var React = require('react');
var ReactPropTypes = React.PropTypes;

var WidgetExample = React.createClass({displayName: "WidgetExample",
  getInitialState: function() {
    return {
      value: this.props.value
    };
  },
  getDefaultProps: function () {
    return {
      value: ['text']
    }
  },
  componentDidMount: function() {
    this.props.showField();
    if (this.props.value) {
      this.props.setValue('left');
    }
  },
  handleClick : function(e) {
    var val = e.target.value;
    this.setState({
      value: val
    });
    this.props.setValue(val);
  },
  getActiveCssClass: function(val) {
    var cssClass = 'btn btn-default';
    if (this.state.value === val) {
      cssClass = 'btn btn-default active';
    }
    if (!this.state.value && val === 'left') {
      cssClass = 'btn btn-default active';
    }
    return cssClass;
  }, 
  // handleChange: function(e) {
  //   this.setState({
  //     value: e.target.value
  //   });
  //   this.props.setValue(e.target.value);
  // },
  // handleBlur: function(e) {
  //   this.props.validate();
  // },
  render: function() {
    return (
      React.createElement("div", {className: "btn-group"}, 
        React.createElement("button", {className: this.getActiveCssClass('left'), type: "button", onClick: this.handleClick, value: "left"}, "Left"), 
        React.createElement("button", {className: this.getActiveCssClass('middle'), type: "button", onClick: this.handleClick, value: "middle"}, "Middle"), 
        React.createElement("button", {className: this.getActiveCssClass('right'), type: "button", onClick: this.handleClick, value: "right"}, "Right")
      )
    );
  }
});

WidgetExample.propTypes = {
  id: React.PropTypes.string.isRequired,
  name: React.PropTypes.string.isRequired,
  value: React.PropTypes.array,
  schema: React.PropTypes.object.isRequired,
  setValue: React.PropTypes.func.isRequired,
  setInvalid: React.PropTypes.func.isRequired,
  validate: React.PropTypes.func.isRequired,
  showField: React.PropTypes.func.isRequired
};

module.exports = WidgetExample;