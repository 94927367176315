var validate = require('./validate.js');

var controls = { 
  init: function() {
    if (typeof reloadPageNavigation !== 'undefined') {
      reloadPageNavigation();
      //require('./pageNavigation.js').init();
    }
    $('.js-include-item-type').each(function(index) {
      var self = this; 
      var data = '';
      $.ajax({
        url: '/edit/entries/?content_type=sub-page-test&action=new&mode=embed',
        data: data,  
        success: function(response) { 
          var result = $(response).find('#properties-row').html();
          var id = '#' + self.id; 
          $(id).html('<div class="row">' + result + '</div>');
          systemRenderForm(id);
        }
      });
    });
    this.loadArrayObject();
    this.loadRelationships();
    this.loadDatePickers();
  },
  validateForm: function() {
    var currentActiveElement = document.activeElement;
    var navMenuItem = $('#nav-menu-item-display-name-input');
    if (navMenuItem.length && navMenuItem.val().length === 0) {
      navMenuItem.parent().addClass('has-error');
      navMenuItem.focus();
      $('button[type="submit"]').prop('disabled', true);
      return false;
    }
    // if(!$('#validation-errors').length) {
    //   return;
    // }
    $('input, textarea').each(function(index) {
      var input = $(this);
      var tempType = input.prop('type');
      input.prop('type', 'text');
      if(input.data('type') === 'date' || input.data('type') === 'date-time') {
        input.click();
      } else {
        input.focus();
      }
      input.prop('type', tempType);
    });
    $('#__status').focus();
    currentActiveElement.focus();
    var isValid = $('.has-error').length === 0; 
    if(!isValid) {
      $('button#form-cms[type="submit"]').prop('disabled', true);
      return false;
    } else {
      $('button#form-cms[type="submit"]').prop('disabled', false);
      var navMenuItemSave = Array.prototype.slice.call(document.querySelectorAll('#nav-menu-item-save'));
      if(navMenuItemSave.length) {
        navMenuItemSave[0].click();
      }
      var loadFrame;
      // if(windowCurrentLinkObject && window.top.window.currentLinkObject_1) {
      //   loadFrame = window.top.window.currentLinkObject_1.loaderFrame;
      // } else if (window.top.window.currentLinkObject_1) {
      //   loadFrame = window.top.window.currentLinkObject_1.loaderFrame;
      // } else if (windowCurrentLinkObject) {
      //   loadFrame = eval(windowCurrentLinkObject.loaderFrame);
      // } else {
      //   loadFrame = window.top;
      // }
      loadFrame = window;
      var modifier;
      if (~location.toString().indexOf('/items/') && !~location.toString().indexOf('is_link=true')) {
        if (~location.toString().indexOf('nav-menu-item')) {
          modifier = 'loader--inline';
        } else {
          modifier = 'loader--inline loader--inline-item-types';
        }
      } else {
        modifier = 'loader--light-inverted-bg';
      }
      $('button#form-cms[type="submit"]').button('loading');
      loadFrame.postMessage({'action': 'show_loader', 'modifier': modifier, 'preLoaderText': preLoaderText}, '*');
      // postMessage({'action': 'show_loader', 'modifier': modifier, 'preLoaderText': preLoaderText}, '*');
    }
  },
  loadArrayObject: function() {
    //console.log('eheheheh');
    var accordionItemWasRemoved = localStorage.getItem('accordionItemWasRemoved') ? parseInt(localStorage.getItem('accordionItemWasRemoved')) : false;
    var accordionIndex = (localStorage.getItem('accordionIndex') && localStorage.getItem('accordionIndex') !== 'false') ? parseInt(localStorage.getItem('accordionIndex')) : false;
    //console.log(accordionIndex)
    if(accordionIndex && accordionItemWasRemoved) {
      //console.log(accordionItemWasRemoved);
      //console.log(accordionIndex);
      if(accordionItemWasRemoved === accordionIndex) {
        accordionIndex = false;
      } else if (accordionItemWasRemoved < accordionIndex) {
        accordionIndex--;
      } else if (accordionItemWasRemoved > accordionIndex) {
        accordionIndex++;
      } 
    }
    localStorage.removeItem('accordionItemWasRemoved');
    var sortableStartIndex = null;
    $('.accordion').accordion();
    $('.accordion').accordion('destroy');
    $('.accordion').each(function(index) {
      var acc = $(this);
      var active = acc.next().prop('name') === localStorage.getItem('accordionCurrentFormControl') ? accordionIndex : false;
      //console.log(acc.next().prop('name'));
      acc.accordion({
        header: '> .panel > .panel-heading',
        collapsible: true,
        // active: accordionIndex,
        active: active,
        animate: 150,
        heightStyle: 'content',
        create: function(event, ui) {
          var header = ui.header;
          if(header.length) {
            header.addClass('active');
            header.find('.toggle-item .fa').removeClass('fa-angle-down');
            header.find('.toggle-item .fa').addClass('fa-angle-up');
            //console.log(header.find('.toggle-item .glyphicon').prop('class'));
          }
          //$(this).accordion('option', {active: 0});
        },
        activate: function(event, ui) {
          var newHeader = ui.newHeader;
          var newPanel = ui.newPanel;
          var oldPanel = ui.oldPanel;
          $('.accordion .panel-heading').removeClass('active');
          newHeader.addClass('active');
          newPanel.addClass('active');
          //oldPanel.addClass('active');
          //console.log($(this).accordion('option', 'active'));
          var isNestedArray = false;
          var item = newHeader.parent().parent().parent().parent().attr('id') ? newHeader : oldPanel;
          if (~item.parent().parent().parent().parent().attr('id').indexOf('----')) {
            isNestedArray = true;
          }
          if (!isNestedArray) {
            localStorage.setItem('accordionIndex', $(this).accordion('option', 'active'));
            localStorage.setItem('accordionCurrentFormControl', $(this).next().prop('name'));
          }
          //console.log($(this).next().prop('name'));
          
          // if ($('#clientframe').length) {
          //   var propName = $(this).next().prop('name');
          //   var index = $(this).accordion('option', 'active');
          //   var pageObject = $($('#clientframe').contents()[0].documentElement);
          //   var el;
          //   if (index !== false) {
          //     el = pageObject.find('[data-id="' + window.currentEditingBlock + '"] [data-field-array="' + propName + '"] [data-field-array-item]');
          //   } else {
          //     el = pageObject.find('[data-id="' + window.currentEditingBlock + '"] [data-field-array="' + propName + '"] [data-field-array-item].active-array-object');
          //   }
          //   if (el.length) {
          //     // localStorage.setItem('data-field-array-item-do-not-open-item-in-editor', true);
          //     // el.click();
          //     // el.addClass('item-focus-active');
          //   }
          //   // console.log(propName);
          //   // console.log(index);
          // }
        },
        beforeActivate: function(event, ui) {
          var newHeader = ui.newHeader;
          var oldHeader = ui.oldHeader;
          var newPanel = ui.newPanel;
          var oldPanel = ui.oldPanel;
          newHeader.addClass('active');
          newHeader.find('.toggle-item .fa').removeClass('fa-angle-down');
          newHeader.find('.toggle-item .fa').addClass('fa-angle-up');
          oldHeader.removeClass('active');
          oldHeader.find('.toggle-item .fa').removeClass('fa-angle-up');
          oldHeader.find('.toggle-item .fa').addClass('fa-angle-down');
          //newPanel.addClass('active');
          //newPanel.removeClass('active');
          //oldPanel.removeClass('active');
        },
      }).sortable({
        handle: '.move-item',
        placeholder: 'sortable-placeholder',
        axis: 'y',
        //containment: 'body',
        //tolerance: 'pointer',
        start: function(event, ui) {
          var item = ui.item;
          var placeholder = ui.placeholder;
          placeholder.css('height', item[0].offsetHeight+1 + 'px');
          sortableStartIndex = item.index();
        },
        stop: function(event, ui) {
          var item = ui.item;
          //console.log('from: ' + sortableStartIndex);
          //console.log('to: ' + item.index());
          localStorage.setItem('sortablePosition', JSON.stringify({
            from: sortableStartIndex,
            to: item.index()
          }));
          var active = false;
          var currentActive = $(this).accordion('option', 'active');
          if(currentActive !== false) {
            if(currentActive !== sortableStartIndex) {
              active = currentActive;
              if((item.index() <= active) && (sortableStartIndex > active)) {
                //console.log('hehehehe');
                active++;
              }
              if(item.index() >= active) {
                active--;
                //console.log('what');
              }
            } else {
              active = item.index();
            }
          }
          //console.log(currentActive + ' : ' + sortableStartIndex);
          //console.log(active);
          var isNestedArray = false;
          if (~item.parent().parent().parent().attr('id').indexOf('----')) {
            isNestedArray = true;
          }
          if (!isNestedArray) {
            localStorage.setItem('accordionIndex', active);
          }
          item.click();
        }
      });
    });
    $('.accordion .tool-box .move-item, .accordion .tool-box .remove-item').click(function() {
      return false;
    });
    $('.accordion .panel-heading').click(function() {
      $('.accordion .ui-accordion-content').removeClass('active');
      $('.accordion').find('input[type="radio"]').css('visibility', 'hidden');
    });
  },
  loadRelationships: function() {
    // if($('[data-key="'+localStorage.getItem('accordionCurrentFormControl')+'"] > .panel--group .accordion').length) {
    //   console.log(localStorage.getItem('accordionCurrentFormControl'));
    //   $('[data-key="'+localStorage.getItem('accordionCurrentFormControl')+'"] > .panel--group .accordion').accordion('option', {active: 0});
    // }
    var relAccordionIndex = (localStorage.getItem('relAccordionIndex') && localStorage.getItem('relAccordionIndex') !== 'false') ? parseInt(localStorage.getItem('relAccordionIndex')) : false;
    // if(accordionIndex && accordionItemWasRemoved) {
    //   console.log(accordionItemWasRemoved);
    //   console.log(accordionIndex);
    //   if(accordionItemWasRemoved === accordionIndex) {
    //     accordionIndex = false;
    //   } else if (accordionItemWasRemoved < accordionIndex) {
    //     accordionIndex--;
    //   } else if (accordionItemWasRemoved > accordionIndex) {
    //     accordionIndex++;
    //   } 
    // }
    //console.log(relAccordionIndex);
    $('.relationship-sortable').accordion();
    $('.relationship-sortable').accordion('destroy');
    $('.relationship-sortable').accordion({
      header: ' li > .list-g-heading',
      collapsible: true,
      active: relAccordionIndex,
      animate: 150,
      heightStyle: 'content',
      create: function(event, ui) {
        var header = ui.header;
        if(header.length) {
          header.addClass('active');
          //header.find('.toggle-item .fa').removeClass('fa-angle-down');
          //header.find('.toggle-item .fa').addClass('fa-angle-up');
          //console.log(header.find('.toggle-item .glyphicon').prop('class'));
        }
      },
      activate: function(event, ui) {
        var newHeader = ui.newHeader;
        var newPanel = ui.newPanel;
        var oldPanel = ui.oldPanel;
        //$('.accordion .panel-heading').removeClass('active');
        //newHeader.addClass('active');
        //newPanel.addClass('active');
        //oldPanel.addClass('active');
        //localStorage.setItem('relationship-accordion-index', 1);
        localStorage.setItem('relAccordionIndex', $(this).accordion('option', 'active'));
        if($(this).accordion('option', 'active') === false) {
          if(window.top.window.currentLinkObject) {
            $('#' + window.top.window.currentLinkObject.input_id + '-update-link-view').click();
          }
        }
        //console.log($(this).accordion('option', 'active'));
      },
      beforeActivate: function(event, ui) {
        var newHeader = ui.newHeader;
        var oldHeader = ui.oldHeader;
        var newPanel = ui.newPanel;
        var oldPanel = ui.oldPanel;
        // newHeader.addClass('active');
        // newHeader.find('.toggle-item .fa').removeClass('fa-angle-down');
        // newHeader.find('.toggle-item .fa').addClass('fa-angle-up');
        // oldHeader.removeClass('active');
        // oldHeader.find('.toggle-item .fa').removeClass('fa-angle-up');
        // oldHeader.find('.toggle-item .fa').addClass('fa-angle-down');
        //newPanel.addClass('active');
        //newPanel.removeClass('active');
        //oldPanel.removeClass('active');
      },
    }).sortable({
      handle: '.move-item',
      placeholder: 'sortable-placeholder',
      axis: 'y',
      start: function(event, ui) {
        var item = ui.item;
        var placeholder = ui.placeholder;
        placeholder.css('height', item[0].offsetHeight-1 + 'px');
      },
      stop: function(event, ui) {
        var item = ui.item;
        item.parent().parent().parent().find('[id*="reorder-link-view"]').click();
      }
    });
    $('.list-g-heading .change-item').unbind();
    $('.list-g-heading .item-text').unbind();
    $('.list-g-heading .change-item, .list-g-heading .item-text').mouseup(function() {
      if($(this).parent().parent().prev().prop('class') === 'list-g-heading--over') {
        $(this).parent().parent().prev().click();
      } else {
        $(this).parent().click();  
      }
    });
    $('.relationship-sortable  li > .list-g-heading .move-item, .relationship-sortable  li > .list-g-heading .item-text, .relationship-sortable  li > .list-g-heading .remove-item, .relationship-sortable  li > .list-g-heading .change-item').click(function() {
      return false;
    });
  },
  loadDatePickers: function() {
    $('[data-type="date"]').datetimepicker('destroy');
    $('[data-type="date-time"]').datetimepicker('destroy');
    $('[data-type="date"]').datetimepicker({
      lang:'sv',
      format:'Y-m-d',
      timepicker: false,
      closeOnDateSelect: true,
      dayOfWeekStart: 1,
      validateOnBlur: false,
      //mask: true,
      // onShow: function(current_time, $input) {
      //   $input.click();
      // },
      // onChangeDateTime: function(current_time, $input) {
      //   $input.click();
      // },
      onClose: function(current_time, $input) {
        $input.click();
      }
    });

    $('[data-type="date-time"]').datetimepicker({
      lang:'sv',
      format:'Y-m-d H:i',
      closeOnDateSelect: true,
      todayButton: true,
      dayOfWeekStart: 1,
      validateOnBlur: false,
      //mask: true,
      // onShow: function(current_time, $input) {
      //   $input.click();
      // },
      // onChangeDateTime: function(current_time, $input) {
      //   $input.click();
      // },
      onClose: function(current_time, $input) {
        $input.click();
      }
    });

    $('[data-type="date"]').next().mousedown(function() {
      $(this).prev().focus();
    });
    $('[data-type="date-time"]').next().mousedown(function() {
      $(this).prev().focus();
    });
    // $('[data-type="date"]').next().click(function() {
    //   $(this).prev().focus();
    // });
    // $('[data-type="date-time"]').next().click(function() {
    //   //$(this).prev().focus();
    // });
    
    // $('[data-type="date"]').parent().datetimepicker({
    //   format: 'YYYY-MM-DD',
    //   keepOpen: true,
    //   locale: 'se',
    //   collapse: true,
    // }).on('change.dp', function(e) {
    //    $(this).find('[data-type="date"]').focus();
    //    //$(this).find('[data-type="date"]').select();
    // });
    // $('[data-type="date-time"]').parent().datetimepicker({
    //   format: 'YYYY-MM-DD hh:mm',
    //   keepOpen: true,
    //   locale: 'sv'
    // });
    
    // $('[data-type="date-time"]').parent().on('changeDate', function(ev){
    //   $(this).datepicker('hide');
    // });
    // $('[data-type="date-time"]').parent().on('changeDate', function(ev){
    //   $(this).datepicker('hide');
    // });
    // $('.datepicker td').on('click', function() {
    //   //alert('asdf');
    //   //$.dp.hide();
    //   hide.dp;
    //   //$('.bootstrap-datetimepicker-widget').css('visibility', 'hidden');
    // });
    //alert($('[data-type="date-time"]').datetimepicker);
    //$('[data-type="date-time"]').datetimepicker();
    // $('[data-type="date"]').datetimepicker(
    //   {
    //     lang:'sv',
    //     format:'Y-m-d',
    //     timepicker: false
    //   }
    // );
  }
}

module.exports = controls;