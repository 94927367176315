var React = require('react');
var ReactPropTypes = React.PropTypes;

var Color = React.createClass({displayName: "Color",
  getInitialState: function() {
    // var colorPickerColors;
    // var colorPickerColors1;
    // var colorPickerColors2;
    // if (window.klar) {
    //   if (!localStorage.getItem('color-picker-selectors')) {
    //     colorPickerColors1 = Object.keys(klar.data.theme.colors.primary_shades.colors);
    //     colorPickerColors1 = colorPickerColors1.map((key) => {if (key === '900' || key === '700' || key === '500' || key === '300') { return klar.data.theme.colors.primary_shades.colors[key]} else {return null}}).filter(c => c !== null).reverse();
    //     colorPickerColors2 = Object.keys(klar.data.theme.colors.neutral_shades.colors);
    //     colorPickerColors2 = colorPickerColors2.map((key) => {if (key === '900' || key === '700' || key === '500' || key === '300') { return klar.data.theme.colors.neutral_shades.colors[key]} else {return null}}).filter(c => c !== null).reverse();
    //     colorPickerColors2 = colorPickerColors2.concat(['#ffffff']);
    //     colorPickerColors = colorPickerColors1.concat(colorPickerColors2);
    //     localStorage.setItem('color-picker-selectors', JSON.stringify(colorPickerColors));
    //   }
    // }
    // console.log(colorPickerColors);
    var colorSelectorsValue = localStorage.getItem('color-picker-selectors') ? JSON.parse(localStorage.getItem('color-picker-selectors')) : [];
    return {
      value: this.props.value || 'transparent',
      colorSelectors: colorSelectorsValue
    };
  },
  componentDidMount: function() {
    // this.props.showField();
    // this.props.setValue(this.state.value);
    setTimeout(function() { 
      var colorPickerIsVisible = false;
      var colorSelectorsState = localStorage.getItem('color-picker-selectors') ? JSON.parse(localStorage.getItem('color-picker-selectors')) : [];
      var colorPickerId = this.props.id + '-color-picker';
      var colorPickerObj = $('#' + colorPickerId);
      var colorSelectors = {};
      for (var i = 0; i < colorSelectorsState.length; i++) {
        colorSelectors[colorSelectorsState[i]] = colorSelectorsState[i];
      }
      if (Object.keys(colorSelectors).length === 0) {
        colorSelectors = null;
      }
      colorPickerObj.colorpicker('destroy');
      colorPickerObj.colorpicker({
        format: this.props.schema.format === 'rgba' ? 'rgba' : 'hex',
        color: this.state.value || 'transparent',
        fallbackColor: 'red',
        ustomClass: 'colorpicker-2x',
        colorSelectors: colorSelectors
      });
      colorPickerObj.find('.input-group-addon').unbind('mousedown');
      colorPickerObj.find('.input-group-addon').bind('mousedown', function () {
        if (~colorPickerObj.data('colorpicker').picker.attr('class').indexOf('colorpicker-visible')) {
          colorPickerIsVisible = true;
        }
      });
      colorPickerObj.colorpicker().on('showPicker', function (e) {
        if (colorPickerIsVisible) {
          colorPickerObj.colorpicker('hide');
          colorPickerIsVisible = false;
          return;
        }
        if ($('.iframe-over-fix').length) {
          $('.iframe-over-fix').removeClass('hide');
        }
        // var val = e.color.toString();
        // // if (val && val.length === 7) {
        //   this.setValue(val);
        // // }
        // this.setCssColor(e.color.toString());
      }.bind(this));
      colorPickerObj.colorpicker().on('hidePicker', function (e) {
        if ($('.iframe-over-fix').length) {
          $('.iframe-over-fix').addClass('hide');
        }
        // console.log('object: ', e.color);
        var val = e.color.toString();
        
        // if (this.props.schema.format === 'rgba') {
        //   console.log(e.color.toRGB());
        //   var prevAlpha = e.color.toRGB().a;
        //   var rgba = e.color.toRGB();
        //   val = 'rgba(' + rgba.r + ',' + rgba.g + ',' + rgba.b + ',' + prevAlpha + ')';
        //   // this.setCssColor(val);
        //   console.log(val)
        // }   

        // if (val && val.length === 7) {
          this.setValue(val);
        // }
        this.setCssColor(val);
      }.bind(this));
      colorPickerObj.colorpicker().on('changeColor', function (e) {
        // console.log(e.color.toRGB());
        // if (this.props.schema.format === 'rgba') {
        //   var prevAlpha = e.color.toRGB().a;
        //   // setTimeout(function () {
        //     var rgba = e.color.toRGB();
        //     val = 'rgba(' + rgba.r + ',' + rgba.g + ',' + rgba.b + ',' + prevAlpha + ')';
        //     this.setCssColor(val);
        //   // console.log(colorPickerObj.colorpicker('getValue'))
        //   // }, 10);
        // } else {
          this.setCssColor(e.color.toString());
        // }     
      }.bind(this));
    }.bind(this), 500);
  },
  setCssColor: function (color) {
    if ($('#clientframe').length) {
      if (this.props.schema['ui:css_selector'] && this.props.schema['ui:css_property']) {
        var pageObject = $($('#clientframe').contents()[0].documentElement);
        var classReplacer = window.currentEditingBlock ? '.' + window.currentEditingBlock : '';
        var idReplacer = window.currentEditingBlock ? '#' + window.currentEditingBlock : '';
        var selector = this.props.schema['ui:css_selector'].replace(/\$\{block_id_css_class\}/g, classReplacer);
        selector = selector.replace(/\$\{block_id_css_id\}/g, idReplacer);
        // console.log(selector)
        pageObject.find(selector).css(this.props.schema['ui:css_property'], color);
      }
    }
  },
  setValue : function(val) {
    this.setState({
      value: val
    });
    this.props.setValue(val);
  },
  onChange: function(e) {
    // console.log(e.target.value)
    this.setState({
      value: e.target.value
    });
    this.props.setValue(e.target.value);
  },
  onBlur: function(e) {
    // this.setState({
    //   value: e.target.value
    // });
    // this.props.setValue(e.target.value);
    // this.props.validate();
  },
  onClick: function(e) {
    this.state.colorSelectors = localStorage.getItem('color-picker-selectors') ? JSON.parse(localStorage.getItem('color-picker-selectors')) : [];
    if (~this.state.colorSelectors.indexOf(this.state.value)) {
      return;
    }
    if (this.state.colorSelectors.length === 20) {
      value.splice(value.indexOf(inputValue), 1);  
    }
    this.state.colorSelectors.push(this.state.value);
    localStorage.setItem('color-picker-selectors', JSON.stringify(this.state.colorSelectors));
    // console.log(localStorage.getItem('color-picker-selectors')[0])
    this.setState({
      colorSelectors: this.state.colorSelectors
    });
  },
  onDoubleClick: function(e) {
    this.state.colorSelectors = localStorage.getItem('color-picker-selectors') ? JSON.parse(localStorage.getItem('color-picker-selectors')) : []
    if (~this.state.colorSelectors.indexOf(e.target.value)) {
      this.state.colorSelectors.splice(this.state.colorSelectors.indexOf(e.target.value), 1);
    }
    localStorage.setItem('color-picker-selectors', JSON.stringify(this.state.colorSelectors));
    this.setState({
      colorSelectors: this.state.colorSelectors
    });
  },
  setColor: function(e) {
    var val = e.target.value;
    var colorPickerId = this.props.id + '-color-picker';
    var colorPickerObj = $('#' + colorPickerId);
    
    if (this.props.schema.format === 'rgba') {
      var prevAlpha = colorPickerObj.data('colorpicker').color.toRGB().a;
      setTimeout(function () {
        var rgba = colorPickerObj.data('colorpicker').color.toRGB();
        val = 'rgba(' + rgba.r + ',' + rgba.g + ',' + rgba.b + ',' + prevAlpha + ')';
        colorPickerObj.colorpicker('setValue', val);
      }, 10);
      colorPickerObj.colorpicker('setValue', val);
    } else {
      colorPickerObj.colorpicker('setValue', val);
    }   
    this.setValue(val);
    this.setCssColor(val);
  },
  render: function() {
    return (
      React.createElement("div", {style:  {position: 'relative', paddingBottom: '5px'} }, 
        React.createElement("a", {className: "btn btnLink", onClick: this.onClick, title: "Add color to preset colors", style:  {position: 'absolute', right: '0px', top: '29px', fontSize: '10px'} }, "Add"), 
        React.createElement("div", {className: "tn-group", style: { position: 'absolute', right: '0px', top: '-25px'}}, 
          
            !window.klar && this.state.colorSelectors.map(function (color, index) {
              return (
                React.createElement("button", {type: "button", value: color, className:  color === this.state.value ? 'btn btn-default ocus ctive' : 'btn btn-default', 
                  onClick: this.setColor, 
                  onDoubleClick: this.onDoubleClick, title: 'Color: ' + color + '\nTip: Doubleclick to remove the color', 
                  style: {backgroundColor: color, order: 'none', height: '15px', padding: '8px', margin: '0 0 0 2px'}})  
              )
            }.bind(this))
          
        ), 
        React.createElement("div", {id: this.props.id + '-color-picker', "data-format": "alias", className: "input-group colorpicker-component"}, 
          React.createElement("input", {type: "text", 
            onChange: this.onChange, 
            value: this.state.value, 
            className: "form-control"}), 
          React.createElement("span", {className: "input-group-addon", style: { minWidth: '41px'}}, React.createElement("i", null))
        )
      )
    );
  }
});

Color.propTypes = {
  id: React.PropTypes.string.isRequired,
  name: React.PropTypes.string.isRequired,
  value: React.PropTypes.array,
  schema: React.PropTypes.object.isRequired,
  setValue: React.PropTypes.func.isRequired,
  setInvalid: React.PropTypes.func.isRequired,
  validate: React.PropTypes.func.isRequired,
  showField: React.PropTypes.func.isRequired
};

module.exports = Color;