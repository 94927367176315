var React = require('react');
var ReactPropTypes = React.PropTypes;
var request = require('superagent');
var async = require('async');

var _PagesBase = {
  _componentDidMount: function() {
    var propField = document.getElementById(this.props.id);
    document.getElementById(this.props.id).type = 'hidden';
    var propertyLabel = Array.prototype.slice.call(document.querySelectorAll('[for="' + this.props.name + '"]'));
    if(propertyLabel.length) {
      propertyLabel[0].style.display = 'none';
    }
    if (this.isMounted()) {
      var navMenuItems = [];
      var items = context.items;
      if(items) {
        for (var i = 0; i < items.length; i++) {
          var item = items[i];
          if(item.__meta.item_type.data.id === 'nav-menu-item') {
            if(localStorage.getItem('nav-menu-item-active-id') && item.__meta.id === localStorage.getItem('nav-menu-item-active-id')) {
              item.is_active = true;
              item.reloadPage = true;
            }
            navMenuItems.push(item);
          }
        }
      }
      this.props.parent.setState({
        value: navMenuItems,
        isValid: true,
        items: items,
        itemTypes: context.item_types,
        ajaxIsLoaded: true
      });
    }
  },
  _addPageItem: function(position, setActive, itemTypeId, parentId) {
    localStorage.removeItem('nav-menu-item-active-id');
    var navMenuItems = [];
    //navMenuItems.push(this._newMenuItem(position));
    var items = this.props.parent.state.items;
    if(items) {
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if(item.__meta.item_type.data.id === 'nav-menu-item') {
          // if(localStorage.getItem('nav-menu-item-active-id') && item.__meta.id === localStorage.getItem('nav-menu-item-active-id')) {
          item.is_active = false;
          // }
          navMenuItems.push(item);
        }
      }
    }
    var newItem = this._newMenuItem(position, itemTypeId, parentId);
    newItem.is_active = true;
    newItem.reloadPage = true;
    // alert(newItem.meta.parent_id);
    // alert(newItem.meta.position);
    navMenuItems.splice(position, 0, newItem);
    navMenuItems = navMenuItems.sort(function(a, b){return a.meta.position-b.meta.position});
    this.props.parent.setState({
      value: navMenuItems,
      isValid: true,
      itemTypes: this.props.parent.state.itemTypes,
      items: this.props.parent.state.items
    });
  },
  _reloadPageItems: function(activeId, fromServer, reloadPage) {
    // alert('activeId: ', activeId);
    reloadPage = reloadPage ? reloadPage : false;
    if (fromServer) {
      var self = this;
      var endpoint = '/api';
      var query = {
        get: 'items',
        sort: 'meta.position',
        bucket_id: window.bucketId
      };
      async.parallel([
          function(callback) {
            request
              .get(endpoint)
              .query(query)
              .set('Accept', 'application/json')
              .end(function(err, res) {
                if(err)  {
                  //alert(err);
                  console.log('asdf')
                } else {
                  callback(null, res.body);
                  console.log(res.body)
                }
              }
            );
          }
        ],
        function (err, results) {
          console.log('asdf')
          var navMenuItems = [];
          var items = results[0];
          if(items) {
            for (var i = 0; i < items.length; i++) {
              var item = items[i];
              if(item.__meta.item_type.data.id === 'nav-menu-item') {
                if(item.__meta.id === activeId) {
                  item.is_active = true;
                  item.reloadPage = reloadPage;
                }
                if(localStorage.getItem('nav-menu-item-active-id') && item.__meta.id === localStorage.getItem('nav-menu-item-active-id')) {
                  item.is_active = true;
                  item.reloadPage = reloadPage;
                }
                navMenuItems.push(item);
              }
            }
          }
          self.props.parent.setState({
            value: navMenuItems,
            isValid: true,
            itemTypes: self.props.parent.state.itemTypes,
            items: items
          });
        }
      )
    } else {
      var navMenuItems = [];
      var items = this.props.parent.state.items;
      if(items) {
        for (var i = 0; i < items.length; i++) {
          var item = items[i];
          if(item.__meta.item_type.data.id === 'nav-menu-item') {
            if(item.__meta.id === activeId) {
              item.is_active = true;
              item.reloadPage = reloadPage;
            } else {
              item.is_active = false;
            }
            if(localStorage.getItem('nav-menu-item-active-id') && item.__meta.id === localStorage.getItem('nav-menu-item-active-id')) {
              item.is_active = true;
              item.reloadPage = reloadPage;
            } else {
              item.is_active = false;
            }
            navMenuItems.push(item);
          }
        }
      }
      this.props.parent.setState({
        value: navMenuItems,
        isValid: true,
        itemTypes: this.props.parent.state.itemTypes,
        items: items
      });
    }
  },
  _newMenuItem: function(position, itemTypeId, parentId) {
    var menuItem = {
      display_name: '',
      slug: '',
      page: {
        data: {
          type: 'items',
          id: '',
          meta: {
            item_type: {
              data: {
                type: 'item-types',
                id: itemTypeId
              }
            }
          }
        }
      },
      __meta: {
        id: -1,
        position: position
      },
      meta: {
        position: position,
        parent_id: parentId || ''
      }
    };
    return menuItem;
  }
};

module.exports = _PagesBase;