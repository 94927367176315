var React = require('react');
var ReactPropTypes = React.PropTypes;
var request = require('superagent');

var Slug = React.createClass({displayName: "Slug",
  propTypes: {
    id: React.PropTypes.string.isRequired, 
    name: React.PropTypes.string.isRequired,
    value: React.PropTypes.string,
    config: React.PropTypes.object.isRequired,
    validateCallback: React.PropTypes.func.isRequired,
  },
  getInitialState: function() {
    return {
      slugIsUnique: true,
      value: null
    };
  },
  componentDidMount: function() {
    if(typeof this.props.value !== 'undefined' && this.props.value.length) {
      if(this.props.config.unique || this.props.config.unique_with) {
        this._checkUniqueSlug();
      }
    }
  },
  componentDidUpdate: function() {
    var value = document.getElementById(this.props.id).value;
    if(this.props.config.unique || this.props.config.unique_with) {
      if(this.state.value !== this.props.value || ~$('#'+this.props.id).parent().attr('class').indexOf('has-error')) {
        this._checkUniqueSlug();
      }
    }
  },
  shouldComponentUpdate: function(nextProps, nextState) {
    return this.state.value !== nextProps.value;
  },
  _checkUniqueSlug: function() {
    // Return if not in CMS
    if (!window.context || !window.context.render_domain) {
      return;
    }
    if(~$('#'+this.props.id).parent().attr('class').indexOf('has-error')) {
      this.setState({
        slugIsUnique: true,
        value: this.props.value
      });
      this.props.validateCallback();
      return;
    }
    var endpoint = '/api/utils'
    var query = {
      action: 'validate_form'
    };
    var data = $('form').serialize();
    data = data.replace('__item_type', '__item_type_oldie');
    data = data + '&__id=' + window.context.item_id + '&__bucket_id=' + window.bucketId + '&__item_type=' + window.context.item_type;
    request
      .post(endpoint) 
      .send(data)
      .query(query)
      .set('Accept', 'application/json')
      .end(function(err, res) {
        if(err)  {
          //alert(err);
        } else {
          if(res.body === 'no errors') {
            //console.log('no errors');
          } else {
            var data = res.body;
            var isUnique = true;
            var validations = data.validation.validations;
            for(var i=0; i < validations.length; i++) {
              var validation = validations[i];
              if(validation.property_name === this.props.name) {
                isUnique = false;
                break;
              }
            }
            if (!isUnique) {
              this.setState({
                slugIsUnique: isUnique,
                value: this.props.value
              });
              this.props.validateCallback();
            }
          }
        }
      }.bind(this));
  },
  _update: function(value) {
    var propField = document.getElementById(this.props.id);
    propField.value = value;
    propField.click();
  },
  _getValue: function() {
    return this.props.value;
  },
  render: function() {
    var config = this.props.config;
    var uniqueView;
    var uniqueButton;
    if(config.unique || config.unique_with) {
      uniqueButton = (
        React.createElement("button", {
          id: name + '-check-unique-field', 
          type: "button", 
          tyle: {display: 'none'}, 
          onClick: this._checkUniqueSlug
        }, 
        "Check unique slug"
        )
      );
    }
    if(this.state.slugIsUnique === false) {
      uniqueView = (
        React.createElement("div", {id: 'js-slug-' + this.props.name + '-is-not-unique', style: {display: 'none'}}, 
          React.createElement("div", {className: "help-block"}, 
          React.createElement("i", {className: "fa fa-warning"}), 
          React.createElement("span", null, " "), 
          React.createElement("span", null, "Sluggen är inte unik!")
          )
        )
      );
    }
    return (
      React.createElement("div", {className: "slug-view"}, 
        uniqueView
      )
    );
  }
});
 
module.exports = Slug;

