var React = require('react');
var PropertyObject = require('./PropertyObject');
var controls = require('../../lib/controls.js');

var PropertyArray = React.createClass({displayName: "PropertyArray",
  propTypes: {
    name: React.PropTypes.string.isRequired,
    value: React.PropTypes.array.isRequired, 
    config: React.PropTypes.object.isRequired
  },
  componentDidMount: function() {
    if(this.props.config.disabled !== true) {
      this._setInputData();
      var self = this;
      if ($('body.cms').length) {
        alert('File: PropertyArray.js')
        $('form').submit(function() {
          self._disableAllInputs();
        });
      }
      if (!this.props.onFormChange) {
        controls.init();
      }
    }
  },
  getInitialState: function() {
    return {
      items: this._getDefaults(),
      page: 1
    };
  },
  _getDefaults: function() {
    var defaults;
    if(this.props.value.length) {
      defaults = this.props.value;
    } else {
      //localStorage.removeItem('tempValue');
      //var tempValue = localStorage.getItem('tempValue') ? JSON.parse(localStorage.getItem('tempValue')) : false;
      //if(tempValue) {
      //  return tempValue;
      //}
      // var array = [];
      // var propObj = this._config().items.properties;
      // if(propObj) {
      //   array.push(propObj);
      // }
      // defaults = array;
      defaults = [];
    }
    return defaults;
  },
  _config: function() {
    return this.props.config;
  },
  _template: function() {
    var obj = {};
    var properties = this._config().items.properties;
    if(properties) {
      for (var key in properties) {
        var item = properties[key];
        obj[key] = item.default_value ?
                   item.default_value :
                     item.default ?
                       item.default : 
                       '';
        if(item.type === 'boolean') {
          if(obj[key].length === 0) {
            obj[key] = false;
          }
        }
      };
    }
    return obj;
  },
  _onChange: function(obj, index) {
    //var newItems = this.state.items;
    //console.log(index);
    //newItems[index] = obj;
    //this.setState({items: newItems});
    if (this.props.onFormChange) {
      this.props.onFormChange(this.props.name, this.state.items);
    }
    this._setInputData();
  },
  _loadControls: false,
  _createItemWait: false,
  _createItem: function(event) {
    if (this._createItemWait) {
      return;
    } else {
      setTimeout(function () { this._createItemWait = false }.bind(this), 300);
      this._createItemWait = true;
    }
    var newItems = this.state.items;
    // newItems[newItems.length] = this._template();
    newItems.unshift(this._template());
    this.setState({ items: newItems, isNew: true });
    this._setInputData();
    this._loadControls = true;
    if (this.props.onFormChange) {
      this.props.onFormChange(this.props.name, this.state.items);
    }
  },
  _setInputData: function() {
    if(this.props.disabled !== true) {
      if(document.getElementById('result-array')) {
        document.getElementById('result-array').innerHTML = JSON.stringify(this.state.items).replace(/{/g, '{<br/>&nbsp;&nbsp;').replace(/}/g, '<br/>}').replace(/":/g, '": ').replace(/,{/g, 'change{').replace(/,/g, ',<br/>&nbsp;&nbsp;').replace(/change{/g, ',<br/>{');
      }
      document.getElementById(this.props.name).value = JSON.stringify(this.state.items);
      // console.log('df', this.props.name)
      if (!this.props.name.includes('----')) {
        localStorage.setItem('tempValue', JSON.stringify(this.state.items));
      }
    }
  },
  _disableAllInputs: function() {
    var PropertyArrayObjectItems = Array.prototype.slice.call(document.querySelectorAll('[data-type="property-array"] [class="form-control"]'));
    for(var i in PropertyArrayObjectItems) {
      var item = PropertyArrayObjectItems[i];
      item.setAttribute('disabled', 'disabled');
    }
  },
  componentDidUpdate: function() {
    var self = this;
    // console.log(this._loadControls)
    if(this._loadControls) {
      // console.log('controls initialized');
      controls.init();
      if (this.state.isNew) {
        var isNestedArray = false;
        if (~self.props.name.indexOf('----')) {
          isNestedArray = true;
        }
        if (!isNestedArray) {
          setTimeout(function () { 
            localStorage.removeItem('accordionIndex');
            localStorage.removeItem('accordionItemWasRemoved');
            localStorage.removeItem('accordionCurrentFormControl');
          }, 200);
        }
        var firstItemObj = document.querySelector('#js-array-' + this.props.name + ' .panel--box .panel-heading');
        // firstItemObj.nextSibling.style.display = 'block';
        // if (document.querySelector('#js-array-' + this.props.name + ' input.form-control')) {
        //   document.querySelector('#js-array-' + this.props.name + ' input.form-control').select();
        // }
        // setTimeout(function () { firstItemObj.click(); }, 100);
        firstItemObj.click();
        setTimeout(function () {
          if (document.querySelector('#js-array-' + this.props.name + ' input.form-control')) {
            document.querySelector('#js-array-' + this.props.name + ' input.form-control').select();
          }
        }.bind(this), 150);
      }
      this.state.isNew = false;
    }
    this._loadControls = false;
  },
  _sort: function() {
    if (localStorage.getItem('klar-no-sort-on-nested-arrays') && this.props.name.includes('----')) {
      return;
    }
    if(localStorage.sortablePosition && localStorage.sortablePosition !== 'empty') {
      var pos = JSON.parse(localStorage.getItem('sortablePosition'));
      // console.log(pos);
      //console.log(pos.to);
      // Update state
      //var items = this.state.items;
      var from = Number(pos.from);
      var to = Number(pos.to);
      var newItems = this.state.items;
      // console.log(newItems)
      newItems.splice(to, 0, newItems.splice(from, 1)[0]);
      //var fromObj = JSON.stringify(newItems[from]);
      //var toObj = JSON.stringify(newItems[to]);
      //newItems[from] = JSON.parse(toObj);
      //newItems[to] = JSON.parse(fromObj);
      //if(from < to) to--;
      this.setState({items: newItems});
      //this.replaceState({items: newItems});
      //this.forceUpdate();
      this._loadControls = true;
      this._setInputData();
      if (this.props.onFormChange) {
        this.props.onFormChange(this.props.name, this.state.items);
      }
    }
    localStorage.removeItem('klar-no-sort-on-nested-arrays')
    localStorage.sortablePosition = 'empty';
  },
  _removeItem: function(index) {
    //console.log(index);
    var newItems = this.state.items;
    newItems.splice(index, 1);
    this.setState({items: newItems});
    this._loadControls = true;
    localStorage.setItem('accordionItemWasRemoved', index);
    this._setInputData();
    if (this.props.onFormChange) {
      this.props.onFormChange(this.props.name, this.state.items);
    }
  },
  _setStateFromInputData: function() {
    var items = JSON.parse(document.getElementById(this.props.name).value);
    this.setState({ items: items });
    this._loadControls = true;
    if (this.props.onFormChange) {
      this.props.onFormChange(this.props.name, items);
    }
  },
  _getPaginationButtons: function() {
    var paginationButtonsLength = Math.ceil(this.state.items.length/this.props.config.limit);
    var paginationButtons = [];
    // console.log(paginationButtonsLength)
    var i;
    for (i = 0; i < paginationButtonsLength; i++) {
        // if ((this.state.page === (i+1))) {
        //   paginationButtons.push(<li className="active">
        //   <a href="javascript:void(0)">{i+1}</a>
        //   </li>)
        //  } else {
        //   paginationButtons.push(<li> 
        //   <a href="javascript:void(0)" onClick={function () {this._loadControls = true;this.setState({...this.state.items, page: i+1})}}>{i+1}</a>
        //   </li>)
        //  }
    }
    return paginationButtons;
    // { this.state.page == 1 &&
    //   <li className="active"> 
    //     <a href="javascript:void(0)">1</a>
    //   </li>
    //   }
    //   { this.state.page > 1 &&
    //   <li> 
    //     <a href="javascript:void(0)" onClick={() => {this._loadControls = true;this.setState({...this.state.items, page: this.state.page-1})}}>1</a>
    //   </li>
    //   }
    //   <li>
    //     <a href="javascript:void(0)" onClick={() => {this._loadControls = true;this.setState({...this.state.items, page: this.state.page+1})}}>2</a>
    //   </li>  
  },
  render: function() {
    var result;
    if(this.props.config.disabled === true) {
      result = (React.createElement("div", null));
    } else {
      var self = this;
      var items;
      // console.log(this.state.page)
      // if (this.props.config.limit && (this.state.page * this.props.config.limit) < this.state.items.length) {
        if (this.props.config.limit) {
        items = this.state.items.slice((this.state.page-1)*this.props.config.limit, ((this.state.page-1)*this.props.config.limit)+this.props.config.limit);
      } else {
        items = this.state.items;
      }
      var blocks = items.map(function(item, i) {
        var uniqueKey = Math.random();
        var uiSchema = self.props.uiSchema ? self.props.uiSchema : null;
        //console.log(uniqueKey);
        return (
          React.createElement(PropertyObject, {
            isArray: "true", 
            onChange: self._onChange, 
            onFormChange: self.props.onFormChange, 
            removeItem: self._removeItem, 
            index: i, 
            uniqueArrayKey: (new Date()).getTime()+'-'+i, 
            key: uniqueKey+i, 
            name: self.props.name + '----' + i + '------', 
            value: item, 
            config: self._config().items, 
            uiSchema: uiSchema, 
            sort: self._sort}));
      });
      result = (
        React.createElement("div", null, 
        React.createElement("div", {id: 'js-array-' + this.props.name, className: "panel--group"}, 
            React.createElement("div", {className: "panel-heading clearfix"}, 
              React.createElement("h3", {className: "panel-title pull-left"}, this._config().title), 
              React.createElement("div", {className: "tool-box pull-right"}, 
                React.createElement("div", {className: "item add-item", title: "Lägg till", onClick: this._createItem}, 
                  React.createElement("span", {className: "fa fa-plus"})
                )
              )
            ), 
            React.createElement("div", {className: "panel-body"}, 
              React.createElement("div", {className: "sortable accordion"}, 
                blocks
              ), 
              React.createElement("input", {id: this.props.name, name: this.props.name, onClick: this._setStateFromInputData, type: "hidden"})
            )
          ), 


           this.props.config.limit && 

          React.createElement("div", {className: "clearfix"}, 
            React.createElement("nav", {className: "pull-right"}, 
              React.createElement("ul", {className: "pagination", style: {margin: '-10px 0 0'}}, 
                
                /* {this.state.page === 1 &&
                <li className={this.state.page === 1 && 'disabled'}>
                  <span aria-hidden="true">«</span>
                </li>
                
                this.state.page > 1 &&
                <li onClick={() => {this._loadControls = true;this.setState({...this.state.items, page: 1})}}>
                  <span aria-hidden="true">«</span>
                </li> */
                
                
                
                this._getPaginationButtons()
                /* <li> 
                  <a  href="javascript:void(0)">    
                    <span aria-hidden="true">»</span>   
                  </a>    
                </li> */
              )
            )
          )
      
            
            
            
            )
      );
    }
    return result;
  }
});

module.exports = PropertyArray;