var React = require('react');
var ReactPropTypes = React.PropTypes;

var Custom = React.createClass({displayName: "Custom",
  propTypes: {
    id: React.PropTypes.string.isRequired,
    name: React.PropTypes.string.isRequired,
    value: React.PropTypes.string,
    config: React.PropTypes.object.isRequired
  },
  componentDidMount: function() {
  },
  render: function() {
    return (
      React.createElement("div", null, 
        "GAGAGAGAGA"
      )
    );
  }
});
 
module.exports = Custom;

