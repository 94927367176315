var validate = {
  form: function(data, properties) {
    var validation = {};
    var validations = [];
    for (var key in data) {
      if(properties[key]) {
        var itemConfig = properties[key];
        var value = data[key];
        if(itemConfig.type === 'object') {
          var data1 = value;
          if (typeof data1 === 'string') {
            data1 = JSON.parse(data1);
          }
          for (var key1 in data1) {
            var itemConfig1 = itemConfig['properties'][key1];
            var value1 = data1[key1];
            var validation = this.control(key1, value1, itemConfig1)
             if(!validation.isValid) {
               validations.push({
                 property: itemConfig.title + ' : ' + itemConfig1.title,
                 property_name: key1,
                 value: value1,
                 messages: [validation.message]
               });
             }
          }
        } else {
          var validation = this.control(key, value, itemConfig)
          if(!validation.isValid) {
            validations.push({
              property: itemConfig.title,
              property_name: key,
              value: value,
              messages: [validation.message]
            });
          }
        }
      }
    };
    if(validations.length > 0) {
      validation.title = 'Följande fel upptäcktes';
      validation.validations = validations;
      validation.isValid = false;
    } else {
      validation.isValid = true;
    }
    return validation;
  },
  control: function(name, value, config) {
    // alert('Den här alerten är i validate.js : )');
    var isValid = true;
    var message = '';
    var fieldFormats = this.fieldFormats();
    if(value.length === 0 && !config.required) {
      // move on : )
    } else if(config.required && value.length === 0) {
      isValid = false;
      message = fieldFormats.required.message;
    } else if
    (
      config.format ||
      config.pattern ||
      config.field && (config.field.type === 'text' || config.field.type === 'textarea') ||
      config.type === 'email' ||
      config.type === 'number' ||
      config.type === 'integer' ||
      config.type === 'symbol' ||
      config.type === 'slug'
    ) {
      var returnValue = true;
      var pattern;
      if (config.pattern) {
        pattern = config.pattern;
        message = config.message || 'Lägg till ett meddelande!';
      } else {
        if(config.format) {
          if(!fieldFormats[config.format]) {
            return {
              isValid: true,
              message: 'Access-token test'
            }
          }
          pattern = fieldFormats[config.format].pattern;
          message = fieldFormats[config.format].message;
        } else if (config.field && (config.field.type === 'text' || config.field.type === 'textarea')) {
          pattern = fieldFormats[config.field.type].pattern;
          message = fieldFormats[config.field.type].message;
        } else {
          pattern = fieldFormats[config.type].pattern;
          message = fieldFormats[config.type].message;
        }
      }
      var flags = null;
      if(!~pattern.indexOf('function')) {
        if(pattern.indexOf('/') != -1) {
          if(pattern.substring(0, 1) == '/') {
            if(pattern.substring(pattern.length-1, pattern.length) != '/') {
              if(pattern.substring(pattern.length-3, pattern.length).indexOf('/') == -1) {
                flags = pattern.substring(pattern.length-3, pattern.length);
              } else if(pattern.substring(pattern.length-2, pattern.length).indexOf('/') == -1) {
                flags = pattern.substring(pattern.length-2, pattern.length);
              } else if(pattern.substring(pattern.length-1, pattern.length).indexOf('/') == -1) {
                flags = pattern.substring(pattern.length-1, pattern.length);
              }
              flags = flags.replace('/', '');
            }
            if(flags != null) {
              pattern = pattern.substring(1, pattern.length - (1 + flags.length));
            } else {
              pattern = pattern.substring(1, pattern.length - 1);
            }
          }
        }
      }
      try {
        var re = null;
        if(!~pattern.indexOf('function')) {
          if(flags != null) {
            //console.log(pattern);
            //console.log(flags);
            re = new RegExp(pattern, flags);
          } else {
            re = new RegExp(pattern);
          }
        }
        //if(config.format === 'jsons' || ~pattern.indexOf('function')) {
        if(~pattern.indexOf('function')) {
          var func = eval('(' + pattern + ')');
          returnValue = func(name, value);
        } else {
          returnValue = re.test(value);
        }
      }
      catch(err) {
        alert(err + '\n' + 'Name: ' + name);
      }
      if(!returnValue) {
        isValid = false;
      }
    }
    // if(name === '__handle') {
    //   var endpoint = '/api'
    //   var query = {get: 'entries?__meta.handle[$eq]=' + value}; 
    //   request
    //     .get(endpoint)
    //     .query(query)
    //     .set('Accept', 'application/json')
    //     .end(function(err, res) {
    //       if(err)  {
    //         alert(err);
    //       } else {
    //         var data = res.body;
    //         var isUnique = !data.length;
    //         return {
    //           isValid: false,
    //           message: 'come on'
    //         }
    //         // if(data.length === 1) {
    //         //   if(data[0].__meta.id === window.currentItemId) {
    //         //     isUnique = true;
    //         //     //alert(data);  
    //         //   }
    //         // }
    //         // this.setState({
    //         //   value: this.state.value,
    //         //   isValid: this.state.isValid,
    //         //   slugIsUnique: isUnique
    //         // });
    //       }
    //     }.bind(this));
    // } else {
    return {
      isValid: isValid,
      message: message
    }
    //}
  },
  fieldFormats: function() {
    var fieldFormats = {
      'email': {
        'message': 'Var vänlig och fyll i en korrekt e-postadress.',
        'pattern': '/[a-z0-9]+[_a-z0-9\\.-]*[a-z0-9]+@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,4})/g'
      },
      'date': {
        'message': 'Värdet är inte ett korrekt datum.',
        'pattern': '/^(19|20)\\d\\d([- /.])(0[1-9]|1[012])\\2(0[1-9]|[12][0-9]|3[01])$/g'
      },
      'date-time': {
        'message': 'Värdet är inte ett korrekt datum med tid.',
        'pattern': '/^\\d{4}-\\d{2}-\\d{2} \\d{2}:\\d{2}$/g'
      },
      'number': {
        'message': 'Var vänlig och ange ett numeriskt värde',
        'pattern': '/^\\d+(\\.\\d{1,4})?$/g'
      },
      'integer': {
        'message': 'Värdet är inte en siffra.',
        'pattern': '/^\\d+(\\.\\d{1,4})?$/g'
      },
      'symbol': {
        'message': 'Värdet kan bara innehålla små bokstäver utan åäö eller dessa tecken: ("_", "-").',
        'pattern': '/^[a-z0-9_-]+$/g'
      },
      'text': {
        'message': 'Text får inte innehålla html-taggar.',
        'pattern': '//'
        // 'pattern': '/^(?!.*(>([^<]*)<))/g'
      },
      'textarea': {
        'message': 'Text får inte innehålla html-taggar.',
        'pattern': '//'
      },
      'slug': {
        //'message': 'Värdet kan bara innehålla små bokstäver utan åäö eller dessa tecken: ("_", "-") och måste vara unik.',
        'message': 'Värdet kan bara innehålla små bokstäver utan åäö eller dessa tecken: ("_", "-").',
        // 'message': 'Detta fält måste vara unikt.',
        //'pattern': '/^[a-z0-9_-]+$/g'
        'pattern': 'function(name, value) {try {var re = new RegExp("^[a-z0-9_-]+$", "g");if($("#js-slug-\" + name + \"-is-not-unique").length || !re.test(value)) {return false;} else {return true;}} catch(err) {return true;}}'
      },
      'markdown': {
        'message': 'Detta skall alltid gå igenom',
        'pattern': '//'
      },
      'json': {
        'message': 'Detta är inte korrekt JSON',
        //^\\"([^\\"\\\\]*|\\\\(["\\\\\/bfnrt]{1}|u[a-f0-9]{4}))*\\"$
        //'pattern': '/^\\"([^\\"\\\\]*|\\\\(["\\\\\/bfnrt]{1}|u[a-f0-9]{4}))*\\"$/g'
        //'pattern': '/^("(\\\\.|[^"\\\n\r])*?"|[,:{}\\[\\]0-9.\\-+Eaeflnr-u \\n\\r\\t])+?$/g'
        //'pattern': '//'
        // 'pattern': 'function(name, value) {try {JSON.parse(value);return true;} catch(err) {return false;}}'
        'pattern': 'function(name, value) {try {if(~value.toString().indexOf("{")){JSON.parse(value)} else {JSON.stringify(value)};return true;} catch(err) {return false;}}'
        //'pattern': 'var fn = function(value) {alert(value);}'
      },
      'hidden': {
        'message': 'Detta skall alltid gå igenom',
        'pattern': '//'
      },
      'uri': {
        'message': 'Var vänlig och ange en korrekt URL',
        'pattern': '/(http(s|)://)(www|).*?\\.(com|org|net|uk|ua|ru|se)/g'
      },
      'min': {
        'message': 'Var vänlig ange ett värde högre än $system1',
        'pattern': ''
      },
      'max': {
        'message': 'Var vänlig ange ett värde lägre än $system1',
        'pattern': ''
      },
      'required': {
        'message': 'Detta fält får inte vara tomt.',
        'pattern': ''
      }
    };
    return fieldFormats;
  }
}
module.exports = validate;