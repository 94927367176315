var React = require('react');
var ReactPropTypes = React.PropTypes;
var request = require('superagent');

var FilePicker = React.createClass({displayName: "FilePicker",
  propTypes: {
    id: React.PropTypes.string.isRequired,
    name: React.PropTypes.string.isRequired,
    value: React.PropTypes.string,
    config: React.PropTypes.object.isRequired
  },
  componentDidMount: function() {
    var propField = document.getElementById(this.props.id);
    document.getElementById(this.props.id).type = 'hidden';
    var propertyLabel = Array.prototype.slice.call(document.querySelectorAll('[for="' + this.props.name + '"]'));
    if(propertyLabel.length) {
      propertyLabel[0].style.display = 'none';
    }
  },
  _deleteAccessToken: function(event) {
    var item = event.target;
    var accessToken = $(item).parent().data('token-id').toString();
    var model = this._getModel().filter(function(at) {
      return at !== accessToken;
    });
    this._update(model);
  },
  _onChange: function() {
    // if (location.pathname === '/media' || location.pathname === '/media/') {
    if (location.host !== 'localhost:14000' && location.host !== 'klar.io') {
      return;
    }
      // window.twixlyCreateMediaItem(file);
    // }
    var self = this;
    filepicker.setKey('AYFHJ0TlXTtavagazzdF3z');
    filepicker.pick(
      function(blob) {
        //console.log(blob);
        var file = {
          url: blob.url,
          name: blob.filename,
          content_type: blob.mimetype,
          size: blob.size
          //id: blob.id,
          //client: blob.client,
          //is_writeable: blob.isWriteable,
        }
        self._update(file);
      }
    );
  },
  _update: function(model) {
    var propField = document.getElementById(this.props.id);
    propField.value = JSON.stringify(model);
    propField.click();
  },
  _getModel: function() {
    var propValue = this.props.value;
    // console.log(propValue)
    // Fix for one page website builder
    if (propValue && typeof propValue === 'string' && propValue.startsWith('http')) {
      propValue = {
        url: propValue,
        name: propValue,
        content_type: 'image/jpg',
        size: 0
      };
      propValue = JSON.stringify(propValue);
      // console.log(propValue)
    }
    var model = 
        propValue ?
        JSON.parse(propValue) :
        null;
    return model;
  },
  render: function() {
    var config = this.props.config;
    var value = this.props.value;
    var model = this._getModel();
    var fileItems;
    var file = '';
    var pickText = 'Välj fil';
    var title = config.title;
    var fileView;
    var fileInfo;
    var loader;
    var domain = '';
    if(model && !~model.url.indexOf('https://')) {
      if (window.context) {
        domain = window.context.render_domain;
      }
    }
    title = config.required ? title + ' *' : title;
    var buttonStyle = {display: 'none', marginBottom: '0', position: 'absolute', top: '3px', right: '3px'};
    var buttonStyle1 = {display: 'inline-block', margin: '20px 0'};
    if(model) {
      buttonStyle.display = 'block';
      buttonStyle1.display = 'none';
      pickText = 'Byt fil';
      fileView = React.createElement("a", {onClick: this._onChange, ref: model.url, target: "_blank", title: 'Byt fil'}, React.createElement("span", {className: "fa fa-file text-primary", style: {cursor: 'pointer',fontSize: '100px', position: 'absolute', top: '45px', left: '36%'}}));
      if(~model.content_type.indexOf('image')) {
        if (location.host === 'localhost:14000' || location.host === 'klar.io') {
          fileView = (
            React.createElement("a", {onClick: this._onChange, ref: model.url, target: "_blank", title: 'Byt fil'}, 
              React.createElement("img", {src: domain + model.url, alt: "", style: {cursor: 'pointer',maxWidth: '100%',position: 'relative', isplay: 'none'}})
            )
          );
        } else {
          fileView = (
            React.createElement("a", {href: domain + model.url, target: "_blank", title: "Öppna i nytt fönster"}, 
              React.createElement("img", {src: domain + model.url, alt: "", style: {cursor: 'pointer', height: '250px', maxWidth: '300px',position: 'relative', isplay: 'none'}})
            )
          );
        }
      }
      if(!fileView) {
        loader = React.createElement("span", {className: "fa fa-cog fa-spin text-primary", style: {fontSize: '50px', position: 'absolute', top: '70px', left: '44%'}})
      }
      fileInfo = (
        React.createElement("div", {lassName: "panel panel--box", tyle: {padding: '2px', overflow: 'hidden', position: 'absolute', zIndex: 1000, top: 10, left: 10, widt: 'auto'}}, 
          React.createElement("div", {lassName: "panel-body panel-body--content ill text-left", tyle: {padding: '2px'}}, 
          React.createElement("div", {className: "panel-footer", style: {overflow: 'hidden'}}, 
            "Filnamn: ",  model.original_name ? model.original_name : model.name, React.createElement("br", null), 
            "Typ: ", model.content_type, React.createElement("br", null), 
            
              model.size && (
                React.createElement("span", null, 
                  "Storlek: ", parseInt(model.size/1000) + 'KB', React.createElement("br", null)
                )
              ), 
            
            React.createElement("a", {href: domain + model.url, target: "_blank", title: domain + model.url}, "Öppna i nytt fönster")
          )
          )
        )
      );
      file = (
        React.createElement("div", {href: "#", className: "humbnail", style: {marginBottom: 0, minHeight: '200px', position: 'relative'}}, 
          loader, 
          fileView
        )
      )
    }
    fileItems = (
      React.createElement("div", {className: "text-center", style: {}}, 
        file, 
        React.createElement("button", {className: "btn btn-default tn-sm btn-success", style: buttonStyle1, type: "button", onClick: this._onChange, title: pickText}, pickText)
      )
    );
    return (
      React.createElement("div", {className: "panel panel-default panel--box clearfix", style: {marginBottom: 0, overflow: 'hidden'}}, 
        React.createElement("div", {className: "panel-heading clearfix", style: {position: 'relative'}}, 
          
            (location.pathname !== '/media' && location.pathname !== '/media/') && (location.host === 'localhost:14000' || location.host === 'klar.io')  &&
            React.createElement("button", {className: "btn btn-default btn-sm btn-success", style: buttonStyle, type: "button", onClick: this._onChange, title: pickText}, pickText), 
          
          React.createElement("h3", {className: "panel-title"}, title)
        ), 
        React.createElement("div", {className: "panel-body panel-body--content ill", style: {padding: '2px', overflow: 'hidden'}}, 
          fileItems
        ), 
        fileInfo
      )
    );
  }
});
 
module.exports = FilePicker;

