var navMenuNavigation = {
  init: function() {
    if($('.nav--menu-navigation').length) {
      function run() {
        //Sort navigation
        var sortSelector = '.nav--menu-navigation';
        sortSelector = 'ul';
        var handle = '.move-item';
        var items = '>li';
        $(sortSelector).sortable({
          // group: 'nested',
          // connectWith: 'ul',
          items: items,
          handle: handle,
          placeholder: 'sortable-placeholder',
          axis: 'y',
          revert: 300,
          delay: 100,
          start: function(event, ui) {
            var item = ui.item;
            var placeholder = ui.placeholder;
            placeholder.css('height', item[0].offsetHeight+1 + 'px');
            // $('.nav--menu-navigation ul').css('min-height', '10px');
            // $('.nav--menu-navigation ul').css('background', 'white');
          },
          stop: function(event, ui) {
            // $('.nav--menu-navigation ul').css('min-height', '0');
            var item = ui.item;
            var to = item.index();
            var parent_id = item.parent().parent().data('id') || '';
            var id = item.data('id');
            var itemType = 'nav-menu-item';
            var setType = '&_item_type=nav-menu-item'; 
            var data = 
            '_action=reorder' +
            '&' +
            '_id=' + id +
            '&' +
            '_parent_id=' + parent_id +
            '&' +
            '_item_type=' + itemType +
            '&' +
            '_to=' + to;
            //console.log('query: ' + data);
            $.ajax({
              type: 'POST',
              data: data,
              success: function(response) {
                //$('#nav-items-reload-items-from-server')[0].click();
                console.log(response);
                parent.frames.postMessage({'action': 'reload_template'}, '*');
              }
            });
          }
        });
      }
      run();
    }
  }
}

module.exports = navMenuNavigation;