var formView = require('./formView.js');

var listView = {
  init: function() {
    /*
    Bulk options
    --------------------------------------------------------------------------------------------------------------------------------
    Variables
    --------------------------------------------------------------------------------------------------------------------------------*/
    // var list = $('.js-list-view table');
    // var cbSelectAll = list.find('th input[type=checkbox]');
    // var checkboxes = list.find('td input[type=checkbox]');
    var listViewSelector = '.js-list-view table';
    var cbSelectAllSelector = '.js-list-view table th input[type="checkbox"]';
    var checkboxesSelector = '.js-list-view table td input[type="checkbox"]';
    var isNew = false;
    // if (Boolean($('body').attr('class').indexOf('cms'))) {
    if (!$('body.cms').length) {
      isNew = true;
      listViewSelector = '.js-list-view';
      cbSelectAllSelector = '.js-list-view .js-bulk-delete-select-all input[type="checkbox"]';
      checkboxesSelector = '.js-list-view .js-bulk-delete input[type="checkbox"]';
    }
    /*
    --------------------------------------------------------------------------------------------------------------------------------
    Events
    --------------------------------------------------------------------------------------------------------------------------------*/
    //Bulkoptions
    // cbSelectAll.unbind();
    $('body').on('click', cbSelectAllSelector, function() {
      var isChecked = $(cbSelectAllSelector).is(':checked');
      toggleCheckAll(isChecked);
    });
    $('body').on('click', checkboxesSelector, function() {
      var checkboxesChecked = $(listViewSelector).find('td input[type=checkbox]:checked');
      if (isNew) {
        checkboxesChecked = $(listViewSelector).find('li input[type="checkbox"]:checked');
        // Make it work on tables to. Refactor when you got time.
        if (!checkboxesChecked.length) {
          checkboxesChecked = $(listViewSelector).find('td input[type="checkbox"]:checked');
        }
      }
      if(checkboxesChecked.length == $(checkboxesSelector).length) {
        $(cbSelectAllSelector).prop('checked', true);
      }
      if(checkboxesChecked.length != $(checkboxesSelector).length) {
        $(cbSelectAllSelector).removeAttr('checked');
      }
      if($(this).is(':checked')) {
        $('.btn--bulk').removeClass('hide');
        $('.btn--choose-item').removeClass('hide');
        $('.js-list-toolbar').removeClass('hide');
      } else {
        if (checkboxesChecked.length === 0) {
          $('.btn--bulk').addClass('hide');
          $('.btn--choose-item').addClass('hide');
          $('.js-list-toolbar').addClass('hide');
        }
      }
      //console.log(checkboxesChecked.length + ' : ' + checkboxes.length);
    });
    $('.btn--bulk').click(function() {
      $('#cms-list-form').submit();
      return false;
    });
    /*
    --------------------------------------------------------------------------------------------------------------------------------
    Functions
    --------------------------------------------------------------------------------------------------------------------------------*/
    var toggleCheckAll = function(isChecked) {
      var checkboxes = $(checkboxesSelector);
      if(!isChecked) {
        $(checkboxesSelector).removeAttr('checked');
        $('.btn--bulk').addClass('hide');
        $('.btn--choose-item').addClass('hide');
        $('.js-list-toolbar').addClass('hide');
      } else {
        $(checkboxesSelector).prop('checked', true);
        $('.btn--bulk').removeClass('hide');
        $('.btn--choose-item').removeClass('hide');
        $('.js-list-toolbar').removeClass('hide');
      }
    }
    if($('.sortable-table').length) {
      function run() {
        //Sort navigation
        // Return a helper with preserved width of cells
        var fixHelper = function(e, ui) {
          //var trs = ui.parent().find('tr');
          ui.children().each(function() {
            $(this).width($(this).width());
          });
          // trs.each(function() {
          //   $(this).width(ui.width());
          // });
          return ui;
        };
        var sortSelector = '.sortable-table tbody';
        var handle = '.move-item';
        var items = '> tr';
        //var sortableStartIndex = null;
        $(sortSelector).sortable({
          items: items,
          handle: handle,
          placeholder: 'sortable-placeholder',
          axis: 'y',
          revert: 300,
          delay: 100,
          helper: fixHelper,
          //containment: 'body',
          //tolerance: 'pointer',
          start: function(event, ui) {
            var item = ui.item;
            var placeholder = ui.placeholder;
            placeholder.css('height', item[0].offsetHeight+1 + 'px');
            //sortableStartIndex = item.index();
          },
          stop: function(event, ui) {
            var item = ui.item;
            //var from = sortableStartIndex;
            var to = item.index();
            var parent_id = '';  
            var id = item.data('id');
            var itemType = item.parent().parent().parent().data('item-type-name') ? item.parent().parent().parent().data('item-type-name') : '';
            var data = 
            '_action=reorder' + 
            '&' +
            '_id=' + id +
            '&' +
            '_parent_id=' + parent_id +
            '&' +
            '_item_type=' + itemType + 
            //'&' +
            //'_from=' + from +
            '&' +
            '_to=' + to;
            $.ajax({
              type: 'POST',
              //url: url,
              data: data,
              success: function(response) {
                console.log('Something happend');
              }
            });
          }
        });
      }
      run();
    }
    this.setListEvents();
  },
  setListEvents: function() {
    var self = this;
    var listForm = $('.list-view form');
    var url = localStorage.getItem(window.currentBucketId + '-' + window.currentItemType + '-item-url');
    if (url) {
      self._updatePage(url);
      listForm.find('table tbody tr a[href="'+url+'"]').parent().parent().addClass('active');
    }
    // listForm.find('table td a').each(function(index) {
      // alert($(this).prop('href'));
      var link = $(this);
      listForm.find('table tbody tr').click(function(e) {
        if (e.target.type && e.target.type === 'checkbox') {
          // return false;
        } else {
          listForm.find('table tr').removeClass('active');
          self._updatePage($(this).find('a').attr('href'));
          // alert($(this).find('a').attr('href'))
          $(this).addClass('active');
          return false;  
        }
      });
    // });
    $('.list-view .btn--add').click(function() {
      listForm.find('table tr').removeClass('active');
      self._updatePage($(this).prop('href'));
      return false;
    });
  },
  _updatePage: function(url, data) {
    if (data) {
      history.replaceState(null, null, url);
    }
    $('.update-panel').html($('#pre-loader').clone());
    $('.update-panel').find('#pre-loader')
    .removeClass().addClass('loader')  
    .addClass('loader--light-inverted')
    .addClass('loader--absolute')
    .css('margin-top', '-80px')
    $('.update-panel').find('#pre-loader .content')
    .css('background', 'transparent')
    .css('box-shadow', 'none')
    $('.update-panel').find('#pre-loader .content').text('Laddar...');
    setTimeout(function(){if($('#react-form').length === 0){$('.update-panel').find('#pre-loader').css('visibility', 'visible');}}.bind(this), 250);
    this._getPageItem(url, {ajax: true}, function(response) {
      if (response.status === 400) {
        $('.update-panel').find('#pre-loader').css('visibility', 'hidden');
        localStorage.removeItem(window.currentBucketId + '-' + window.currentItemType + '-item-url');
        return;
      }
      var objHtml = $(response).find('.update-panel').html();
      $('.update-panel').html(objHtml);
      window.context.item_id = data ? data.created_id : window.currentItemId;
      window.context.item_type = data ? data.item_type : window.currentItemType;
      systemRenderForm('.update-panel');
      $('#react-form').css('visibility', 'visible');
      // this.setSubmitEvents();
      formView.init();
      localStorage.setItem(window.currentBucketId + '-' + window.currentItemType + '-item-url', url);
    }.bind(this));
  },
  _getPageItem: function(url, query, callback) {
    query = query || {};
    var data = $.param(query);
    $.ajax({
      type: 'GET',
      url: url,
      data: data,
      cache: false
    })
    .done(function(response) {
      callback(response);
    })
    .error(function(response) {
      callback(response);
    });
  },
  reloadList: function() {
    var self = this;
    var url = location;
    query = {ajax: true};
    var data = $.param(query);
    $.ajax({
      type: 'GET',
      url: url,
      cache: false
    })
    .done(function(response) {
      var objHtml = $(response).find('.list-view form').html();
      $('.list-view form').html(objHtml);
      self.init();
    });
  }
}

module.exports = listView;