var jsonschema = require('jsonschema');
var ValidationError = jsonschema.ValidationError;
var Validator = jsonschema.Validator;
var validator = new Validator();

var validate = {
  form: function(instance, schema) {
    instance = instance ?
               JSON.parse(JSON.stringify(instance)) :
               instance;
    schema = schema ?
             JSON.parse(JSON.stringify(schema)) :
             schema;
    var self = this;
    // Validates item and item type
    if (schema.attributes && schema.attributes.schema) {
      if (instance.relationships) {
        for (var relPropKey in instance.relationships) {
          var relProp = instance.relationships[relPropKey];
          instance.attributes[relPropKey] = relProp;
        }
      }
      instance = instance.attributes;
      schema = schema.attributes.schema;
    }
    /* 
    /* If field is required and the value length is zero then remove instance value
    /* If field is not required and value length is zero and the field has format attached to it then remove instance value */
    for (var propKey in schema.properties) {
      var prop = schema.properties[propKey];
      /* If field is required and the value length is zero then remove instance value */
      if (schema.required && ~schema.required.indexOf(propKey) && (prop.type === 'string' || prop.type === 'slug' || prop.type === 'text') && typeof instance[propKey] === 'string' && instance[propKey].length === 0) {
        delete instance[propKey];
        // break;
      }
      // if (prop.type === 'string' && ~schema.required.indexOf(propKey) && instance[propKey].length === 0) {
      // if (schema.required) {
        // if (~schema.required.indexOf(propKey) && instance[propKey].length === 0) {
        // if (instance[propKey].length === 0) {
        //   delete instance[propKey];
        //   break;
        // }
      // }
      /* If field is not required and value length is zero and the field has format attached to it then remove instance value */
      // if ((!schema.required || !~schema.required.indexOf(propKey)) && prop.format && instance[propKey].length === 0) {
      //   delete instance[propKey];
      //   break;
      // }
    }

    // Type: slug
    // validator.attributes.type = function validateContains(instance, schema, options, ctx) {
    //   if (schema.type === 'slug') {
    //     // if (schema.required && (typeof instance === 'undefined' || instance.length === 0)) {
    //     //   return this.fieldMessages['required'].message;
    //     // }
    //     const regExp = new RegExp(/^[a-z0-9_-]+$/, 'g');
    //     if (typeof instance === 'undefined' || instance.length === 0 || regExp.test(instance)) {
    //       return;
    //     }
    //     return this.fieldMessages['slug'].message;
    //   } else if (schema.type === 'relationship') {
    //     // console.log('instance', instance);
    //     // console.log('schema', schema);
    //     // console.log('options', options);
    //     // console.log('ctx', ctx.propertyPath);
    //     if (typeof instance === 'string' && instance.length > 0) {
    //       return;
    //     }
    //     if (typeof instance === 'object') {
    //       return;
    //     }
    //     return this.fieldMessages['relationship'].message;
    //   } else {
    //     return;
    //   }
    // }.bind(this);

    Validator.prototype.customFormats.json = function(input) {
      if (typeof input === 'undefined') return true;
      if (typeof input === 'object') return true;
      try {
        JSON.parse(input);
        return true;
      } catch(err) {
        return false;
      }
    };

    var validation = validator.validate(instance, schema);
    
    // Custom types
    function validateSlug(instance, schema, key, pK) {
      var value = instance;
      var propPath = pK ?
                     'instance.' + pK + '.' + key :
                     'instance.' + key;
      if (typeof instance === 'undefined') {
        return;
      }
      if (typeof instance === 'string' && instance.length === 0) {
        return;
      }
      const regExp = new RegExp(/^[a-z0-9_-]+$/g);
      if (value && !regExp.test(value)) {
        var validationError = new ValidationError(self.fieldMessages['slug'].message, value, schema.properties[key], propPath);
        // var validationError = new ValidationError('The slug field is not correct', formData.slug, schema.properties.slug, 'instance.slug');
        validation.errors.push(validationError);
        // validation.addError('fails some validation test');
      }
    }

    function validateRelationship(instance, schema, key, pK) {
      var propPath = pK ?
                     'instance.' + pK + '.' + key :
                     'instance.' + key;
      var validationError = new ValidationError(self.fieldMessages['required'].message, instance, schema.properties[key], propPath);
      if (typeof instance === 'undefined') {
        // ToDo: check if not required
        // validation.errors.push(validationError);
        return;
      }
      if (typeof instance === 'undefined') {
        validation.errors.push(validationError);
        return;
      }
      if (typeof instance === 'string') {
        instance = JSON.parse(instance);
      }
      if (schema.required && ~schema.required.indexOf(key)) {
        // if (typeof value.data === 'object' && Object.keys(value.data).length === 0) {
        if (!instance.data) {
          validation.errors.push(validationError);
        } else if (Array.isArray(instance.data) && instance.data.length === 0) {
          validation.errors.push(validationError);
        }
      }
      // var value = instance;
      // const regExp = new RegExp(/^[a-z0-9_-]+$/, 'g');
      // if (value && !regExp.test(value)) {
      //   var validationError = new ValidationError(self.fieldMessages['slug'].message, value, schema, 'instance.' + key);
      //   // var validationError = new ValidationError('The slug field is not correct', formData.slug, schema.properties.slug, 'instance.slug');
      //   validation.errors.push(validationError);
      //   // validation.addError('fails some validation test');
      // }
    }

    function _activateCustomTypes(instance, schema, pK) {
      if (schema.properties) {
        for(var propKey in schema.properties) {
          var prop = schema.properties[propKey];
          propKey = propKey && ~propKey.indexOf('__') ?
                    propKey.split('__')[1] :
                    propKey;
          var value = instance ? instance[propKey] : undefined;
          if (prop.type === 'object') {
            _activateCustomTypes(value, prop, propKey);
          } else if (prop.type === 'array') {
            if (value) {
              var array = value;
              for (var i = 0; i < array.length; i++) {
                _activateCustomTypes(array[i], prop.items, propKey + '[' + i + ']');
              }
            }
          } else {
            if (prop.type === 'slug') {
              validateSlug(value, schema, propKey, pK);
            }
            if (prop.type === 'relationship') {
              validateRelationship(value, schema, propKey, pK);
            }
          }
        }
      }
    }
    _activateCustomTypes(instance, schema);   

    validation = this.convertJSONSchemaObjectToRestfulErrors(validation, instance);
    return validation;
  },
  schema: function(schema) {
    schema = schema ?
             JSON.parse(JSON.stringify(schema)) :
             schema;
    if (schema.attributes && schema.attributes.schema) {
      schema = schema.attributes.schema;
    }

    // var check = validator.addSchema(schema);

    // console.log('CHECK: ', check);

    var errors = [];
    return errors;
  },
  convertJSONSchemaObjectToRestfulErrors: function(validation, instance) {
    // console.log('SCHEMA_VALIDATION: ', validation);
    var errors = [];
    if (validation && validation.errors) {
      for (var i = 0;i < validation.errors.length; i++) {
        var error = validation.errors[i];
        // Replace Json schema message with pattern message if both property pattern and property message is present
        if (error.schema.pattern && error.schema.message) {
          error.message = error.schema.message;
        }
        // console.log('error.property: ', error.property);
        if (error.message === 'is required') continue;
        // if (typeof error.argument !== 'undefined' && error.property === 'instance') continue;
        // if (~error.property.indexOf('instance.')) {
          var fieldName;
          if (~error.property.indexOf('instance.')) {
            fieldName = error.property.split('.')[1];
          } else {
            fieldName = error.argument;
          }
          if (error.schema.type === 'slug') {
            fieldName = error.property.replace('instance.', '');  
          } else if (error.schema.type === 'relationship') {
            fieldName = error.property.replace('instance.', '');  
          } else {
            if (error.property.split('.').length > 1) {
              // if (error.property.split('.').length !== 3) continue;
              // fieldName = error.property.replace('instance.', '') + '.' + error.argument;
              if (error.name !== 'format') {
                // if (error.property.split('.')[1] === error.argument || error.property.split('.')[1] === error.argument[0]) {
                if (error.property.split('.')[1] === error.argument) {
                  fieldName = error.property.split('.')[1];
                } else {
                  if (!error.schema.pattern && !(error.property === 'instance.number' || error.property === 'instance.integer')) {
                    fieldName = error.property.split('.')[1] + '.' + error.argument;  
                  }
                }
              }
            }
          }
          // if (error.schema.type === 'object') {
            // continue;
            // if (error.property.split('.').length !== 3) continue;
            // fieldName = error.property.split('.')[1] + '.' + error.property.split('.')[2];
          // }
          var newError = {
            status: 422,
            title: 'Invalid Attribute',
            source: {
              pointer: '/data/attributes/' + fieldName
            },
          };
          if (error.schema.type === 'slug') {
            newError.detailed = error.message;
          } else if (error.schema.type === 'relationship') {
            newError.detailed = error.message;
          } else if (error.name) {
            if (error.name === 'required') {
              newError.detailed = this.fieldMessages['required'].message;
            } else if (error.name === 'format') {
                newError.detailed = this.fieldMessages[error.argument].message;
            } else if (Array.isArray(error.argument)) {
              if (error.argument[0] === 'integer') {
                newError.detailed = this.fieldMessages['integer'].message;
              } else if (error.argument[0] === 'number') {
                newError.detailed = this.fieldMessages['number'].message;
              }
            } else {
              newError.detailed = error.message;
            }
          }
          // Fix for now. If URI start with a hash, we say it's okay.
          if (error.argument === 'uri' && error.instance.startsWith('#')) {
            // console.log(error.instance)
          } else {
            errors.push(newError);
          }
        // }
      }
    }
    var v = {
      isValid: errors.length ? false : true,
      data: instance,
      errors: errors
    }
    return v;
  },
  convertJSONSchemaObjectToValidationObject: function(validation) {
    var validation = {
      isValid: validation.errors.length === 0,
      message: validation.errors.length ? validation.errors[0].message : ''
    }
    return validation;
  },
  fieldMessages: {
    'email': {
      'message': 'Var vänlig och fyll i en korrekt e-postadress.',
      'pattern': '/[a-z0-9]+[_a-z0-9\\.-]*[a-z0-9]+@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,4})/g'
    },
    'date': {
      'message': 'Värdet är inte ett korrekt datum.',
      'pattern': '/^(19|20)\\d\\d([- /.])(0[1-9]|1[012])\\2(0[1-9]|[12][0-9]|3[01])$/g'
    },
    'date-time': {
      'message': 'Värdet är inte ett korrekt datum med tid.',
      'pattern': '/^\\d{4}-\\d{2}-\\d{2} \\d{2}:\\d{2}$/g'
    },
    'number': {
      'message': 'Var vänlig och ange ett numeriskt värde',
      'pattern': '/^\\d+(\\.\\d{1,4})?$/g'
    },
    'integer': {
      'message': 'Värdet är inte en siffra.',
      'pattern': '/^\\d+(\\.\\d{1,4})?$/g'
    },
    'symbol': {
      'message': 'Värdet kan bara innehålla små bokstäver utan åäö eller dessa tecken: ("_", "-").',
      'pattern': '/^[a-z0-9_-]+$/g'
    },
    'text': {
      'message': 'Text får inte innehålla html-taggar.',
      'pattern': '//'
      // 'pattern': '/^(?!.*(>([^<]*)<))/g'
    },
    'textarea': {
      'message': 'Text får inte innehålla html-taggar.',
      'pattern': '//'
    },
    'slug': {
      //'message': 'Värdet kan bara innehålla små bokstäver utan åäö eller dessa tecken: ("_", "-") och måste vara unik.',
      'message': 'Värdet kan bara innehålla små bokstäver utan åäö eller dessa tecken: ("_", "-").',
      // 'message': 'Detta fält måste vara unikt.',
      //'pattern': '/^[a-z0-9_-]+$/g'
      'pattern': 'function(name, value) {try {var re = new RegExp("^[a-z0-9_-]+$", "g");if($("#js-slug-\" + name + \"-is-not-unique").length || !re.test(value)) {return false;} else {return true;}} catch(err) {return true;}}'
    },
    'markdown': {
      'message': 'Detta skall alltid gå igenom',
      'pattern': '//'
    },
    'json': {
      'message': 'Detta är inte korrekt JSON',
      //^\\"([^\\"\\\\]*|\\\\(["\\\\\/bfnrt]{1}|u[a-f0-9]{4}))*\\"$
      //'pattern': '/^\\"([^\\"\\\\]*|\\\\(["\\\\\/bfnrt]{1}|u[a-f0-9]{4}))*\\"$/g'
      //'pattern': '/^("(\\\\.|[^"\\\n\r])*?"|[,:{}\\[\\]0-9.\\-+Eaeflnr-u \\n\\r\\t])+?$/g'
      //'pattern': '//'
      // 'pattern': 'function(name, value) {try {JSON.parse(value);return true;} catch(err) {return false;}}'
      'pattern': 'function(name, value) {try {if(~value.toString().indexOf("{")){JSON.parse(value)} else {JSON.stringify(value)};return true;} catch(err) {return false;}}'
      //'pattern': 'var fn = function(value) {alert(value);}'
    },
    'hidden': {
      'message': 'Detta skall alltid gå igenom',
      'pattern': '//'
    },
    'relationship': {
      'message': 'Fel i relationship-objektet.',
      'pattern': '//'
    },
    'uri': {
      'message': 'Var vänlig och ange en korrekt URL',
      'pattern': '/(http(s|)://)(www|).*?\\.(com|org|net|uk|ua|ru|se)/g'
    },
    'min': {
      'message': 'Var vänlig ange ett värde högre än $system1',
      'pattern': ''
    },
    'max': {
      'message': 'Var vänlig ange ett värde lägre än $system1',
      'pattern': ''
    },
    'color': {
      'message': 'Var vänlig ange ett korrekt hexadecimalt värde',
      'pattern': ''
    },
    'required': {
      'message': 'Detta fält får inte vara tomt.',
      'pattern': ''
    }
  }
}
module.exports = validate;