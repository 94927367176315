// require('../../bower_components/ace-builds/src-min-noconflict/ace.js');
// ace.config.set('basePath', '/bower_components/ace-builds/src-min-noconflict');
// var cloudflareAceScript = document.createElement('script');
// cloudflareAceScript.type = 'text/javascript';
// cloudflareAceScript.src = '//cdnjs.cloudflare.com/ajax/libs/ace/1.1.3/ace.js';
// cloudflareAceScript.async = 'true';
// cloudflareAceScript.onload = function(){ace.config.set('basePath', '/bower_components/ace-builds/src-min-noconflict');markdownEditor.init();};
// document.head.appendChild(cloudflareAceScript);
// document.write('<script src="//cdnjs.cloudflare.com/ajax/libs/ace/1.1.3/ace.js"></script>');
// document.write('<script onload="document.querySelector(\'[data-item-type]\').style.visibility=\'visible\';" src="//sdk.twixly.io/ace-builds/src-min-noconflict/ace.js"></script>');

// load jquery 
window.$ = window.jQuery = require('jquery');
// require('./utils.js').showLoader();

window.C10 = {};
C10.API = {};
// C10.API.accessToken = null;
// C10.API.bucketId = null;

// if (localStorage.getItem('C10-API-bucketId') && localStorage.getItem('C10-API-accessToken-' + localStorage.getItem('C10-API-bucketId'))) {
//   C10.API.accessToken = localStorage.getItem('C10-API-accessToken-' + localStorage.getItem('C10-API-bucketId'));
//   C10.API.bucketId = localStorage.getItem('C10-API-bucketId');
//   window.accessToken = C10.API.accessToken;
//   window.bucketId = C10.API.bucketId;
// }

if (typeof isOldCMS !== 'undefined') {
  var cms;
  var clientConfig;
  if (typeof window.envLocal !== 'undefined') {
    cms = require('../../../../sdk/index.js');
    clientConfig = {
      host: 'localhost',
      port: '8080', 
      secure: false,
      bucket: window.bucketId
    }  
  } else {
    cms = require('twixly');
    clientConfig = {
      bucket: window.bucketId
    }
  }
  if (typeof window.accessToken !== 'undefined') {
    clientConfig['accessToken'] = window.accessToken;
  } else {
    clientConfig['username'] = window.username;
    clientConfig['password'] = window.password;
  }
  window.cmsClient = new cms.Client(clientConfig);
}
// if (localStorage.getItem('C10-API-bucketId') && localStorage.getItem('C10-API-accessToken-' + localStorage.getItem('C10-API-bucketId'))) {
//   window.C10.api = window.cmsClient;
// }

// load jquery UI
// load everything 
  //require('jquery-ui');
// or load just the modules you need 
//require('jquery-ui/draggable');   
//require('jquery-ui/droppable');
require('./jquery.nestable.js');
require('jquery-ui/sortable');   
require('jquery-ui/accordion');
// load jquery date time picker
require('../../bower_components/bootstrap/dist/js/bootstrap.min.js');
require('../../bower_components/bootstrap-contextmenu/bootstrap-contextmenu.js');
require('../../bower_components/datetimepicker/jquery.datetimepicker.js');
// require('../../../node_modules/bootstrap-markdown/js/bootstrap-markdown.js');

// Get lib components
var aceTexteditor = require('./aceTexteditor.js');    
var markdownEditor = require('./markdownEditor.js');    
var controls = require('./controls.js');    
var dialog = require('./dialog.js');    
var sidebar = require('./sidebar.js');
var pageNavigation = require('./pageNavigation.js');
var navMenu = require('./navMenu.js');
var listView = require('./listView.js');
var formView = require('./formView.js');
var postMessage = require('./postMessage.js');    

$(document).ready(function() {
  sidebar.init();
  aceTexteditor.init();
  markdownEditor.init();
  controls.init();
  dialog.init();
  pageNavigation.init();
  navMenu.init();
  listView.init();
  formView.init();
  postMessage.init();
  $('body.cms').tooltip({
    selector: '[data-toggle="tooltip"]',
    // container: 'body',
    delay: 150
  });
  $('body.cms').on('mousedown, focus','[data-toggle="tooltip"]', function() {
      $(this).tooltip('hide');
  });
  
  if($('#validation-errors').length) {
    controls.validateForm();
  }
  if (typeof cmsFormCloseDialog !== 'undefined') { cmsFormCloseDialog(); }

  // Post message to top window
  var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
  var eventer = window[eventMethod];
  var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';
  eventer(messageEvent, function(e) { window.executePostMessageEvent(e.data); }, false);
  window.executePostMessageEvent = function(data) {
    if (data.action == 'current_link_object_response') {
      // console.log('CurrentLinkObject: ' + data.value);
      window.windowCurrentLinkObject = data.value;
      // if (typeof cmsFormAddRelationship !== 'undefined') { cmsFormAddRelationship(); }
      // if (typeof cmsFormAddRelationship !== 'undefined' && !(typeof windowCurrentLinkObject_1 !== 'undefined' && windowCurrentLinkObject_1)) { cmsFormAddRelationship(); }
      // if (typeof windowCurrentLinkObject !== 'undefined' && typeof windowCurrentLinkObject_1 !== 'undefined') {
      //   alert('windowCurrentLinkObject: ' + windowCurrentLinkObject)
      //   localStorage.setItem('accordionIndex', false);
      // }
      if (typeof windowCurrentLinkObject !== 'undefined' && windowCurrentLinkObject) {
        eval(windowCurrentLinkObject.iframe).postMessage({'action': 'current_link_object_iframe_input_value', 'input_id': windowCurrentLinkObject.input_id}, '*');
      }
    } else if (data.action == 'current_link_object_iframe_input_value_response') {
      // console.log('CurrentLinkObjectIframeInputValue: ' + data.value);
      window.windowCurrentLinkObjectIframeInputValue = data.value;
    } else if (data.action == 'current_link_object_1_iframe_input_value_response') {
      // console.log('CurrentLinkObjectIframeInputValue: ' + data.value);
      window.windowCurrentLinkObjectIframeInputValue_1 = data.value;
    } else if (data.action == 'current_link_object_1_response') {
      // console.log('Value received: ' + data.value);
      window.windowCurrentLinkObject_1 = data.value;
      if (typeof cmsFormAddRelationship !== 'undefined') { cmsFormAddRelationship(); }
      if (typeof windowCurrentLinkObject_1 !== 'undefined' && windowCurrentLinkObject_1) {
        eval(windowCurrentLinkObject_1.iframe).postMessage({'action': 'current_link_object_1_iframe_input_value', 'input_id': windowCurrentLinkObject_1.input_id}, '*');
      }
    } else if (data.action == 'window_context_response') {
      // console.log('Context: ' + JSON.stringify(data.value));
      window.windowContext = data.value;
    } else {
      // console.log('Left over data:' + data);
    }
  };
  window.top.window.postMessage({'action': 'current_link_object'}, '*');
  window.top.window.postMessage({'action': 'current_link_object_1'}, '*');
  // window.top.window.postMessage({'action': 'current_link_object_iframe_input_value'}, '*');
  window.top.window.postMessage({'action': 'window_context'}, '*');

  $('body:not(.cms)').on('click', '.collapse', function (e) {
    if (~$(this).attr('class').indexOf('collapse in')) {
      if($(e.target).attr('class') !== 'dropdown-toggle') {
        $(this).removeClass('in');
      }
    }
  });
});

var systemModalStr = '';
systemModalStr += '<div id="systemModal" class="modal modal--default" role="dialog" aria-labelledby="systemModalLabel" aria-hidden="true" ata-backdrop="static">';
systemModalStr += '  <div class="modal-dialog modal-lg">';
systemModalStr += '    <div class="modal-content">';
systemModalStr += '      <div class="modal-header">';
systemModalStr += '        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
systemModalStr += '        <h4 class="modal-title">Modal title</h4>';
systemModalStr += '      </div>';
systemModalStr += '      <div class="modal-body" style="padding: 0;background: #f1f1f1">';
systemModalStr += '        <div class="loader" style="background: #f1f1f1;position:absolute;z-index: 3;"><div class="content" style="background:none;box-shadow:none;">Laddar...</div></div>';
systemModalStr += '        <iframe class="iframe" style="position:relative;z-index:2;" scrolling="auto" frameborder="0" name="modal-iframe" id="modal-iframe" src="about:blank"></iframe>';
systemModalStr += '      </div>';
systemModalStr += '      <div class="modal-footer">';
systemModalStr += '        <button type="button" class="btn btn-default js-dialog-cancel" data-dismiss="modal">Avbryt</button>';
systemModalStr += '        <button type="button" class="btn btn-default js-dialog-delete hidden">Ta bort</button>';
systemModalStr += '        <button type="button" class="btn btn-primary js-dialog-save">Spara</button>';
systemModalStr += '      </div>';
systemModalStr += '    </div><!-- /.modal-content -->';
systemModalStr += '  </div><!-- /.modal-dialog -->';
systemModalStr += '</div><!-- /.modal -->';
$('body').append(systemModalStr);

window.pageNavigation = pageNavigation;
