var React = require('react');
var controls = require('../../lib/controls.js');

// var PropertyItem = require('./PropertyItem');
// var PropertyObject = require('./PropertyObject');
// var PropertyArray = require('./PropertyArray');

var SchemaField1 = React.createClass({displayName: "SchemaField1",
  propTypes: {
    type: React.PropTypes.string.isRequired,
    name: React.PropTypes.string.isRequired,
    schema: React.PropTypes.object.isRequired,
    uiSchema: React.PropTypes.object.isRequired,
    isNew: React.PropTypes.bool.isRequired,
    onFormChange: React.PropTypes.func.isRequired,
    initControls: React.PropTypes.bool
  },
  
  componentDidMount: function() {
    
  },

  componentDidUpdate: function() {
    if (this.props.initControls) {
      if (typeof window.arrayInitControlsTimeoutId !== 'undefined') {
        clearTimeout(window.arrayInitControlsTimeoutId);  
      }
      window.arrayInitControlsTimeoutId = setTimeout(function () {
        // console.log('this.props.initCfdasdfontrols');
        controls.init();
      }, 1000);
    }
  },

  render: function() {
    // console.log(this.props)
    var field = (
      React.createElement(GetField, {type: this.props.schema.type, propKey: this.props.name, prop: this.props.schema, value: this.props.value, uiSchema: this.props.uiSchema, required: this.props.schema.required, isNew: this.props.isNew, onFormChange: this.props.onFormChange})
    );
    return (
      React.createElement("div", null, 
        field 
      )
    );
  }
});

var GetField = function(props) {
  var type = props.type;
  var propKey = props.propKey;
  var prop = props.prop;
  var uiSchema = props.uiSchema;
  var required = props.required;
  var value = props.value;
  var isNew = props.isNew;

  var formField;
  if (type === 'array' && !(prop.uniqueItems && prop.items && prop.items.enum) && !prop.extension) {
    // console.log('typsssssyyypeeeeee:', PropertyArray)
    value = value || [];
    formField = React.createElement(PropertyArray, {name: propKey, value: value, config: prop, uiSchema: uiSchema, required: required, isNew: isNew, onFormChange: props.onFormChange});
  } else if (type === 'object' && !prop.extension) {
    value = value || {};
    formField = React.createElement(PropertyObject, {name: propKey, value: value, config: prop, uiSchema: uiSchema, required: required, isNew: isNew, onFormChange: props.onFormChange});
  }
  // else {
  //   formField = <PropertyItem name={propKey} value={value} config={prop} uiSchema={uiSchema} required={required} uniqueArrayKey={(new Date()).getTime()} isNew={isNew} onFormChange={props.onFormChange} />;
  // }

  return (
    React.createElement("div", null, 
      formField
    )
  );
};

module.exports = SchemaField1;

