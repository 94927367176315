var React = require('react');
var ReactPropTypes = React.PropTypes;
var request = require('superagent');
var controls = require('../../../lib/controls.js');

var Relationship = React.createClass({displayName: "Relationship",
  propTypes: {
    id: React.PropTypes.string.isRequired,
    name: React.PropTypes.string.isRequired,
    value: React.PropTypes.string,
    config: React.PropTypes.object.isRequired,
    parentState: React.PropTypes.object.isRequired,
    updateRelationshipViewCallback: React.PropTypes.func.isRequired
  },
  componentDidMount: function() {
    var propField = document.getElementById(this.props.id);
    document.getElementById(this.props.id).type = 'hidden';
    var propertyLabel = $(propField).prev();
    if(propertyLabel.length && propertyLabel.prop('tagName') === 'LABEL') {
      propertyLabel.css('display', 'none');
    }
    this._setDefaultValue();
    propField.click();
    localStorage.removeItem('relAccordionIndex');
    // Do not load controls when this field resides in an array type. Refactor when time.
    if (!this.props.isArray) {
      controls.init();
    } else {
      // console.log('Is array in ralationship!');
    }
  },
  componentDidUpdate: function() {
    // Do not load controls when this field resides in an array type. Refactor when time.
    if (!this.props.isArray) {
      controls.init();
    } else {
      // console.log('Is array in ralationship!');
    }
  },
  _setDefaultValue: function() {
    var propField = document.getElementById(this.props.id);
    if(typeof this.props.value === 'undefined' || this.props.value.length === 0) {
      if(this.props.config.multiple) {
        propField.value = '{"data":[]}';
      } else {
        propField.value = '{"data":null}';
      }
    }
  },
  _reorderLinkView: function() {
    var ul = $('#'+this.props.name +'-ul'+' li');
    var input = $('#' + this.props.id);
    //console.log(ul);
    var newArray = {
      data: []
    };
    var value = JSON.parse(input.val());
    ul.each(function(item, index) {
      var item = $(this);
      var id = item.prop('id');
      var obj = value.data.filter(function(item) {
        return item.id === id;
      })
      newArray.data.push(obj[0]);
      //console.log(item.prop('id'));
    });
    //console.log(newArray);
    input.val(JSON.stringify(newArray));
    input.click();
    
    //this.props.updateRelationshipViewCallback();
  },
  render: function() {
    // if (typeof this.props.parentState.itemTypes === 'undefined') {
    //   return false;
    // }
    // console.log(this.props.parentState.itemTypes)
    if (typeof window.accessToken === 'undefined' && typeof window.username === 'undefined') {
      return (
        React.createElement("div", null, 
          React.createElement("label", {style: {marginBottom: '2px'}}, "The ", this.props.config.type, " control has a dependency!"), 
          React.createElement("p", {style: {marginBottom: '3px'}}, "You need to add a variable to the window object with the current access token."), 
          React.createElement("p", {style: {marginBottom: '0'}}, React.createElement("strong", null, "Example:"), React.createElement("br", null), "window.accessToken = [access_token];")
        )
      );
    }
    // console.log('Render relationship: ' + this.props.name);
    var self = this;
    var config = this.props.config;
    var value = this.props.value;
    var id = this.props.id;
    var name = this.props.name;
    var parentState = this.props.parentState;
    var disableAddLink = {marginBottom: '10px'};
    var multiple = config.multiple ? true : false;
    var linkageAddNewButton;
    var linkageItems = [];
    var linkageItemsView = [];
    var linkageItemsViewFull;
    var configArray = [];
    var _removeLinkItem = function(event) {
      var item = event.target;
      var linkId = $(item).parent().data('link-id');
      var input = $('#' + id);
      var tempValueParsed;
      var newValue = {
        data: []
      };
      if(~input.val().indexOf('[{')) {
        tempValueParsed = JSON.parse(input.val());
        newValue.data = tempValueParsed.data.filter(function (item) {
          return item.id !== linkId;
        });
        if(newValue.data.length) {
          newValue = JSON.stringify(newValue);  
        } else {
          newValue = JSON.stringify(newValue);  
        }
      } else {
        newValue = '{"data":null}';
        //newValue = '';  
      }
      input.val(newValue);
      input.click();
    }
    var _getDataItemTypes = function(condition) {
      var itemTypes = parentState.itemTypes && parentState.itemTypes.data;
      var data = [];
      if(typeof itemTypes === 'undefined') {
        return data;
      }
      for (var i = 0; i < itemTypes.length; i++) {
        var itemType = itemTypes[i];
        if (itemType.attributes[condition.property] === condition.value) {
          data.push({type:'items', id:itemType.id});
        }
      }
      return data;
    }
    var _getItemType = function(handle) {
      var itemTypes = parentState.itemTypes && parentState.itemTypes.data;
      // console.log(handle);
      // console.log(JSON.stringify(itemTypes));
      var tempObj = {
        attributes: {
          labels: {
            plural_name: '',
            singular_name: ''
          }
        }
      };
      if(typeof itemTypes === 'undefined') {
        return tempObj;
      }
      var itemType = itemTypes.filter(function(item) {
        return item.id === handle;
      })
      if(itemType.length) {
        return itemType[0];
      } else {
        return tempObj;
      }
    }
    var _getItem = function(id) {
      var items = parentState.items && parentState.items.data;
      var tempObj = {
        title: 'Laddar...',
        meta: {
          item_type: {
            data: {
              id: null
            }
          }
        }
      };
      if(typeof items === 'undefined') {
        return tempObj;
      }
      var item = items.filter(function (item) {
        return item.id === id;
      })
      if(item.length) {
        return item[0];
      } else {
        return tempObj;
      }
    }
    var _getAsset = function(id) {
      var media = parentState.media && parentState.media.data;
      var tempObj = {
        title: 'Laddar...',
        meta: {
          item_type: {
            data: {
              id: null
            }
          }
        }
      };
      if(typeof media === 'undefined' || media === null) {
        return tempObj;
      }
      var asset = media.filter(function (asset) {
        return asset.id === id;
      })
      if(asset.length) {
        return asset[0];
      } else {
        return tempObj;
      }
    }
    if (config.data && config.data.condition) { 
      configArray = _getDataItemTypes(config.data.condition);
    } else if($.isArray(config.data)) { 
      configArray = config.data;    
    } else {
      configArray[0] = config.data;
    }

    configArray.map(function(item, index) {
      var itemType = _getItemType(item.id);
      var itemTypeId;
      if(item.type === 'media') {
        //itemType = _getItemType(item.id);
        itemTypeId = '';
      } else {
        //itemType = _getItemType(item.id);
        itemTypeId = item.id;
      }
      var singularName = itemType.attributes.labels ?
                         itemType.attributes.labels.singular_name :
                         itemType.attributes.title;
      linkageItems.push(
        React.createElement("li", {key: index, role: "presentation"}, 
          React.createElement("a", {
            href: "javascript:void(0);", 
            "data-link-modal-config": '{"add_button": true, "title": "Lägg till", "add_new": false, "item_type_id": "' + itemTypeId + '", "input_id": "' + id + '", "multiple": ' + multiple + ', "type": "' + item.type + '"}'
          }, 
            singularName.replace(/^[a-z]/, function(m){ return m.toUpperCase() })
          )
        )
      );
    });
    var addLinkText = 'Lägg till';
    var addLinkIcon = React.createElement("span", {className: 'fa fa-plus'});
    if(value && !$.isArray(value.data) && !multiple) {
      disableAddLink = {marginBottom: '0', position: 'absolute', top: '2px', right: '2px'};
      addLinkText = 'Ändra';
      addLinkIcon = React.createElement("span", {className: 'fa fa-edit', style: {fontSize: '16px', verticalAlign: 'middle'}});
    } else {
      disableAddLink = {marginBottom: '0', position: 'absolute', top: '2px', right: '2px'};
    }
    if(configArray.length === 1) {
      var itemTypeId = configArray[0].id;
      if(configArray[0].type === 'media') {
        itemTypeId = '';
      }
      linkageAddNewButton = (
        React.createElement("div", {key: id, className: "ull-right", style: disableAddLink}, 
          React.createElement("button", {
            className: "btn btn-default btn-sm btn-success", 
            type: "button", 
            title: addLinkText, 
            "data-link-modal-config": '{"add_button": true,"title": "Lägg till", "mode": "' + config.mode + '", "item_type_id": "' + itemTypeId + '", "input_id": "' + id + '", "multiple": ' + multiple + ', "type": "' + configArray[0].type + '"}'
          }, 
          addLinkIcon
          )
        )
      );  
    } else {
      linkageAddNewButton = (
        React.createElement("div", {className: "dropdown ull-right", style: disableAddLink}, 
          React.createElement("button", {className: "btn btn-default btn-sm btn-success", type: "button", "data-toggle": "dropdown", title: addLinkText}, addLinkIcon, "  ", React.createElement("span", {className: "caret"})), 
          React.createElement("ul", {className: "dropdown-menu", style: {left: 'auto',right: 0}}, 
            linkageItems
          )
        )
      );  
    }
    if(value) {
      var valueArray = [];
      var valueObj = JSON.parse(value);
      if($.isArray(valueObj.data)) {
        valueArray = valueObj.data;
      } else {
        if(valueObj.data) {
          valueArray[0] = valueObj.data;
        }
      }
      valueArray.map(function(linkItem, index) { 
        var uniqueKey = Math.random();
        var item;
        var itemTypeId;
        //alert(linkItem.type)
        if(linkItem.type === 'media') {
          item = _getAsset(linkItem.id);
          itemTypeId = '';
        } else {
          item = _getItem(linkItem.id); 
          itemTypeId = item.meta.item_type.data.id;
        }
        var ifrHeight;
        if(config.field && config.field.height) {
          ifrHeight = config.field.height;
        }
        // not loaded yet. Will be rendered when loaded
        // if(!item.__meta.item_type) {
        //   return;
        // }
        // 
        if (config.mode === 'inline') {
        linkageItemsView.push(
          React.createElement("li", {id: linkItem.id, className: 'rel-' + linkItem.id, key: uniqueKey + linkItem.id, style: {borderBottom: 'none',position:'relative'}}, 
            React.createElement("div", {className: "list-g-heading--over", 
            style: {position:'absolute',height: '40px',width: '100%',ackground: 'red',zIndex: '1'}, 
            "data-link-modal-config": '{"title": "Ändra", "edit": true, "edit": true, "link_id": "' + linkItem.id + '", "height": "' + ifrHeight + '", "item_name": "' + _getItemType(itemTypeId).labels.singular_name + '", "mode": "' + config.mode + '", "item_type_id": "' + itemTypeId + '", "input_id": "' + id + '", "multiple": ' + multiple + ', "type": "' + linkItem.type + '"}'
            }, 
            React.createElement("span", {className: "fa fa-spin pull-right fa-cog hide", style: {olor: '#fff',margin: '10px 32px 0 0',lineHeight:'22px', fontSize: '22px',width: 'auto'}})
            ), 
            React.createElement("div", {className: "list-g-heading clearfix", style: {outline: 'none'}}, 
              React.createElement("div", {className: "list-group-item"}, 
                React.createElement("div", {className: "tool-box tool-box--left pull-left", style: {position:'relative',zIndex: '2',marginTop: '1px'}}, 
                  React.createElement("div", {className: "item move-item", title: "Flytta"}, React.createElement("span", {className: "fa fa-th"}))
                ), 
                React.createElement("a", {
                  href: "javascript:void(0);", 
                  title: 'Ändra', 
                  style: {position:'relative',zIndex: '2',color: '#555',extDecoration: 'none'}, 
                  className: "item-text"
                }, 
                  item.title || item.attributes.title || item[_getItemType(itemTypeId).display_field] || _getItemType(itemTypeId).labels.singular_name.replace(/^[a-z]/, function(m){ return m.toUpperCase() })
                ), 
                React.createElement("a", {
                  href: "javascript:void(0);", 
                  title: 'Ta bort', 
                  className: "pull-right remove-item", 
                  onMouseUp: _removeLinkItem, 
                  "data-link-id": linkItem.id, 
                  style: {fontSize: '20px', margin: '-4px 0 0 3px', color: '#a94442',position:'relative',zIndex: '2'}
                }, 
                  React.createElement("span", {className: "fa fa-remove"})
                ), 
                React.createElement("a", {
                  href: "javascript:void(0);", 
                  title: 'Ändra', 
                  className: "pull-right change-item", 
                  "ata-link-modal-config": '{"title": "Ändra", "edit": true, "link_id": "' + linkItem.id + '", "mode": "' + config.mode + '", "item_type_id": "' + itemTypeId + '", "input_id": "' + id + '", "multiple": ' + multiple + ', "type": "' + linkItem.type + '"}', 
                  style: {fontSize: '18px',margin: '-1px 0 0 0',position:'relative',zIndex: '2'}
                }, 
                  React.createElement("span", {className: "fa fa-edit"})
                )
              )
            ), 
            React.createElement("div", {className: "inline-iframe", style: {background: '#f1f1f1'}}, 
              React.createElement("div", {className: "inline-iframe-inner", style: {width: '100%'}}

              )
            )
          )
        );
        } else {
          var displayPropertyValue;
          if (item && item.attributes) {
            displayPropertyValue = item.attributes[_getItemType(itemTypeId).attributes.display_field];
            if (!displayPropertyValue) {
              displayPropertyValue = _getItemType(itemTypeId).attributes.labels.singular_name.replace(/^[a-z]/, function(m){ return m.toUpperCase() })
              // console.log(item);
              // console.log(_getItemType(itemTypeId));
            }
          }
          linkageItemsView.push(
            React.createElement("li", {className: "list-group-item", id: linkItem.id, key: uniqueKey + linkItem.id}, 
              
                config.multiple && (
                  React.createElement("div", {className: "tool-box tool-box--left pull-left", style: {marginTop: '1px'}}, 
                    React.createElement("div", {className: "item move-item", title: "Flytta"}, React.createElement("span", {className: "fa fa-th"}))
                  )
                ), 
              
              React.createElement("a", {
                href: "javascript:void(0);", 
                title: 'Ändra', 
                style: {olor: '#555'}, 
                "data-link-modal-config": '{"title": "Ändra", "edit": true, "edit": true, "link_id": "' + linkItem.id + '", "mode": "' + config.mode + '", "item_type_id": "' + itemTypeId + '", "input_id": "' + id + '", "multiple": ' + multiple + ', "type": "' + linkItem.type + '"}'
              }, 
                item.title || item.attributes.title || displayPropertyValue
              ), 
              React.createElement("a", {
                href: "javascript:void(0);", 
                title: 'Ta bort', 
                className: "pull-right", 
                onMouseUp: _removeLinkItem, 
                "data-link-id": linkItem.id, 
                style: {fontSize: '20px', margin: '-4px 0 0 3px', color: '#a94442'}
              }, 
                React.createElement("span", {className: "fa fa-remove"})
              ), 
              React.createElement("a", {
                href: "javascript:void(0);", 
                title: 'Ändra', 
                className: "pull-right change", 
                "data-link-modal-config": '{"title": "Ändra", "edit": true, "link_id": "' + linkItem.id + '", "mode": "' + config.mode + '", "item_type_id": "' + itemTypeId + '", "input_id": "' + id + '", "multiple": ' + multiple + ', "type": "' + linkItem.type + '"}', 
                style: {fontSize: '18px',margin: '-1px 0 0 0'}
              }, 
                React.createElement("span", {className: "fa fa-edit"})
              )
            )
          );
        }
        var sortClass = 'list-group relationship-sortable clearfix';
        if (config.mode === 'inline') {
          sortClass = 'list-group clearfix relationship-sortable relationship-accordion';
        }
        linkageItemsViewFull = (
          React.createElement("ul", {id: this.props.name+'-ul', className: sortClass, style: {clear: 'both'}}, 
            linkageItemsView
          )
        );
      }.bind(this));
    }
    var emptyText = 'Laddar...';
    var itemsInText = '';
    var itemInText = '';
    configArray.map(function(item, index) {
      if (item.type !== 'media') {
        var itemType = _getItemType(item.id);
        var pluralName = itemType.attributes.labels ? itemType.attributes.labels.plural_name : itemType.attributes.title;
        var singularName = itemType.attributes.labels ? itemType.attributes.labels.singular_name : itemType.attributes.title;
        if (configArray.length === 1) {
          itemsInText += pluralName;
          itemInText += singularName;
        } else {
          if (index === 0) {
            itemsInText += pluralName;
            itemInText += singularName;
          } else if (configArray.length-1 === index) {
            itemsInText += ' eller ' + pluralName;
            itemInText += ' eller ' + singularName;
          } else {
            itemsInText += ', ' + pluralName;
            itemInText += ', ' + singularName;
          }
        }
      } else {
        if (configArray.length === 1) {
          itemsInText += 'filer';
          itemInText += 'fil';
        } else {
          //if (index === 0) {
            itemsInText += 'filer';
            itemInText += 'fil';
          //}
        }
      }
    });
    if(itemsInText || itemInText) {
      if(config.multiple) {
        emptyText = 'Inga ' + itemsInText + ' än';
      } else {
        emptyText = 'Ingen ' + itemInText + ' än';
      }
    }
    if(!linkageItemsViewFull) {
      linkageItemsViewFull = (
        React.createElement("div", {className: "text-center", style: {padding: '9px 0 10px'}}, 
          React.createElement("h6", {className: "ext-info", style: {dsplay: 'none'}}, 
            emptyText
          ), 
          React.createElement("div", {style: {display: 'none'}}, 
            React.createElement("br", null), 
            "Välj ", itemInText, " genom att klicka på knappen till höger"
          )
        )
      );
    }
    return (
      React.createElement("div", {className: "panel panel-default panel--box clearfix", style: {marginBottom: 0}, ref: "container"}, 
        React.createElement("div", {className: "panel-heading clearfix", style: {position: 'relative'}}, 
          React.createElement("button", {
            id: id + '-update-link-view', 
            type: "button", 
            style: {display: 'none'}, 
            onClick: this.props.updateRelationshipViewCallback
          }, 
          "Uppdatera relationship vy"
          ), 
          React.createElement("button", {
            id: name + '-reorder-link-view', 
            type: "button", 
            style: {display: 'none'}, 
            onClick: this._reorderLinkView}), 
          linkageAddNewButton, 
          React.createElement("h3", {className: "panel-title"}, config.title, " ", config.required ? ' *' : '')
        ), 
        React.createElement("div", {className: "panel-body panel-body--content fill"}, 
          linkageItemsViewFull
        )
      )
    );
  }
});
 
module.exports = Relationship;

