var React = require('react');
var ReactPropTypes = React.PropTypes;

var Checkboxes = React.createClass({displayName: "Checkboxes",
  componentDidMount: function () {
    // this.props.showField();
  },
  componentWillMount: function () {
    this.value = this.props.value ?
                 this.props.value :
                 [];
  },
  handleClick: function (e) {
    var value = this.value;
    var inputValue = e.target.value;
    if (~value.indexOf(inputValue)) {
      value.splice(value.indexOf(inputValue), 1);
    } else {
      value.push(inputValue);
    }
    this.props.setValue(value);
    this.props.validate();
  },
  render: function() {
    return (
      React.createElement(Checkbox, {items: this.props.schema.items.enum, value: this.value, onClick: this.handleClick})
    );
  }
});

var Checkbox = function(props) {
  return (
    React.createElement("div", {style:  {marginTop: '-5px'} }, 
      
        props.items.map(function (item, index) {
          return (
            React.createElement("div", {key: index, className: "checkbox"}, 
              React.createElement("label", null, 
                React.createElement("input", {type: "checkbox", defaultChecked:  ~props.value.indexOf(item), onClick: props.onClick, value: item}), 
                item
              )
            )
          )            
        })
      
    )
  );
};

Checkboxes.propTypes = {
  id: React.PropTypes.string.isRequired,
  name: React.PropTypes.string.isRequired,
  value: React.PropTypes.array,
  schema: React.PropTypes.object.isRequired,
  setValue: React.PropTypes.func.isRequired,
  setInvalid: React.PropTypes.func.isRequired,
  validate: React.PropTypes.func.isRequired,
  showField: React.PropTypes.func.isRequired
};

module.exports = Checkboxes;