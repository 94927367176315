var request = require('superagent'); 

var modalWin;

var dialog = {
  init: function() {
    function showLinkModal(options, preload) {
      function getItem(url, query, callback) {
        query = query || {};
        var data = $.param(query);
        $.ajax({
          type: 'GET',
          url: url,
          data: data,
          success: function(response) {
            callback(response);
          }
        });
      }
      var self = this;
      //console.log(options.input_id);
      //window.top.currentIframeName = (new Date()).getTime();
      var isInline = options.mode === 'inline';
      var isInlineSub = false;
      if (options.item_type_id === 'null') {
        return;
      }
      var query = '';
      var itemId = '';
      var itemTypeStr = '?content_type=' + options.item_type_id + '&';
      var ifrHeight = '505px'
      if(typeof options.height !== undefined) {
        ifrHeight = options.height;
      }
      if(options.type === 'media') {
        itemTypeStr = '?';
      }
      if(options.add_new) {
        query = '&action=new';
      }
      if(options.edit) {
        query = '&action=edit';
        query += '&is_link_edit=true';
        itemId = '/' + options.link_id;
      }
      var source;
      var domain = '//localhost:11000';
      // console.log('Location check - remove this when checked: ', location.hostname);
      // if (typeof window.context === 'undefined' && location.hostname !== 'localhost') {
      if (location.hostname !== 'localhost') {
        domain = '//app-cms.twixly.io';
        // domain = '//app-cms.twixly.io';
      }
      // console.log('domain check - remove this when checked: ', domain);
      //if(options.mode === 'inline' || (window.top.window.currentLinkObject && window.top.window.currentLinkObject.inline)) {
      if(~this.frames.name.indexOf('inline-iframe')) {
        isInline = true;
        isInlineSub = true;
      }
      //console.log('inline: ' + options.mode)
      if(options.mode === 'inline' && options.add_button) {
        window.top.window.currentLinkObject = null;
        window.top.window.currentLinkObject_1 = null;
        isInline = false;
      } else if (options.mode === 'inline') {
        window.top.window.currentLinkObject = null;
        window.top.window.currentLinkObject_1 = null;
        isInline = true;
      // } else if (window.top.window.currentLinkObject && window.top.window.currentLinkObject.inline) {
      } else if (windowCurrentLinkObject && typeof windowCurrentLinkObjectInline !== 'undefined') {
        isInline = true;
        isInlineSub = true;
      }
      if(isInline && !isInlineSub) {
        window.top.window.currentLinkObject = null;
        //console.log('Inline hehehehe');
        var currentIframeName = 'inline-iframe-' + (new Date()).getTime();
        var frameContainer = $('.rel-' + options.link_id + ' .inline-iframe-inner');
        source = '/edit/buckets/' + window.bucketId + '/' + options.type + itemId + itemTypeStr + 'mode=embed&is_inline=true&is_link=true'+query;
        if(frameContainer.find('.relationship-object').length === 0) {
          //var inlineIframe = $('<iframe class="iframe iframe-inline" allowtransparency="true" scrolling="auto" frameBorder="0" name="'+currentIframeName+'" id="'+currentIframeName+'" src="'+source+'" style="width: 100%;height:100%;in-height: '+ifrHeight+'"></iframe>');
          var inlineIframe = $('<div class="relationship-object" style="padding: 10px;"></div>');
          var inlineIframeAbout1 = $('<div style="width: 100%;in-height: '+ifrHeight+';position:relative;"><div class="insert-loader"></div><div class="insert-iframe" style="position:relative;z-index:2"></div><div class="modal-footer" style="z-index:3;padding: 5px;"><button type="button" class="btn btn-sm btn-default js-inline-dialog-cancel">Stäng</button><button type="button" class="btn btn-sm btn-primary js-inline-dialog-save">Spara</button></div></div>');
          var inlineIframeAbout2 = $('<div class="loader" style="display:flex;background-color: rgba(0,0,0,0);z-index:1;"><div class="content">Laddar ' + options.item_name + '...</div></div>');
          frameContainer.append(inlineIframeAbout1);
          frameContainer = frameContainer.find('.insert-iframe');
          frameContainer.append(inlineIframe);
          var container;
          //$('#' + currentIframeName).load(function () {
            getItem(source, {}, function(response) {
              var objHtml = $(response).find('.update-panel').html();
              container = frameContainer.find('.relationship-object');
              //alert(objHtml);
              container.html(objHtml);
              container.find('form').css('visibility', 'visible');
              //alert(options.link_id)
              systemRenderForm('.rel-' + options.link_id + ' .inline-iframe-inner .relationship-object');
              //container.find('input[value="put"]').remove();
              container.parent().parent().parent().parent().parent().find('.change-item').css('visibility', 'visible');
              container.parent().parent().parent().parent().parent().find('.inline-iframe').css('display', 'block');
              //container.css('height', container.contents().height());
              container.parent().parent().parent().parent().parent().find('.inline-iframe').css('display', 'none');
              if(!preload) {
                container.parent().parent().parent().parent().parent().find('.list-g-heading').click();
                container.parent().parent().parent().parent().parent().find('.list-g-heading--over').remove();
              }
              container.find('input, textarea').each(function(index) {
                $(this).keydown(function() {
                  if (event.keyCode == 13) {
                    container.parent().next().find('.js-inline-dialog-save').focus();
                    container.parent().next().find('.js-inline-dialog-save').click();
                    return false;
                  }
                });  
              });
              container.parent().next().find('.js-inline-dialog-cancel').unbind();
              container.parent().next().find('.js-inline-dialog-cancel').click(function() {
                var el = $(this).parent().parent().parent().parent().parent().find('.list-g-heading');
                el.click();
              });
              container.parent().next().find('.js-inline-dialog-save').unbind();
              container.parent().next().find('.js-inline-dialog-save').click(function() {
                container.find('#pre-loader .content').text('Sparar ' + options.item_name + '...');
                container.find('#pre-loader').css('visibility', 'visible');
                $(this).attr('disabled', true);
                var data = container.find('form').serialize();
                $.ajax({
                  type: 'POST',
                  url: container.find('form').attr('action'),
                  data: data,
                  cache: false
                })
                  .done(function(response) {
                    container.find('.alert-success').remove();
                    var str = '<div class="alert alert-success alert-dismissable fade in" role="alert">';
                    str += $(response).find('.alert-success').html();
                    str += '</div>';
                    container.prepend(str);
                    container.find('#pre-loader').css('visibility', 'hidden');
                    $(this).attr('disabled', false);
                    container.parent().next().find('.js-inline-dialog-cancel').click();
                  });
              });
            }.bind(this));

            // $(this).parent().parent().parent().parent().parent().find('.change-item').css('visibility', 'visible');
            // $(this).parent().parent().parent().parent().parent().find('.inline-iframe').css('display', 'block');
            // $(this).css('height', $(this).contents().height());
            // $(this).parent().parent().parent().parent().parent().find('.inline-iframe').css('display', 'none');
            // if(!preload) {
            //   $(this).parent().parent().parent().parent().parent().find('.list-g-heading').click();
            //   $(this).parent().parent().parent().parent().parent().find('.list-g-heading--over').remove();
            // }

            // $('#' + currentIframeName)[0].contentWindow.onbeforeunload = function () {
            //   window.top.window.currentLinkObject = null;
            //   window.top.window.currentLinkObject_1 = null;
            //   console.log('d')
            // };
          //});
          // $('.js-inline-dialog-cancel').click(function() {
          //   var el = $(this).parent().parent().parent().parent().parent().find('.list-g-heading');
          //   el.click();
          //   $(this).unbind();
          // });
          // $('.js-inline-dialog-save').click(function() {
          //   var win = parent.frames['page'][currentIframeName];
          //   win.$('form').submit();
          //   win.$('#pre-loader .content').text(win.preLoaderText);
          //   win.$('#pre-loader').css('display', 'flex');
          // });
        }
        alert('1')
        if(!window.top.window.currentLinkObject) {
          //console.log('lo-0');
          window.top.window.currentLinkObject = {
            input_id: options.input_id,
            type: options.type,
            item_type: options.item_type_id,
            multiple: options.multiple,
            iframe: window.top.parent.frames['page'] || window.top,
            //modalIframe: window.top.parent.frames['page'][currentIframeName],
            modalIframeId: window.top.$('#' + currentIframeName)[0],
            //loaderFrame: window.top.parent.frames['page'][currentIframeName],
            inline: true
          };
        } else {
          //console.log('lo-1');
          window.top.window.currentLinkObject_1 = {
            input_id: options.input_id,
            type: options.type,
            item_type: options.item_type_id,
            multiple: options.multiple,
            iframe: window.top['modal-iframe'],
            modalIframe: parent.frames['modal-iframe'],
            modalIframeId: $('#modal-iframe')[0]
          };
        }
        //alert(window.top.window.currentLinkObject.input_id)
      } else {
        //if(isInlineSub || (window.top.window.currentLinkObject && window.top.window.currentLinkObject.inlineSub)) {
        if(isInlineSub) {
          window.ject = {
            input_id: null,
            type: null,
            item_type: null,
            multiple: null,
            iframe: null
          };
          if(window.top.window.currentLinkObject) {
            //console.log(options.input_id);
            ject = window.top.window.currentLinkObject;
          }
          window.top.window.currentLinkObject = null;
          window.top.window.currentLinkObject_1 = null;
          //console.log(this.frames.name);
        }
        var modal;
        modalWin = 'window';
        var modalOnHidden;
        var modalIframeHeight;
        if(!windowCurrentLinkObject) {
          // ToDo: have to be able to load from window.top
          if (window.name !== 'app' && window.name !== 'app_include' && window.name !== 'code') {
            modalWin = 'window.top';
          }
          // modal = $('#systemModal');
          modalIframeHeight = '700px';
          modalOnHidden = 'object';
          // modal.off('hidden.bs.modal');
          // modal.on('hidden.bs.modal', function() {
          //   if(window.top.window.currentLinkObject && window.top.window.currentLinkObject.inlineSub) {
          //     window.top.window.currentLinkObject = {
          //       input_id: window.top.window.currentLinkObject.input_id_parent,
          //       type: window.top.window.currentLinkObject.type_parent,
          //       item_type: window.top.window.currentLinkObject.item_type_parent,
          //       multiple: window.top.window.currentLinkObject.multiple_parent,
          //       iframe: window.top.window.currentLinkObject.iframe_parent,
          //       input_id_parent: window.ject.input_id,
          //       type_parent: window.ject.type,
          //       item_type_parent: window.ject.item_type,
          //       multiple_parent: window.ject.multiple,
          //       iframe_parent: window.ject.iframe,
          //       // Don't need them your inline : )
          //       // modalIframe: window.top,
          //       // modalIframeId: window.top.$('#modal-iframe')[0],
          //       loaderFrame: window.top.parent.frames['page'],
          //       inline: true,
          //       inlineSub: true
          //     };
          //     // console.log('input_id: ' + window.top.window.currentLinkObject.input_id);
          //     // console.log('type: ' + window.top.window.currentLinkObject.type);
          //     // console.log('item_type: ' + window.top.window.currentLinkObject.item_type);
          //     // console.log('multiple: ' + window.top.window.currentLinkObject.multiple);
          //     //console.log('iframe: ' + window.top.window.currentLinkObject.iframe.name);
          //     //console.log('loaderFrame: ' + window.top.window.currentLinkObject.loaderFrame.name);
          //   } else {
          //     // window.top.window.currentLinkObject = null;
          //     window.top.window.postMessage({'action': 'clear_current_link_object'}, '*');
          //   }
          //   //console.log('removed 0');
          // })
        } else {
          // modal = $('#systemModal');
          modalIframeHeight = '500px';
          // modal.on('hidden.bs.modal', function () {
          //   window.top.window.currentLinkObject_1 = null;
          //   //console.log('removed 1');
          // })
        }
        if(!windowCurrentLinkObject) {
          //console.log('lo-0');
          if(isInlineSub) {
            //alert(options.input_id);
            window.top.window.currentLinkObject = {
              // input_id: options.input_id,
              // type: options.type,
              // item_type: options.item_type_id,
              // multiple: options.multiple,
              // iframe: window.top.parent.frames['page'][this.frames.name] || window.top[this.frames.name],
              // modalIframe: window.top,
              // modalIframeId: window.top.$('#modal-iframe')[0],
              // loaderFrame: window.top['modal-iframe'],
              // inlineSub: true,
              // inlineSubId: this.frames.name,
              // input_id_parent: window.ject.input_id,
              // type_parent: window.ject.type,
              // item_type_parent: window.ject.item_type,
              // multiple_parent: window.ject.multiple,
              // iframe_parent: window.ject.iframe
              input_id: options.input_id,
              type: options.type,
              item_type: options.item_type_id,
              multiple: options.multiple,
              iframe: window.top.parent.frames['page'] || window.top,
              modalIframe: window.top,
              modalIframeId: window.top.$('#modal-iframe')[0],
              loaderFrame: window.top['modal-iframe'],
              inlineSub: true,
              inlineSubId: 'page',
              input_id_parent: window.ject.input_id,
              type_parent: window.ject.type,
              item_type_parent: window.ject.item_type,
              multiple_parent: window.ject.multiple,
              iframe_parent: window.ject.iframe
            };
          } else {
            // window.top.window.currentLinkObject = {
            // console.log('GAGA: ', parent.frames.document.getElementById('app_include').src);
            // console.log('GAGA_WIN: ', parent.frames.name);
            var object = {
              input_id: options.input_id,
              type: options.type,
              item_type: options.item_type_id,
              multiple: options.multiple,
              // iframe: 'window.top',
              // modalIframe: 'window.top',
              iframe: window.name === 'app' ?
                      'window.top.parent.frames["app"]' :
                      window.name === 'app_include' ?
                        ~parent.frames.document.getElementById('app_include').src.indexOf('&window_name=app') || ~parent.frames.document.getElementById('app_include').src.indexOf('&window_name=code') ?
                          'window.top.parent.frames["' + parent.frames.name + '"]["app_include"]' :
                          'window.top.parent.frames["app_include"]' :
                        window.name === 'code' ?
                          'window.top.parent.frames["code"]' :
                          'window.top',
              modalIframe: window.name === 'app' ?
                           'window.top.parent.frames["app"]' :
                           window.name === 'app_include' ?
                             ~parent.frames.document.getElementById('app_include').src.indexOf('&window_name=app') || ~parent.frames.document.getElementById('app_include').src.indexOf('&window_name=code') ?
                              'window.top.parent.frames["' + parent.frames.name + '"]["app_include"]' :
                              'window.top.parent.frames["app_include"]' :
                             window.name === 'code' ?
                               'window.top.parent.frames["code"]' :
                               'window.top',
              // modalIframeId: 'window.top.$("#modal-iframe")[0]',
              // loaderFrame: 'window.top["modal-iframe"]'
            };
            window.top.window.postMessage({'action': 'set_current_link_object', 'value': object}, '*');
          }
        } else {
          // console.log('lo-1');
          // window.top.window.currentLinkObject_1 = { 
          // console.log('windowCurrentLinkObject: ', windowCurrentLinkObject);
          var object = {
            input_id: options.input_id,
            type: options.type,
            item_type: options.item_type_id,
            multiple: options.multiple,
            iframe: windowCurrentLinkObject.iframe + '["modal-iframe"]',
            modalIframe: windowCurrentLinkObject.modalIframe + '["modal-iframe"]',
            loaderFrame: 'window.top["modal-iframe"]'
            // input_id: options.input_id,
            // type: options.type,
            // item_type: options.item_type_id,
            // multiple: options.multiple,
            // iframe: window.top['modal-iframe'],
            // modalIframe: parent.frames['modal-iframe'],
            // modalIframeId: $('#modal-iframe')[0],
            // loaderFrame: parent.frames['modal-iframe']['modal-iframe']
          };
          window.top.window.postMessage({'action': 'set_current_link_object_1', 'value': object}, '*');
        }
        // modal.removeClass('fade');
        // modal.find('.modal-title').text(options.title);
        // source = domain + '/edit/buckets/' + window.bucketId + '/' + options.type + itemId + itemTypeStr + 'mode=embed&is_dialog=true&is_link=true'+query+'&can_select_multiple='+options.multiple;
        // domain = '//localhost:11000';
        query = options.type === 'items' && options.item_type_id ?
                '?item_type=' + options.item_type_id + '&can_select_multiple='+options.multiple + '&mode=embed' :
                '?can_select_multiple=' + options.multiple + '&mode=embed';
        var singleQuery = options.item_id ? '/' + options.item_id : ''; 
        source = domain + '/' + options.type + singleQuery + query;
        // modal.find('.modal-body #modal-iframe').prop('src', source);
        // modal.find('.modal-body #modal-iframe').css('min-height', modalIframeHeight);
        // modal.modal('show');
        // console.log(modalWin);
        eval(modalWin).postMessage({'action': 'show_dialog', 'source': source, 'modalIframeHeight': modalIframeHeight, 'title': options.title, 'modalOnHidden': modalOnHidden}, '*');
      }
    }
    window.showLinkModal = showLinkModal;
    $('[data-link-modal-config]').unbind();
    $('[data-link-modal-config]').each(function(index) { 
      $(this).click(function() {
        if($(this).prop('class') === 'list-g-heading--over') {
          $(this).find('span').removeClass('hide');
          $(this).next().find('.change-item').css('visibility', 'hidden');
        }
        //alert($(this).parent().find('.inline-iframe').length);
        //$(this).parent().find('.inline-iframe').css('border','solid 10px red');
        //$(this).parent().find('.inline-iframe').css('display','block');
        // console.log($(this).data('link-modal-config'));
        showLinkModal($(this).data('link-modal-config'), false);
      });
      // $(this).click(function() {
      //   if($(this).prop('class') === 'list-g-heading--over') {
      //     $(this).find('span').removeClass('hide');
      //     $(this).next().find('.change-item').css('visibility', 'hidden');
      //   }
      //   var item = $(this).next();
      //   item.click();
      //   $(this).remove();
      // });
    });

    $('.js-dialog-save').unbind();
    $('.js-dialog-save').click(function() {
      if (!modalWin) {
        modalWin = 'window';
      };
      eval(modalWin).frames['modal-iframe'].postMessage({'action': 'save_dialog'}, '*');
      // window.frames['modal-iframe'].$('form').submit();
      // window.frames['modal-iframe'].$('#pre-loader .content').text(window.frames['modal-iframe'].preLoaderText);
      // window.frames['modal-iframe'].$('#pre-loader').css('visibility', 'visible');
      // $('#pre-loader .content').text(window.frames['modal-iframe'].preLoaderText);
      // $('#pre-loader').css('display', 'flex');
    });

    $('.js-dialog-delete').click(function() {
      var modal = $('#systemModal');
      var deleteUrl = window.frames['modal-iframe'].$('.js-form-button-delete').prop('href');
      modal.find('.modal-body #modal-iframe').prop('src', deleteUrl);
      // $('#systemModal').modal('hide');
    });

    function showDefaultDialog(options) {
      var modal = $('#systemModal').removeData('bs.modal');
      // var modal = $('#systemModal');
      // if (options.backdrop) {
      //   modal.on('click.dismiss.bs.modal', function(e) {
      //     var el = $(e.target);
      //     alert(el.prop('class'));
      //   });
      // } else {
      //   modal.find('.modal-header .close').unbind();
      //   modal.on('click.dismiss.bs.modal');
      // }
      var modalOptions = {};
      if (options.backdrop) {
        modalOptions['backdrop'] = 'static';
      }
      modal.modal(modalOptions);
      // modal.modal(modalOptions);
      // modal.off('mouseup.dismiss.bs.modal');
      // modal.off('mousedown.dismiss.bs.modal');
      // modal.modal('handleUpdate')
      modal.find('.loader').css('visibility', 'visible')
      .addClass('loader--light-inverted');
      var url = options.url;
      var queryStr = ~url.indexOf('?') ? '&mode=embed&is_dialog=true' : '?mode=embed&is_dialog=true';
      queryStr = options.scroll !== false ? queryStr : queryStr + '&scroll=false';
      options.fade ? modal.addClass('fade') : modal.removeClass('fade');
      options.size ? modal.find('.modal-dialog').removeClass('modal-lg').removeClass('modal-sm').addClass(options.size) : modal.find('.modal-dialog').addClass('modal-lg');
      options.hide_footer ? modal.find('.modal-footer').css('display', 'none') : modal.find('.modal-footer').css('display', 'block');
      options.show_delete_button ? modal.find('.js-dialog-delete').removeClass('hidden') : modal.find('.js-dialog-delete').removeClass('visible');
      options.loader_text ? modal.find('.loader .content').text('Laddar...') : modal.find('.loader .content').text(options.loader_text);
      options.height ? modal.find('.modal-body #modal-iframe').css('min-height', options.height) : modal.find('.modal-body #modal-iframe').css('min-height', '700px');
      options.title ? modal.find('.modal-title').text(options.title) : modal.find('.modal-title').text('Titel');
      modal.find('.modal-body #modal-iframe').prop('src', url + queryStr);
      $('#systemModal').modal('show');
      modal.find('.modal-body #modal-iframe').load(function () {
        modal.find('.loader').css('visibility', 'hidden');
      })
      modal.off('hidden.bs.modal');
      modal.on('hidden.bs.modal', function() {
        modal.find('.modal-body #modal-iframe').prop('src', 'about:blank');
      });
    }
    $('[data-dialog]').each(function(index) { 
      if($(this).data('dialog').load) {
        showDefaultDialog($(this).data('dialog'));
      }
      $(this).click(function() {
        showDefaultDialog($(this).data('dialog'));
        return false;
      });
    });

  }
}

module.exports = dialog;