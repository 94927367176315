var React = require('react');
var ReactPropTypes = React.PropTypes;
var request = require('superagent');
var async = require('async');
var dialog = require('../../../../lib/dialog.js');
var navMenu = require('../../../../lib/navMenu.js');
var PagesBase = require('./PagesBase.js');
var PageItems = require('./PageItems');
var AddNewPageButton = require('./AddNewPageButton');

var Pages = React.createClass({displayName: "Pages",
  mixins: [PagesBase],
  propTypes: {
    id: React.PropTypes.string.isRequired,
    name: React.PropTypes.string.isRequired,
    value: React.PropTypes.array,
    config: React.PropTypes.object.isRequired,
    parent: React.PropTypes.object.isRequired, 
    items: React.PropTypes.array,
    itemTypes: React.PropTypes.array,
    ajaxIsLoaded: React.PropTypes.bool
  },
  componentDidMount: function() {
    this._componentDidMount();
  },
  componentDidUpdate: function() {
    navMenu.init();
    dialog.init();
  },
  render: function() {
    if(!this.props.parent.state.ajaxIsLoaded) {return false;}
    //console.log('Pages render');
    var id = this.props.id;
    var name = this.props.name;
    var value = this.props.value;
    var config = this.props.config;
    var parent = this.props.parent;
    var itemTypes = parent.state.itemTypes;
    itemTypes = itemTypes ?
                itemTypes :
                [];
    return (
      React.createElement("div", {className: "panel panel-default panel--box clearfix", style: {border: 'none', background: '#eaeaea', orderRight: 'solid 1px #d6d6d6'}}, 
        React.createElement("div", {className: "panel-heading clearfix", style: {position: 'relative',padding: '6px 5px', backgroundColor: '#eaeaea', border: 'none', borderBottom: 'solid 1px #d6d6d6'}}, 
          React.createElement(AddNewPageButton, {
            itemTypes: itemTypes, 
            navMenu: value, 
            addPageItem: this._addPageItem})
          /*<h3 className="panel-title">{config.title}</h3>*/
        ), 
        React.createElement("div", {className: "panel-body panel-body--content fill"}, 
          React.createElement(PageItems, {navMenu: value, reloadPageItems: this._reloadPageItems, addPageItem: this._addPageItem, itemTypes: itemTypes})
        )
      )
    );
  }
});
 
module.exports = Pages;

