var React = require('react');
var ReactPropTypes = React.PropTypes;

var AddNewPageButton = React.createClass({displayName: "AddNewPageButton",
  propTypes: {
    itemTypes: React.PropTypes.array.isRequired,
    addPageItem: React.PropTypes.func.isRequired
  },
  getInitialState: function() {
    return {
      showAddMenu: false
    };
  },
  componentDidMount: function() {
    if (!this.props.navMenu.length) {
      this._showMenu();
    }
  },
  _toggleMenu: function(event) {
    if (this.state.showAddMenu) {
      this._hideMenu();
    } else {
      this._showMenu();
    }
  },
  _showMenu: function(event) {
    this.setState({
      showAddMenu: true
    });
  },
  _hideMenu: function(event) {
    this.setState({
      showAddMenu: false
    });
  },
  _addPageItem: function(event) {
    this._hideMenu();
    var itemTypeId = $(event.target).data('item-type-id');
    this.props.addPageItem(0, false, itemTypeId);
    //href={'?content_type=' + itemTypeId + '&action=new&mode=embed&add_nav_menu_item=true'}
    //target='page'
  },
  _getItemTypes: function(event) {
    var itemTypes = [];
    var self = this;
    if (this.props.itemTypes) {
      this.props.itemTypes.map(function(itemType, index) {
        var itemTypeId = itemType.__meta.id;
        var type = 'items';
        if(itemType.show_in_page_menu) { 
          itemTypes.push(
            React.createElement("a", {
              onClick: self._addPageItem, 
              "data-item-type-id": itemTypeId, 
              className: "list-group-item", key: index, 
              style: {cursor: 'pointer'}
            }, 
            itemType.labels.singular_name.replace(/^[a-z]/, function(m){ return m.toUpperCase() })
            )
          );
        }
      });
      if(itemTypes.length) {
        itemTypes = (
          React.createElement("div", {className: "list-group", style: {margin: '0 -13px 0 -13px'}}, 
            itemTypes
          )  
        );
      }
      return itemTypes;
    }
    // if (this.props.itemTypes) {
    //   this.props.itemTypes.map(function(itemType, index) {
    //     var itemTypeId = itemType.__meta.id;
    //     var type = 'items';
    //     if(itemType.show_in_page_menu) {
    //       linkageItems.push(
    //         <li key={index} role="presentation">
    //           <a 
    //             href="javascript:void(0);"
    //             data-link-modal-config={'{"title": "Lägg till", "add_new": true, "item_type_id": "' + itemTypeId + '", "input_id": "' + name + '", "multiple": ' + false + ', "type": "' + type + '"}'}
    //           >
    //             {itemType.labels.singular_name.replace(/^[a-z]/, function(m){ return m.toUpperCase() })}
    //           </a>
    //         </li>
    //       );
    //     }
    //   });
    // }    
  },
  render: function() {
    var self = this;
    var name = this.props.name;
    return (
      React.createElement("div", {className: "ropdown ull-right", style: {marginBottom: '0', osition: 'absolute', op: '2px', ight: '2px'}}, 
        React.createElement("button", {
          className: "btn btn-success", 
          type: "button", 
          "data-toggle": "dropdown", 
          title: 'Skapa en ny sida', 
          onClick: this._toggleMenu, 
          style: {isibility:'hidden'}
        }, 
          React.createElement("span", {className: 'fa fa-plus'}), React.createElement("span", {lassName: "caret"})
        ), 
        this.state.showAddMenu && 
          React.createElement("div", {style: {zIndex: 100,position: 'absolute',top: '100%',left: '0',marginLeft:'0px',width: '275px', background: '#f1f1f1', padding: '0px', border: 'solid 1px #d6d6d6', borderLeft: 'none', borderBottom: 'none', orderRight: 'none'}}, 
            React.createElement("ul", {id: "myTabs", className: "nav nav-pills nav-stacked", role: "tablist", style: {float: 'right', width: '40%', borderLeft:'solid 1px #d6d6d6',height:'220px',display: 'none'}}, 
              React.createElement("li", {role: "presentation", className: "active"}, React.createElement("a", {href: "#home", "aria-controls": "home", role: "tab", "data-toggle": "tab", style: {borderRadius: 0}}, "Sida")), 
              React.createElement("li", {role: "presentation"}, React.createElement("a", {href: "#profile", "aria-controls": "profile", role: "tab", "data-toggle": "tab", style: {borderRadius: 0}}, "Länk"))
            ), 
            React.createElement("div", {className: "tab-content", style: {padding: '10px 10px 0 10px', overflow: 'hidden'}}, 
              React.createElement("div", {role: "tabpanel", className: "tab-pane active", id: "home", style: {ackground: '#fff'}}, 
                React.createElement("div", {style: {fontSize: '14px',fontWeight:'bold',marginBottom: '10px'}}, "Välj en sidtyp"), 
                this._getItemTypes()
              ), 
              React.createElement("div", {role: "tabpanel", className: "tab-pane", id: "profile", style: {ackground: '#fff'}}, 
                "Ange en länk"
              )
            )
          )
        
      )
    );
  }
});

module.exports = AddNewPageButton;