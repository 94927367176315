var React = require('react');
var ReactPropTypes = React.PropTypes;
var ReactDOM = require('react-dom');
var request = require('superagent');
var async = require('async');
var utils = require('../../../../lib/utils.js');
var markdownEditor = require('../../../../lib/markdownEditor.js');
var controls = require('../../../../lib/controls.js');
var formView = require('../../../../lib/formView.js');
var PropertyItem = require('../../PropertyItem.js');

var navMenuItemsLoaded = false;

var PageItem = React.createClass({displayName: "PageItem",
  propTypes: {
    navMenuItem: React.PropTypes.object.isRequired,
    reloadPageItems: React.PropTypes.func.isRequired,
    addPageItem: React.PropTypes.func.isRequired,
    getItemTypes: React.PropTypes.func.isRequired,
    prevElId: React.PropTypes.string.isRequired
  },
  getInitialState: function() {
    return {
      navMenuItem: this.props.navMenuItem,
      isCreatingNewNavMenuItem: false,
      isDeletingNavMenuItem: false,
      showEditToolbar: false,
      showAddMenu: false
    };
  },
  componentDidMount: function() {
    if(this.refs.inputField) {
      this.refs.inputField.focus();
    }
    $('#myTabs a').click(function (e) {
      e.preventDefault()
      $(this).tab('show');
    })
    if (!localStorage.getItem('nav-menu-item-current-page-url')) {
      navMenuItemsLoaded = true;
    }
    var navMenuItem = this.props.navMenuItem;
    var pageUrl;
    //if(navMenuItem.page.data.id === -1 || navMenuItem.is_active && (navMenuItem.page.data.id !== localStorage.getItem('nav-menu-item-current-page-id'))) {
      if(navMenuItem.is_active) {
        if(navMenuItem.__meta.id === -1 || navMenuItem.__meta.id === '-1') {
          //alert(navMenuItem.__meta.position);
          pageUrl = '?content_type=' + navMenuItem.page.data.meta.item_type.data.id + '&action=new&mode=embed&add_nav_menu_item=true';
        } else {
          pageUrl = navMenuItem.page.data.id + '?content_type=' + navMenuItem.page.data.meta.item_type.data.id + '&action=edit&mode=embed&nav_menu_item=true';
        }
        if (navMenuItem.attributes) {
          localStorage.setItem('nav-menu-item-current-page-url', navMenuItem.attributes.path);
        }
        localStorage.setItem('nav-menu-item-current-page-id', navMenuItem.page.data.id);
        this._updatePage(pageUrl, navMenuItem);
      }
    //}
    $('#pre-loader').css('visibility', 'hidden');
  },
  componentDidUpdate: function() {
  },
  _disableAllObjectInputs: function() {
    //var PropertyObjectItems = Array.prototype.slice.call(document.querySelectorAll('[data-key="' + this.props.name + '"] [class="form-control"], [data-key="' + this.props.name + '"] [class="form-control--unstyled"]'));
    var PropertyObjectItems = Array.prototype.slice.call(document.querySelectorAll('.js-property-object-items [class="form-control"], .js-property-object-items [class="form-control--unstyled"]'));
    for(var i in PropertyObjectItems) {
      var item = PropertyObjectItems[i];
      item.setAttribute('disabled', 'disabled');
    }
  },
  _enableAllObjectInputs: function() {
    //var PropertyObjectItems = Array.prototype.slice.call(document.querySelectorAll('[data-key="' + this.props.name + '"] [class="form-control"], [data-key="' + this.props.name + '"] [class="form-control--unstyled"]'));
    var PropertyObjectItems = Array.prototype.slice.call(document.querySelectorAll('.js-property-object-items [class="form-control"], .js-property-object-items [class="form-control--unstyled"]'));
    for(var i in PropertyObjectItems) {
      var item = PropertyObjectItems[i];
      item.removeAttribute('disabled');
    }
  },
  _setEvents: function() {
    var self = this;
    $('#topper').text(topperText);
    if(this.state.navMenuItem.attributes) {
      $('#go-to-website-page').attr('href', window.context.render_domain + this.state.navMenuItem.attributes.path);
      $('#go-to-website-page').css('display', 'block');
    }
    markdownEditor.init();
    controls.init();
    formView.init();
    var submitButton = $('#form-cms');
    //submitButton.unbind();
    win = window;
    submitButton.click(function() {
      self._disableAllObjectInputs();
      win.$('#item-saved-successfully').removeClass('show');
      win.$('#item-saved-successfully').removeClass('hide');
      // window.top.window.currentLinkObject = null;
      // window.top.window.currentLinkObject_1 = null;
      $('.relationship-object').find('input').prop('disabled', true);
      controls.validateForm();
      if ($('#form-cms').attr('disabled')) return;
      //$('#pre-loader .content').text(preLoaderText);
      //$('#pre-loader').css('display', 'flex');
      var data = win.$('.update-panel').find('form').serialize();
      data = data + '&ajax=true';
      $.ajax({
        type: 'POST',
        url: win.$('.update-panel').find('form').attr('action'),
        data: data,
        cache: false,
        success: function(response) {
          win.$('.update-panel').find('.alert-danger').remove();
          win.$('.update-panel').find('.alert-success').remove();
          var str;
          var isError = false;
          if(response.status !== 200) {
            isError = true;
            str = '<div class="alert alert-danger alert-dismissable fade in" role="alert">';
            str += '<button class="close" type="button" data-dismiss="alert" aria-label="close">';
            str += '<span aria-label-hidden="true">×</span>';
            str += '</button>';
            str += '<h4 style="font-size: 20px;margin-bottom: 10px;">Felmeddelande!</h4>';
            for (var i=0; i < response.error.validation.validations.length; i++) {
              var validation = response.error.validation.validations[i];
              str += '<div style="margin-bottom: 5px;">';
              if (validation.property_title) {
                str += '<strong>';
                str += validation.property_title;
                str += '</strong>';  
              }
              str += '<br />';
              str += validation.messages[0];
              str += '</div>';
            }
            //str += $(response).find('.alert-danger').html();
            str += '</div>';
          } else if (!response.created_page_id) {
            // str = '<div class="alert alert-success alert-dismissable fade in" role="alert">';
            // str += '<button class="close" type="button" data-dismiss="alert" aria-label="close">';  
            // str += '<span aria-label-hidden="true">×</span>';  
            // str += '</button>';
            // str += '<h4>Lyckad uppdatering!</h4>';  
            // str += 'Formuläret sparades utan problem';
            // // str += $(response).find('.alert-success').html();
            // str += '</div>';
            win.$('#item-saved-successfully').addClass('show');
            setTimeout(function(){win.$('#item-saved-successfully').addClass('hide');}, 1500);
          } else {

          }
          // if($(response).find('.alert-danger').length) {
          //   isError = true;
          //   str = '<div class="alert alert-danger alert-dismissable fade in" role="alert">';
          //   str += $(response).find('.alert-danger').html();
          //   str += '</div>';
          // } else {
          //   str = '<div class="alert alert-success alert-dismissable fade in" role="alert">';
          //   str += $(response).find('.alert-success').html();
          //   str += '</div>';  
          // }
          if (str) {
            win.$('.update-panel').prepend(str);
          }
          $('.relationship-object').find('input').prop('disabled', false);
          if($(response).find('#validation-errors').length) {
            controls.validateForm();
            $('#pre-loader').css('visibility', 'hidden');
          }
          // var currentNavMenuItemId = $(response).find('.update-panel #currentNavMenuItemId').text();
          var currentNavMenuItemId = response.created_page_id || response.created_id;
          if (currentNavMenuItemId) {
            localStorage.setItem('nav-menu-item-active-id', currentNavMenuItemId);
            //self._setActive(null, currentNavMenuItemId);
            if (isError) {
              self.props.reloadPageItems(null, true, false);
            } else {
              self.props.reloadPageItems(null, true, true);
            }
          }
          if (!response.created_page_id) {
            self._enableAllObjectInputs();
            $('#pre-loader').css('visibility', 'hidden');
          }
          submitButton.button('reset');
          parent.frames.postMessage({'action': 'reload_template'}, '*');
          //alert(window.currentNavMenuItemId)
          //win.$('.update-panel').parent().next().find('.js-inline-dialog-cancel').click();
        }
      });
      return false;
    });
    $('.update-panel').find('input').each(function(index) {
      $(this).keydown(function() {
        if (event.keyCode == 13) {
          submitButton.click();
          return false;
        }
      });  
    });
  },
  _getPageItem: function(url, query, callback) {
    query = query || {};
    var data = $.param(query);
    $.ajax({
      type: 'GET',
      url: url,
      data: data,
      //cache: false
    })
      .done(function(response) {
        callback(response);
      });
  },
  _updatePage: function(url, item) {
    if(!this.props.navMenuItem.reloadPage) {
      return;
    }
    if (navMenuItemsLoaded && item.attributes) {
      parent.frames.postMessage({'action': 'load_template_url', 'url': item.attributes.path}, '*');
    }
    navMenuItemsLoaded = true;
    $('.update-panel').html($('#pre-loader').clone());
    $('.update-panel').find('#pre-loader')
    .removeClass().addClass('loader')  
    .addClass('loader--light-inverted')
    .addClass('loader--absolute')
    .css('margin-top', '-80px')
    //.css('align-items', 'baseline')
    //.css('justify-content', 'flex-start')
    $('.update-panel').find('#pre-loader .content')
    .css('background', 'transparent')
    .css('box-shadow', 'none')
    //.css('margin', '10px 0 0 10px')
    //.css('vertical-align', 'left')
    $('.update-panel').find('#pre-loader .content').text('Laddar sidan...');
    setTimeout(function(){if($('#react-form').length === 0){$('.update-panel').find('#pre-loader').css('visibility', 'visible');}}.bind(this), 250);
    //return;
    this._getPageItem(url, {ajax: true}, function(response) {
      var objHtml = $(response).find('.update-panel').html();
      win = window;
      win.$('.update-panel').html(objHtml);
      window.context.item_id = this.state.navMenuItem.page.data.id;
      window.context.item_type = this.state.navMenuItem.page.data.meta.item_type.data.id;
      win.systemRenderForm('.update-panel');
      win.$('#react-form').css('visibility', 'visible');
      this._setEvents();
    }.bind(this));
  },
  _checkInput: function(event) {
    var newNavMenuItem = this.state.navMenuItem;
    newNavMenuItem.display_name = event.target.value;
    newNavMenuItem.slug = utils.slugify(event.target.value);
    // newNavMenuItem.start_page = newNavMenuItem.start_page;
    this.setState({
      navMenuItem: newNavMenuItem
    });
    if ($('#form-cms').length) {
      $('#__nav_menu_item').val(JSON.stringify(this._getItem()));
      $('#form-cms').prop('disabled', false);
      $('#nav-menu-item-display-name-input').parent().removeClass('has-error');
    }
  },
  _checkInputSlug: function(event) {
    var newNavMenuItem = this.state.navMenuItem;
    // newNavMenuItem.display_name = newNavMenuItem.display_name;
    newNavMenuItem.slug = event.target.value;
    // newNavMenuItem.start_page = newNavMenuItem.start_page;
    this.setState({
      navMenuItem: newNavMenuItem
    });
    if ($('#form-cms').length) {
      $('#form-cms').prop('disabled', false);
      $('#nav-menu-item-display-name-input').parent().removeClass('has-error');
    }
  },
  _checkInputStartPage: function(event) {
    var newNavMenuItem = this.state.navMenuItem;
    // newNavMenuItem.display_name = newNavMenuItem.display_name;
    // newNavMenuItem.slug = newNavMenuItem.slug;
    newNavMenuItem.start_page = event.target.value === 'false' || event.target.value === 'on' ? true : false;
    this.setState({
      navMenuItem: newNavMenuItem
    });
    // if ($('#form-cms').length) {
    //   $('#__nav_menu_item').val(JSON.stringify(this._getItem()));
    //   $('#form-cms').prop('disabled', false);
    //   $('#nav-menu-item-display-name-input').parent().removeClass('has-error');
    // }
  },
  _checkInputParentId: function(event) {
    var parentId = $(event.target).parent().parent().parent().parent().parent().parent().parent().parent().first().data('id') || null;
    if (!parentId) {
      parentId = $(event.target).parent().parent().parent().parent().parent().parent().prev().data('id') || null;
      // parentId = this.props.prevElId;
    }
    console.log(parentId);
    // return;
    var newNavMenuItem = this.state.navMenuItem;
    // console.log(newNavMenuItem);
    // alert(this.props.prevElId);
    newNavMenuItem.meta.parent_id = newNavMenuItem.meta.parent_id === '' ? parentId : '';
    this.setState({
      navMenuItem: newNavMenuItem
    });
  },
  _changeParentIdIn: function(event) {
    var parentId = $(event.target).parent().parent().parent().parent().parent().parent().parent().parent().first().data('id') || null;
    if (!parentId) {
      parentId = $(event.target).parent().parent().parent().prev().data('id') || '';
    }
    var position = $(event.target).parent().parent().parent().prev().find('li').length;
    // alert(position);
    // console.log(parentId);
    // // return;
    var newNavMenuItem = this.state.navMenuItem;
    // // console.log(newNavMenuItem);
    // // alert(this.props.prevElId);
    newNavMenuItem.meta.parent_id = parentId;
    newNavMenuItem.meta.position = position;
    this.setState({
      navMenuItem: newNavMenuItem
    });
    // alert(parentId);
    this._updateItem();
  },
  _changeParentIdOut: function(event) {
    var parentId = $(event.target).parent().parent().parent().parent().parent().parent().parent().data('id') || '';
    // if (!parentId) {
    //   parentId = $(event.target).parent().parent().parent().prev().data('id') || '';
    // }
    var position = $(event.target).parent().parent().parent().parent().parent().index()+1;;
    // alert(position);
    // console.log(parentId);
    // // return;
    var newNavMenuItem = this.state.navMenuItem;
    // // console.log(newNavMenuItem);
    // // alert(this.props.prevElId);
    newNavMenuItem.meta.parent_id = parentId;
    newNavMenuItem.meta.position = position;
    this.setState({
      navMenuItem: newNavMenuItem
    });
    // alert(parentId);
    this._updateItem();
  },
  _setActive: function(event, id) {
    //parent.frames['page'].$('#pre-loader .content').text('Ladda sida...');
    //parent.frames['page'].$('#pre-loader').css('display', 'flex');  
    var navMenuItem = this.props.navMenuItem;
    // if (navMenuItem.is_active) {
    //   var pageUrl = navMenuItem.page.data.id + '?content_type=' + navMenuItem.page.data.meta.item_type.data.id + '&action=edit&mode=embed&nav_menu_item=true';
    //   parent.frames['page'].location.href = pageUrl;
    // }
    var id;
    if(event) {
      id = $(event.target).data('nav-menu-item-id');
    } else {
      id = id;
    }
    localStorage.setItem('nav-menu-item-active-id', id);
    this.props.reloadPageItems(id, false, true);
  },
  _activateSavePage: function(event) {
    if (event.keyCode == 13) {
      if ($('#form-cms').length) {
        $('#form-cms').click();
      }
      return false;
    }
  },
  _showSaveLoader: function(event) {
    if(!this.state.navMenuItem.id) {
      this.setState({
        navMenuItem: this.state.navMenuItem,
        isCreatingNewNavMenuItem: true
      });
    }
  },
  _updateItem: function(event) {
    var self = this;
    console.log('Updating');
    this.setState({
      navMenuItem: this.state.navMenuItem,
      isCreatingNewNavMenuItem: true
    });
    var endpoint = '/api';
    var query = {
      endpoint: 'items',
      bucket_id: window.bucketId
    }; 
    var data = this._getItem();
    data.meta.item_type = data.meta.item_type.data.id;
    async.parallel([
        function(callback) {
          request
            .put(endpoint)
            .send(data)
            .query(query)
            .set('Accept', 'application/json')
            .end(function(err, res) {
              if(err)  {
                alert(err);
              } else {
                callback(null, res.body);
              }
            }
          );
        }
      ],
      function (err, results) {
        //console.log(results[0]);
        if (results[0].status) {
          alert(results[0].validation.validations[0].messages[0]);
          //return;
        }
        self.props.reloadPageItems(null, true);
        parent.frames.postMessage({'action': 'reload_template'}, '*');
      }
    )
  },
  _updateItemOnEnter: function(event) {
    if (event.keyCode == 13) {
      var navMenuItem = $('#nav-menu-item-display-name-input');
      if (navMenuItem.length && navMenuItem.val().length === 0) {
        navMenuItem.parent().addClass('has-error');
        navMenuItem.focus();
        $('button[type="submit"]').prop('disabled', true);
      } else {
        this._updateItem(event);  
      }
    }
  },
  _deleteItem: function(event) {
    var self = this;
    var id = this.state.navMenuItem.__meta.id;
    var pageId = this.state.navMenuItem.page.data.id;
    console.log('Delete item: ' + id);
    console.log('Delete item: ' + pageId);
    this.setState({
      navMenuItem: this.state.navMenuItem,
      isCreatingNewNavMenuItem: true
    });
    this.setState({
      navMenuItem: this.state.navMenuItem,
      isDeletingNavMenuItem: true
    });
    var endpoint = '/api';
    var query = {
      endpoint: 'items',
      bucket_id: window.bucketId,
      id: id
    }; 
    async.parallel([
        function(callback) {
          request
            .del(endpoint)
            .query(query)
            .set('Accept', 'application/json')
            .end(function(err, res) {
              if(err)  {
                //alert(err);
              } else {
                callback(null, res);
              }
            }
          );
        },
        function(callback) {
          query.id = pageId;
          request
            .del(endpoint)
            .query(query)
            .set('Accept', 'application/json')
            .end(function(err, res) {
              if(err)  {
                //alert(err);
              } else {
                callback(null, res);
              }
            }
          );
        }
      ],
      function (err, results) {
        console.log(results[0]);
        console.log(results[1]);
        self.props.reloadPageItems(null, true);
        // if (parent.frames['page']) {
        //   if (~parent.frames['page'].location.toString().indexOf(self.state.navMenuItem.page.data.id)) {
        //     parent.frames['page'].location = 'about:blank';
        //   }
        // }
        parent.frames.postMessage({'action': 'reload_template'}, '*');
      }
    )
  },
  _getItem: function() {
    //console.log(this.state.navMenuItem.page)
    var data = {
      type: 'items',
      id: this.state.navMenuItem.__meta.id,
      attributes: {
        display_name: this.state.navMenuItem.display_name,
        slug: this.state.navMenuItem.slug,
        //type: 'item_type',
        //bject: this.state.navMenuItem.bject || '',
        //bject_id: this.state.navMenuItem.bject_id || '',
        path: this.state.navMenuItem.path || '',
        //target: '',
        //url: '',
        start_page: this.state.navMenuItem.start_page
      },
      relationships: {
        // object: {
        //   data: {
        //     type: 'item-types',
        //     id: this.state.navMenuItem.page.data.item_type || '',
        //   }
        // },
        page: {
          data: {
            type: 'items',
            id: this.state.navMenuItem.page.data.id || '',
            meta: {
              item_type: {
                data: {
                  type: 'item-types',
                  id: this.state.navMenuItem.page.data.meta.item_type.data.id || ''
                }
              }
            }
          }
        }
      },
      meta: {
        bucket: {
          data: {
            type: 'buckets',
            id: window.bucketId
          }
        },
        item_type: {
          data: {
            type: 'item-types',
            id: 'nav-menu-item'
          }
        },
        status: 'published',
        position: this.state.navMenuItem.meta.position,
        parent_id: this.state.navMenuItem.meta.parent_id
      }
    };
    //console.log('position: ' + this.state.navMenuItem.__meta.position);
    return data;
  },
  _toggleEditToolbar: function(event) {
    if (this.state.showEditToolbar) {
      this._hideEditToolbar();
    } else {
      this._showEditToolbar();
    }
    setTimeout(function() {
      if(this.refs.inputField) {
        this.refs.inputField.select();
      }
    }.bind(this), 0);
  },
  _showEditToolbar: function(event) {
    this.setState({
      navMenuItem: this.state.navMenuItem,
      showEditToolbar: true
    });
  },
  _hideEditToolbar: function(event) {
    this.setState({
      navMenuItem: this.state.navMenuItem,
      showEditToolbar: false
    });
  },
  _reloadItemsAfterFirstSave: function(event) {
    localStorage.setItem('nav-menu-item-active-id', parent.frames['page'].window.currentNavMenuItemId);
    this.props.reloadPageItems(null, true);
  },
  _addItem: function(event) {
    var position = $(event.target).parent().parent().parent().parent().parent().parent().index()+1;
    // position++;
    var itemTypeId = $(event.target).data('item-type-id');
    //href={'?content_type=' + itemTypeId + '&action=new&mode=embed&add_nav_menu_item=true'}
    //target='page'
    //console.log($(event.target).parent().parent().parent().parent().parent().parent());
    //alert(position);
    var parentId = $(event.target).parent().parent().parent().parent().parent().parent().parent().parent().data('id') || '';
    console.log(parentId);
    console.log(position);
    this.props.addPageItem(position, false, itemTypeId, parentId);
  },
  _toggleMenu: function(event) {
    if (this.state.showAddMenu) {
      this._hideMenu();
    } else {
      this._showMenu();
    }
  },
  _showMenu: function(event) {
    this.setState({
      showAddMenu: true
    });
  },
  _hideMenu: function(event) {
    this.setState({
      showAddMenu: false
    });
  },
  _getItemTypes: function(event) {
    var itemTypes = [];
    var self = this;
    if (this.props.getItemTypes()) {
      this.props.getItemTypes().map(function(itemType, index) {
        var itemTypeId = itemType.__meta.id;
        var type = 'items';
        if(itemType.show_in_page_menu) {
          itemTypes.push(
            React.createElement("a", {
              "data-item-type-id": itemTypeId, 
              onClick: self._addItem, 
              className: "list-group-item", key: index, 
              style: {background: '#fff',fontWeight: 'normal',cursor: 'pointer',borderLeft: 'solid 1px red'}
            }, 
            itemType.labels.singular_name.replace(/^[a-z]/, function(m){ return m.toUpperCase() })
            )
          );
        }
      });
      if(itemTypes.length) {
        itemTypes = (
          React.createElement("div", {className: "list-group", style: {margin: '0 -13px 0 -13px'}}, 
            itemTypes
          )  
        );
      }
      return itemTypes;
    }
  },
  render: function() {
    var navMenuItem = this.props.navMenuItem;
    var isCreatingNewNavMenuItem = this.state.isCreatingNewNavMenuItem;
    //isCreatingNewNavMenuItem = true;
    var isDeletingNavMenuItem = this.state.isDeletingNavMenuItem;
    var showEditToolbar = this.state.showEditToolbar;
    var isNew = navMenuItem.__meta.id === -1;
    var gui;
    var pageUrl = navMenuItem.page.data.id + '?content_type=' + navMenuItem.page.data.meta.item_type.data.id + '&action=edit&mode=embed&nav_menu_item=true';
    var propConfig = {type: 'string', title: 'Menynamn', required: true, isNew: false};
    // var testField = [];
    // testField.push(
    //   <PropertyItem
    //     name='display_name'
    //     value=''
    //     config={propConfig} />
    // );
    if (isCreatingNewNavMenuItem) {
      gui = (
        React.createElement("div", {className: "updating-menu-item"}, 
          React.createElement("span", {className: "fa fa-bars move-item"}), 
          React.createElement("a", {href: "javascript:void(0);"}, navMenuItem.display_name), 
          React.createElement("span", {className: "fa fa-cog fa-spin"})
        )
      );
    } else {
      if (isNew) {
        gui = (
          React.createElement("div", {className: "create-new"}, 
            React.createElement("div", null, 
              React.createElement("input", {ref: "inputField", id: "nav-menu-item-display-name-input", placeholder: "Namn på sidan", value: navMenuItem.display_name, className: "form-control", type: "text", onChange: this._checkInput, onKeyDown: this._activateSavePage}), 
              React.createElement("div", {className: "help-block help-block--message js-error-message"}, 
                React.createElement("i", {className: "fa fa-warning"}), 
                React.createElement("span", null, " "), 
                React.createElement("span", {className: "js-error-message-text"}, 
                  "Detta fält får inte vara tomt."
                )
              )
            ), 
            React.createElement("div", {style: {display:'none'}}, 
              React.createElement("input", {ref: "inputFieldSlug", id: "nav-menu-item-display-slug-input", tabIndex: "-1", placeholder: "Slugg", readOnly: true, value: navMenuItem.slug, className: "form-control", type: "text", onChange: this._checkInputSlug, onKeyDown: this._activateSavePage}), 
              React.createElement("div", {className: "help-block help-block--message js-error-message"}, 
                React.createElement("i", {className: "fa fa-warning"}), 
                React.createElement("span", null, " "), 
                React.createElement("span", {className: "js-error-message-text"}, 
                  "Detta fält får inte vara tomt."
                )
              )
            ), 
            React.createElement("div", {style: {display: 'none',position: 'absolute',top: '-1px',left: '100%',marginLeft:'0px',width: '150%', ackground: '#fff', padding: '0px', border: 'solid 1px #d6d6d6', borderLeft: 'none', orderBottom: 'none', orderRight: 'none'}}, 
              React.createElement("ul", {id: "myTabs", className: "nav nav-pills nav-stacked", role: "tablist", style: {float: 'right', width: '30%', borderLeft:'solid 1px #d6d6d6',height:'200px'}}, 
                React.createElement("li", {role: "presentation", className: "active"}, React.createElement("a", {href: "#home", "aria-controls": "home", role: "tab", "data-toggle": "tab", style: {borderRadius: 0}}, "Sida")), 
                React.createElement("li", {role: "presentation"}, React.createElement("a", {href: "#profile", "aria-controls": "profile", role: "tab", "data-toggle": "tab", style: {borderRadius: 0}}, "Länk"))
              ), 
              React.createElement("div", {className: "tab-content"}, 
                React.createElement("div", {role: "tabpanel", className: "tab-pane active", id: "home", style: {minHeight: '200px', padding: '10px', ackground: '#fff'}}, 
                  "Välj en sidtyp"
                ), 
                React.createElement("div", {role: "tabpanel", className: "tab-pane", id: "profile", style: {minHeight: '200px', padding: '10px', ackground: '#fff'}}, 
                  "Ange en länk"
                )
              )
            )
          )
        );
      } else {
        gui = (
          React.createElement("div", null, 
            React.createElement("span", {className: "fa fa-bars move-item"}), 
            React.createElement("a", {href: "javascript:void(0);", onClick: this._setActive, "data-nav-menu-item-id": navMenuItem.__meta.id, tabIndex: "-1", target: "page"}, navMenuItem.display_name), 
            this.props.prevElId === '' && 
            React.createElement("span", {
              title: 'Flytta in', 
              className: "pull-right change-parent-id-in", 
              "data-nav-menu-item-id": navMenuItem.__meta.id, 
              style: {opacity: '.5'}
            }, 
              React.createElement("span", {className: "fa fa-arrow-right"})
            ), 
            
            this.props.prevElId !== '' && 
            React.createElement("span", {
              title: 'Flytta in', 
              className: "pull-right change-parent-id-in", 
              "data-nav-menu-item-id": navMenuItem.__meta.id, 
              onClick: this._changeParentIdIn
            }, 
              React.createElement("span", {className: "fa fa-arrow-right"})
            ), 
            
            navMenuItem.meta.parent_id !== '' &&
            React.createElement("span", {
              title: 'Flytta ut', 
              style: {ight: '60px'}, 
              className: "pull-right change-parent-id-out", 
              "data-nav-menu-item-id": navMenuItem.__meta.id, 
              onClick: this._changeParentIdOut
            }, 
              React.createElement("span", {className: "fa fa-arrow-left"})
            ), 
            
            React.createElement("span", {
              title: 'Ändra', 
              className: "pull-right change-item", 
              "data-nav-menu-item-id": navMenuItem.__meta.id, 
              "data-item-id": navMenuItem.page.data.id, 
              onClick: this._toggleEditToolbar
            }, 
              React.createElement("span", {className: "fa fa-edit"})
            ), 
            React.createElement("span", {title: 'Lägg till en sida', className: "pull-right add-item fa fa-plus color-success", onClick: this._toggleMenu}), 
            this.state.showAddMenu && 
              React.createElement("div", {style: {zIndex: 1000,osition: 'absolute',top: '40px',left: '0%',marginLeft:'0px',width: '275px', ackground: '#fff', padding: '0px', border: 'solid 1px #d6d6d6', borderLeft: 'none', orderBottom: 'none', orderRight: 'none',margin: '-1px 0 0 0'}}, 
                React.createElement("ul", {id: "myTabs", className: "nav nav-pills nav-stacked", role: "tablist", style: {float: 'right', width: '40%', borderLeft:'solid 1px #d6d6d6',height:'220px',display: 'none'}}, 
                  React.createElement("li", {role: "presentation", className: "active"}, React.createElement("a", {href: "#home", "aria-controls": "home", role: "tab", "data-toggle": "tab", style: {borderRadius: 0}}, "Sida")), 
                  React.createElement("li", {role: "presentation"}, React.createElement("a", {href: "#profile", "aria-controls": "profile", role: "tab", "data-toggle": "tab", style: {borderRadius: 0}}, "Länk"))
                ), 
                React.createElement("div", {className: "tab-content", style: {padding: '10px 10px 0 10px', overflow: 'hidden',background: '#f1f1f1'}}, 
                  React.createElement("div", {role: "tabpanel", className: "tab-pane active", id: "home", style: {background: '#f1f1f1'}}, 
                    React.createElement("div", {style: {fontSize: '14px',fontWeight:'bold',marginBottom: '10px'}}, "Välj en sidtyp"), 
                    this._getItemTypes()
                  ), 
                  React.createElement("div", {role: "tabpanel", className: "tab-pane", id: "profile", style: {ackground: '#fff'}}, 
                    "Ange en länk"
                  )
                )
              ), 
            
            showEditToolbar &&
              React.createElement("div", {className: "edit-toolbar", id: 'settings-' + navMenuItem.__meta.id}, 
                React.createElement("div", {className: "item-types clearfix"}, 
                  React.createElement("div", null, 
                    React.createElement("input", {ref: "inputField", id: "nav-menu-item-display-name-input", placeholder: "Namn på sidan", value: navMenuItem.display_name, className: "form-control", type: "text", onChange: this._checkInput, onKeyDown: this._updateItemOnEnter}), 
                    React.createElement("div", {className: "help-block help-block--message js-error-message"}, 
                      React.createElement("i", {className: "fa fa-warning"}), 
                      React.createElement("span", null, " "), 
                      React.createElement("span", {className: "js-error-message-text"}, 
                        "Detta fält får inte vara tomt."
                      )
                    )
                  ), 
                  React.createElement("div", null, 
                    React.createElement("input", {ref: "inputFieldSlug", id: "nav-menu-item-display-slug-input", tabIndex: "-1", placeholder: "Slugg", readOnly: true, value: navMenuItem.slug, className: "form-control", type: "text", onChange: this._checkInputSlug, onKeyDown: this._activateSavePage}), 
                    React.createElement("div", {className: "help-block help-block--message js-error-message"}, 
                      React.createElement("i", {className: "fa fa-warning"}), 
                      React.createElement("span", null, " "), 
                      React.createElement("span", {className: "js-error-message-text"}, 
                        "Detta fält får inte vara tomt."
                      )
                    )
                  ), 
                  React.createElement("div", {style: {float: 'left',marginTop: '4px'}}, 
                    React.createElement("label", {style: {margin: '0'}}, 
                      React.createElement("input", {onChange: this._checkInputStartPage, checked: navMenuItem.start_page, value: navMenuItem.start_page, tabIndex: "-1", style: {fontSize: '16px',margin: '-2px 0 0 0'}, type: "checkbox", id: "start_page", name: "start_page"}), 
                      React.createElement("span", {style: {fontSize: '13px',fontWeight: 'normal',verticalAlign: 'middle', marginLeft: '5px'}}, "Startsida")
                    )
                  ), 
                  /*
                  <div style={{float: 'left',marginLeft: '8px', marginTop: '4px'}}>
                    <label style={{margin: '0'}}>
                      <input onChange={this._checkInputParentId} checked={navMenuItem.meta.parent_id !== ''} value={navMenuItem.meta.parent_id} tabIndex='-1' style={{fontSize: '16px',margin: '-2px 0 0 0'}} type='checkbox' />
                      <span style={{fontSize: '13px',fontWeight: 'normal',verticalAlign: 'middle', marginLeft: '5px'}}>Sub</span>
                    </label>
                  </div>
                  */
                  React.createElement("div", {style: {display: 'none',position: 'absolute',top: '-1px',left: '100%',marginLeft:'0px',width: '150%', ackground: '#fff', padding: '0px', border: 'solid 1px #d6d6d6', borderLeft: 'none', orderBottom: 'none', orderRight: 'none'}}, 
                    React.createElement("ul", {id: "myTabs", className: "nav nav-pills nav-stacked", role: "tablist", style: {float: 'right', width: '30%', borderLeft:'solid 1px #d6d6d6',height:'200px'}}, 
                      React.createElement("li", {role: "presentation", className: "active"}, React.createElement("a", {href: "#home", "aria-controls": "home", role: "tab", "data-toggle": "tab", style: {borderRadius: 0}}, "Sida")), 
                      React.createElement("li", {role: "presentation"}, React.createElement("a", {href: "#profile", "aria-controls": "profile", role: "tab", "data-toggle": "tab", style: {borderRadius: 0}}, "Länk"))
                    ), 
                    React.createElement("div", {className: "tab-content"}, 
                      React.createElement("div", {role: "tabpanel", className: "tab-pane active", id: "home", style: {minHeight: '200px', padding: '10px', ackground: '#fff'}}, 
                        "Välj en sidtyp"
                      ), 
                      React.createElement("div", {role: "tabpanel", className: "tab-pane", id: "profile", style: {minHeight: '200px', padding: '10px', ackground: '#fff'}}, 
                        "Ange en länk"
                      )
                    )
                  ), 
                  isDeletingNavMenuItem &&
                    React.createElement("span", {className: "fa fa-cog fa-spin pull-right"}), 
                  
                  !isDeletingNavMenuItem &&
                    React.createElement("span", null, 
                      React.createElement("span", {title: 'Spara', className: "btn btn-sm btn-primary pull-right", onClick: this._updateItem, "data-nav-menu-item-id": navMenuItem.__meta.id, "data-item-id": navMenuItem.page.data.id}, 
                        "Spara"
                      ), 
                      React.createElement("span", {title: 'Ta bort', className: "btn btn-sm btn-default delete pull-right", onClick: this._deleteItem, "data-nav-menu-item-id": navMenuItem.__meta.id, "data-item-id": navMenuItem.page.data.id}, 
                        "Ta bort", 
                        React.createElement("span", {className: "fa fa-remove"})
                      )
                    )
                  
                )
              )
            
          )
        );
      }
    }
    // var classes = {background: 'transparent', border: 'none', borderBottom: '1px solid #d6d6d6',marginBottom: 0};
    // if(isNew) {
    //   classes = {background: '#f1f1f1', border: 'none', borderBottom: '1px solid #d6d6d6',marginRight: '-1px',marginBottom: 0};
    // }
    // if(navMenuItem.is_active) {
    //   classes = {background: '#f1f1f1', border: 'none', borderBottom: '1px solid #d6d6d6',marginRight: '-1px',marginBottom: 0,fontWeight: 'bold'};
    // }
    var classes = '';
    if(isNew) {
      classes += ' new';
    }
    if(navMenuItem.is_active) {
      classes += ' active';
    }
    return (
      React.createElement("li", {"data-id": navMenuItem.__meta.id, className: classes}, 
        gui, 
        navMenuItem.is_active &&
          React.createElement("div", null, 
            React.createElement("div", {id: "nav-menu-item-save", onClick: this._showSaveLoader, style: {display: 'none'}}), 
            React.createElement("div", {id: "nav-menu-item-reload-items", onClick: this._reloadItemsAfterFirstSave, style: {display: 'none'}})
          ), 
        
        this.props.children
      )
    );
  }
});

module.exports = PageItem;