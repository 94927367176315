var aceTexteditor = {
  init: function() {
    // ace text editor
    if (typeof ace === 'undefined') return;
    var $aceEditorContainer = $('body .ace-editor-container');
    //console.log('whatss');
    $aceEditorContainer.each(function(index, val) {
      //console.log(val);
      var isFullyInstantiated = false;
      var $divEditor = $(this).find('.ace-editor-element');
      var $textarea = $divEditor.prev(); 
      var format = $(this).data('format');
      var editor = ace.edit($divEditor[0]); 
      //var textarea = $textarea.hide();
      var textarea = $textarea;
      editor.getSession().setValue(textarea.val());
      editor.getSession().on('change', function(e, a) {
        textarea.val(editor.getSession().getValue());
        if (format !== 'json') {
          if ($('#clientframe').length && isFullyInstantiated) {
            $textarea.click();
          }
        }
      });
      editor.getSession().on('changeAnnotation', function() {
        var isValid = true;
        var annot = editor.getSession().getAnnotations();
        for (var key in annot) { 
          if (annot.hasOwnProperty(key)) { 
            if (annot[key].type === 'error') {
              isValid = false;
              // console.log(annot[key].text + "on line " + " " + annot[key].row); 
              // console.log(annot[key]);
            }
          }
        }
        if(isValid) {
          if ($('#clientframe').length && isFullyInstantiated && editor.getSession().getValue().length > 0) {
            $textarea.click();
          }
          $('button[type="submit"]').prop('disabled', false);
        } else {
          //console.log('not valid');
          $('button[type="submit"]').prop('disabled', true);
        }
      });
      //editor.setTheme('ace/theme/ambiance');
      //editor.setTheme('ace/theme/clouds');
      //editor.setTheme('ace/theme/dreamweaver');
      //editor.setTheme('ace/theme/tomorrow_night_eighties');
      //editor.setTheme('ace/theme/xcode');
      editor.getSession().setMode('ace/mode/'+format);
      var value = editor.getValue();
      if (format === 'json') {
        try {
          if(value) {
            value = JSON.parse(value);
            value = JSON.stringify(value, null, '  ');
          }
        }
        catch(err)
        {
          alert(err);
        }
      }
      if (format === 'html') {
        editor.getSession().setUseWorker(false);
      }
      editor.getSession().setTabSize(2);
      editor.setValue(value);
      editor.clearSelection();
      editor.moveCursorToPosition({row: 0, column: 0})
      //editor.renderer.setShowGutter(false);
      editor.setHighlightActiveLine(false);
      //editor.setShowPrintMargin(true);
      //editor.setShowInvisibles(true);
      //editor.renderer.setShowGutter(true);
      //editor.renderer.setDisplayIndentGuides(false);
      //editor.renderer.setShowPrintMargin(true);
      //editor.renderer.setHighlightGutterLine(true);
      //editor.focus();
      //console.log(editor.isFocused());
      setTimeout(function () {
        isFullyInstantiated = true;
      }, 500);
    });
  }
}

module.exports = aceTexteditor;