var markdownEditor = require('./markdownEditor.js');
var controls = require('./controls.js');
var formView = require('./formView.js');
var pageNavigation = {
  init: function() {
    if($('.nav--page-navigation').length) {
      function run() {
        //console.log('run');
        //var createdId = (window.location.search.match(new RegExp('created_id' + "=(.*?)($|\&)", "i")) || [])[1] || '';
        var createdId = parent.frames['page'] && parent.frames['page'].created_id ? parent.frames['page'].created_id : null;
        if(createdId) {
          localStorage.setItem('page-navigation-id', createdId);
        }
        function setMenu(item) {
          var position;
          var parent = '';
          var id;
          var contentType;
          if(item) {
            contentType = item.data('content-type');
            id = item.data('id');
            //position = item.parent().find(' > li').length;
            position = item.index()+1;
            //console.log(item.index());
            parent = item.parent().parent().data('id') ? item.parent().parent().data('id') : '';
          } else {
            position = $('.nav--page-navigation > li').length;
          }
          //alert(position);
          $('.create-page a').each(function(index) {
            var item = $(this);
            item.prop('href', item.data('url').replace('[parent]', parent).replace('[position]', position));
          });
          if(item) {
            parent = item.data('id');
            position = item.find('> ul > li').length;
          }
          $('#context-menu a').each(function(index) {
            var item = $(this);
            item.prop('href', item.data('url').replace('[parent]', parent).replace('[position]', position).replace('[content_type_id]', id).replace('[content_type]', contentType));
          });
          
          if(item) {
            item.find(' > .create-page-in-menu a').each(function(index) {
              var item = $(this);
              item.prop('href', item.data('url').replace('[position]', position));
            });
          }
        }
        setMenu();
        $('.nav--page-navigation li a').not('.create-page-in-menu a').bind('click contextmenu', function() {
          //localStorage.getItem('accordionIndex');
          localStorage.setItem('page-navigation-id', $(this).parent().data('id'));
          //localStorage.removeItem('accordionItemWasRemoved');
          setMenu($(this).parent());
          $('.nav--page-navigation li a').parent().removeClass('active');
          $(this).parent().addClass('active');
        });
        $('.create-page li a').bind('click', function() {
          //$('.nav--page-navigation li a').parent().removeClass('active');
          //$('.navigation-wrapper div').css('opacity', .0);
        });
        //Sort navigation
        var sortSelector = '.nav--page-navigation';
        sortSelector = 'ul';
        var handle = '.move-item';
        var items = '> li';
        //var sortableStartIndex = null;
        $(sortSelector).sortable({
          items: items,
          handle: handle,
          placeholder: 'sortable-placeholder',
          axis: 'y',
          revert: 300,
          delay: 100,
          //containment: 'body',
          //tolerance: 'pointer',
          start: function(event, ui) {
            var item = ui.item;
            var placeholder = ui.placeholder;
            placeholder.css('height', item[0].offsetHeight+1 + 'px');
            //sortableStartIndex = item.index();
          },
          stop: function(event, ui) {
            var item = ui.item;
            //var from = sortableStartIndex;
            var to = item.index();
            var parent_id =  item.parent().parent().data('id') ? item.parent().parent().data('id') : '';  
            var id = item.data('id');
            var group = 'page';
            var setType = '&_item_type=page'; 
            //var url = id;
            var data = 
            '_action=reorder' +
            '&' +
            '_id=' + id +
            '&' +
            '_parent_id=' + parent_id +
            setType +
            //'&' +
            //'_from=' + from +
            '&' +
            '_to=' + to;
            $.ajax({
              type: 'POST',
              //url: url,
              data: data,
              success: function(response) {
                console.log('Something happend');
              }
            });
            //alert(from + ' : ' + to);
            // if(currentActive !== false) {
            //   if(currentActive !== sortableStartIndex) {
            //     active = currentActive;
            //     if((item.index() <= active) && (sortableStartIndex > active)) {
            //       //console.log('hehehehe');
            //       active++;
            //     }
            //     if(item.index() >= active) {
            //       active--;
            //       //console.log('what');
            //     }
            //   } else {
            //     active = item.index();
            //   }
            // }
          }
        });

        $('#context1').contextmenu({
          //target:'#context-menu', 
          //before: function(e,context) {
            // execute code before context menu if shown
          //  return true;
          //},
          onItem: function(context,e) {
            e.target.click();
            // execute on menu item selection
            //  return true;
          }
        });
        //$('.navigation-wrapper div').css('opacity', 1);
        function getItem(url, query, callback) {
          query = query || {};
          var data = $.param(query);
          $.ajax({
            type: 'GET',
            url: url,
            data: data,
            success: function(response) {
              callback(response);
            }
          });
        }
        function setEvents() {
          markdownEditor.init();
          controls.init();
          formView.init();
          var submitButton = $('#form-cms');
          submitButton.unbind();
          submitButton.click(function() {
            var win = parent.frames['page'];
            win = window.top;
            $('#pre-loader .content').text(preLoaderText);
            $('#pre-loader').css('display', 'flex');
            var data = win.$('.update-panel').find('form').serialize();
            $.ajax({
              type: 'POST',
              url: win.$('.update-panel').find('form').attr('action'),
              data: data,
              success: function(response) {
                win.$('.update-panel').find('.alert-success').remove();
                var str = '<div class="alert alert-success alert-dismissable fade in" role="alert">';
                str += $(response).find('.alert-success').html();
                str += '</div>';
                win.$('.update-panel').prepend(str);
                $('#pre-loader').css('display', 'none');
                //win.$('.update-panel').parent().next().find('.js-inline-dialog-cancel').click();
              }
            });
            return false;
          });
        }
        $('.create-page a').each(function(index) {
          var item = $(this);
          item.click(function() {
            getItem(item.prop('href'), null, function(response) {
              var objHtml = $(response).find('.update-panel').html();
              var win = parent.frames['page'];
              win = window.top;
              win.$('.update-panel').html(objHtml);
              window.context.item_id = -1;
              window.context.item_type = item.data('content-type');
              //alert(window.context.item_type)
              win.$('#react-form').css('visibility', 'visible');
              win.systemRenderForm('.update-panel');
              setEvents();
            })
            return false;  
          });
        });
        $('.nav--page-navigation a').each(function(index) {
          var item = $(this);
          item.click(function() {
            getItem(item.prop('href'), null, function(response) {
              var objHtml = $(response).find('.update-panel').html();
              var win = parent.frames['page'];
              win = window.top;
              win.$('.update-panel').html(objHtml);
              window.context.item_id = item.parent().data('id');
              window.context.item_type = item.parent().data('content-type');
              // alert(window.context.item_id)
              // alert(window.context.item_type)
              win.systemRenderForm('.update-panel');
              win.$('#react-form').css('visibility', 'visible');
              setEvents();
            })
            return false;  
          });
        });
        if(localStorage.getItem('page-navigation-id') && $('.nav--page-navigation').length) {
          var id = localStorage.getItem('page-navigation-id');
          //if(!parent.frames['page'].document.forms.length && $('[data-id="' + id + '"] > a').length) {
          if(!document.forms.length && $('[data-id="' + id + '"] > a').length) {
            $('[data-id="' + id + '"] > a')[0].click();
          } else {
            $('[data-id="' + id + '"]').addClass('active');
          }
          //localStorage.removeItem('page-navigation-id');
        }
      }
      run();
      //$(document).delegate('change', '.nav--page-navigation', function() { 
      //$('.nav--page-navigation').on('click', function() {
      //  alert('s');
      //  if(parent.frames['page'].loadNavigation) {
      //    run();
      //  }
      //});
    }
  }
}

module.exports = pageNavigation;