var showdown = require('./showdown.js');

var markdownEditor = {
  init: function() {
    if (typeof ace === 'undefined') {
      if ($('.ace-editor-container-markdown textarea').css('position') !== 'static') {
        $('.ace-editor-container-markdown textarea').css('position', 'static');
        // $('.ace-editor-container-markdown textarea').markdown();
        // $('.ace-editor-container-markdown textarea').attr('data-provide', 'markdown');
        // $('.ace-editor-container-markdown textarea').markdownEditor();
        // console.log('Include script: //sdk.twixly.io/ace-builds/src-min-noconflict/ace.js to activate the markdown editor!');
      }
      return; 
    } else {
      $('.ace-editor-container-markdown textarea').css('position', 'absolute');
      require('../../bower_components/bootstrap-markdown-editor/dist/js/bootstrap-markdown-editor.js');
      // Cant use with ace for the moment
      var converter = new showdown.Converter();
      // if($('.ace-editor-element').length === 0) {
        var $markdownEditors = $('.ace-editor-container-markdown');
        $markdownEditors.each(function(index, val) {
          var self = this;
          var $divEditor = $(this).find('.ace-editor-element-markdown');
          var $textarea = $divEditor.prev();
          var editorHeight = $(self)[0].offsetHeight != 0 ? $(self)[0].offsetHeight : 200;
          if ($(self)[0].offsetHeight) {$(self).css('height', editorHeight+40+'px')};
          editorHeight = editorHeight + 40 - 40;
          //alert($(self)[0].offsetHeight)
          $divEditor.markdownEditor({
            fullscreen: true,
            preview: true,
            height: editorHeight,
            // theme: 'ambiance',
            // This callback is called when the user click on the preview button:
            onPreview: function (content, callback) {
              var res = converter.makeHtml(content);
              callback(res);
            },
            //imageUpload: true, // Activate the option
            //uploadPath: 'edit/buckets/555743dea62e4a7409000002/items/?content_type=image&mode=embed' // Path of the server side script that receive the files
            // label: {
            //   btnHeader1: 'Header 1',
            //   btnHeader2: 'Header 2',
            //   btnHeader3: 'Header 3',
            //   btnBold: 'Bold',
            //   btnItalic: 'Italic',
            //   btnList: 'Unordered list',
            //   btnOrderedList: 'Ordered list',
            //   btnLink: 'Link',
            //   //btnImage: 'Insert image',
            //   //btnUpload: 'Uplaod image',
            //   btnEdit: 'Edit',
            //   btnPreview: 'Preview',
            //   btnFullscreen: 'Fullscreen',
            //   loading: 'Loading'
            // }
          });
          var editor = ace.edit($(this).find('.md-editor')[0]);
          editor.$blockScrolling = Infinity;
          editor.getSession().on('change', function() {
            // var res = converter.makeHtml(editor.getSession().getValue());
            // re = new RegExp(' id=(\'|\")([ -0-9a-zA-Z:]*[ 0-9a-zA-Z;]*)*(\'|\")', 'ig');
            // var returnValue = res.replace(re, '');
            // returnValue = returnValue.replace(/\n/g, '');
            // returnValue = returnValue.replace(/\t/g, '');
            $textarea.val(editor.getSession().getValue());
            $textarea[0].click();
          });
          //var initValue = toMarkdown($textarea.val());
          var initValue = $textarea.val();
          editor.setValue(initValue);
          editor.clearSelection();
          editor.renderer.setPadding(12);
          editor.renderer.setScrollMargin(10, 10);
          var $md_ed = $(this).find('.md-editor');
          var $md_pr = $(this).find('.md-preview');
          //$md_pr.addClass('ace-tomorrow');
          //editor.renderer.setPaddingTop(50);
          // have to do this to get the right height
          //$aceContent.css('height', $aceContent[0].offsetHeight + 24 + 'px');
          //$aceContent.css('height', '200px');
          //alert($aceContent[0].offsetHeight);
          //editor.moveCursorToPosition({row: 0, column: 0})
          //editor.setHighlightActiveLine(false);
          // markdownEditor.getSession().on('change', function() {
          //   //alert('');
          //   //textarea.val(editor.getSession().getValue());
          // });
          //alert(markdownEditor.markdownEditor('content'));
          //var textarea = $textarea.hide();
          // var textarea = $textarea;
          // editor.getSession().setValue(textarea.val());
          // editor.getSession().on('change', function() {
          //   textarea.val(editor.getSession().getValue());
          // });
        });
      // }
    }
  }
}

module.exports = markdownEditor;