var React = require('react');
var SchemaField = require('../FormObject/SchemaField');
var FormActions = require('./FormActions');

var Form = React.createClass({displayName: "Form",
  propTypes: {
    itemType: React.PropTypes.object.isRequired,
    item: React.PropTypes.object,
    options: React.PropTypes.object
  },
  
  onFormChange: function (fieldName, value, isValid) {
    this.props.item = this.props.item || {attributes: {} };
    this.props.item.attributes[fieldName] = value;
    if (this.props.options && this.props.options.onChange) {
      this.props.options.onChange(this.props.itemType, this.props.item, fieldName, value);
    }
  },

  render: function() {
    // For now only for extensions
    window.currentItemType = JSON.parse(JSON.stringify(this.props.itemType));
    // window.currentItem = this.props.item ?
    //                      JSON.parse(JSON.stringify(this.props.item)) :
    //                      {attributes: {}};
    window.currentItemExtension = this.props.item ?
                         JSON.parse(JSON.stringify(this.props.item)) :
                         {attributes: {}};
    var classes = 'form--default';
    if (this.props.options && this.props.options.formSize) {
      classes = 'form--default form--' + this.props.options.formSize;
    }

    var style = {};
    var itemType = this.props.itemType;
    var item = this.props.item;
    if (typeof itemType != 'undefined' && itemType.attributes && itemType.attributes.schema && itemType.attributes.schema.properties) {
      var props = itemType.attributes.schema.properties;
      for (var propKey in props) {
        var prop = props[propKey];
        if (prop.extension) {
          // style.position = 'absolute';
          // style.top = '300%';
          // style.width = '100%';
          // style.position = 'relative';
          // style.zIndex = -1;
          // style.visibility = 'hidden';
          style.opacity = 0;
        }
      }
    }

    // Map ui schema properties in JSON schema to the UI Schema
    if (typeof itemType != 'undefined' && itemType.attributes && !itemType.attributes.ui_schema) {
      itemType.attributes.ui_schema = {};
    }
    if (typeof itemType != 'undefined' && itemType.attributes && itemType.attributes.schema && itemType.attributes.schema.properties) {
      function setUiSchemaProp(propKey, prop, objectKey) {
        if (prop['ui:widget']) {
          if (objectKey) {
            // console.log(propKey, prop['ui:widget'])
            if (~objectKey.indexOf(',')) {
              if (!itemType.attributes.ui_schema[objectKey.split(',')[0]]) {
                itemType.attributes.ui_schema[objectKey.split(',')[0]] = {};
              }
              if (!itemType.attributes.ui_schema[objectKey.split(',')[0]][objectKey.split(',')[1]]) {
                itemType.attributes.ui_schema[objectKey.split(',')[0]][objectKey.split(',')[1]] = {};
              }
              itemType.attributes.ui_schema[objectKey.split(',')[0]][objectKey.split(',')[1]][propKey] = {
                'ui:widget': prop['ui:widget']
              }
            } else {
              if (!itemType.attributes.ui_schema[objectKey]) {
                itemType.attributes.ui_schema[objectKey] = {};
              }
              itemType.attributes.ui_schema[objectKey][propKey] = {
                'ui:widget': prop['ui:widget']
              }
            }
          } else {
            itemType.attributes.ui_schema[propKey] = {
              'ui:widget': prop['ui:widget']
            }  
          }
        }
        // Set references if any
        if (prop['$ref']) {
          var references = itemType.attributes.schema.references;
          if (references) {
            // console.log(itemType.attributes.schema.references)
            // console.log('["references"' + prop['$ref'].replace('#', '').replace(/\//g, '"]["') + '"]');
            var props = prop;
            var refProp = eval('itemType.attributes.schema["references' + prop['$ref'].replace('#', '').replace(/\//g, '"]["') + '"]');
            if (refProp) {
              if (objectKey) {
                itemType.attributes.schema.properties[objectKey].properties[propKey] = JSON.parse(JSON.stringify(refProp));
                for (var propKey2 in props) {
                  if (propKey2 !== '$ref') {
                    itemType.attributes.schema.properties[objectKey].properties[propKey][propKey2] = props[propKey2];
                  }
                }
                // Set ref setting
                // var refSetting = itemType.attributes.schema.properties[objectKey].properties[propKey]['default'];
                // // var defaultValue = itemType.attributes.schema.properties[objectKey].properties[propKey]['default'];
                // if (typeof refSetting === 'string' && refSetting.startsWith('#/') && item && item.attributes) {
                //   var p = {
                //     data: onePage.dataFactory.getPageData1()
                //   };
                //   try {
                //     var refSettingCleaned = 'p["data' + refSetting.replace('#\/settings', '').replace(/\//g, '"]["') + '"]';
                //     // console.log(refSettingCleaned);
                //     var refSettingValue = eval(refSettingCleaned);
                //     // console.log(refSettingValue);
                //     // console.log(item);
                //     // console.log(objectKey);
                //     // console.log(propKey);
                //     if (!item.attributes[objectKey]) {
                //       item.attributes[objectKey] = {};
                //     }
                //     if (!item.attributes[objectKey][propKey]) {
                //       item.attributes[objectKey][propKey] = refSettingValue;
                //       // if (defaultValue) {
                //       //   item.attributes[objectKey][propKey] = defaultValue;
                //       // }
                //     }
                //   } catch (e) {
                //     console.log(e);
                //   }
                // }
              } else {
                itemType.attributes.schema.properties[propKey] = refProp;
                for (var propKey2 in props) {
                  if (propKey2 !== '$ref') {
                    itemType.attributes.schema.properties[propKey][propKey2] = props[propKey2];
                  }
                }  
              }
            } else {
              console.log('$ref does not exist!');
            }
          }
        }
      }
      function setUiSchemaProps(props, objectKey) {
        for (var propKey in props) {
          var prop = props[propKey];
          setUiSchemaProp(propKey, prop, objectKey);
          if (prop.type === 'array') {
            if (prop.items && prop.items.type === 'object' && prop.items.properties) {
              var pKey = propKey;
              if (objectKey) {
                pKey = objectKey + ',' + propKey;
              }
              setUiSchemaProps(prop.items.properties, pKey);
            }
          } else if (prop.type === 'object') {
            if (prop.properties) {
              var pKey = propKey;
              if (objectKey) {
                pKey = objectKey + ',' + propKey;
              }
              setUiSchemaProps(prop.properties, pKey);
            }
          }
        }
      }
      var props = itemType.attributes.schema.properties;
      setUiSchemaProps(props);
      // Remove references for now. This is because the JSON validator gets errors otherwise
      // if (itemType.attributes.schema.references) {
      //   itemType.attributes.schema['references'] = undefined;
      //   window.currentItemType.attributes.schema['references'] = undefined;
      // }
    }

    // console.log(itemType.attributes.schema);
    // console.log(this.props.item);
    return (
      React.createElement("form", {className: classes, id: 'c10_form_' + this.props.itemType.id, method: "post", style: style}, 
        React.createElement("div", {className: "form-error js-form-error"}), 
        React.createElement("div", null, 
          React.createElement(SchemaField, {
            item: this.props.item, 
            itemType: this.props.itemType, 
            options: this.props.options, 
            onFormChange: this.onFormChange}), 
          React.createElement("hr", {className: "form-actions-divider"}), 
          React.createElement(FormActions, {itemType: this.props.itemType, item: this.props.item, options: this.props.options})
        )
      )
    );
  }
});

module.exports = Form;

