var request = require('superagent');
var async = require('async');
var merge = require('merge');
var formView = require('./formView.js');

var dataKey = 'cms_data';

module.exports = {
  showLoader: function(options) {
    if ($('#js-loader').length) {
      return;
    };
    defaults = {
      text: 'Laddar...'
    };
    options = merge.recursive(defaults, options);
    selector = options.selector || 'body';
    var loader = $('<div id="js-loader" class="loader loader--light-inverted-bg" style="visibility:visible"><div class="content">'+ options.text +'</div></div>');
    $(selector).append(loader);
  },
  hideLoader: function() {
    $('#js-loader').remove();
  },
  slugify: function(text, separator) {
    separator = separator ?
                separator :
                '-';
    return text.toString().toLowerCase()
      .replace(/\s+/g, separator)     // Replace spaces with -
      .replace(/å/g, 'a')             // For swedish
      .replace(/ä/g, 'a')             // For swedish
      .replace(/ö/g, 'o')             // For swedish
      .replace(/Å/g, 'a')             // For swedish
      .replace(/Ä/g, 'a')             // For swedish
      .replace(/Ö/g, 'o')             // For swedish
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  },
  getRequestData: function(itemType, item, options) {
    options = options ?
              options : 
              {};
    var data =
    {
      type: itemType.type === 'item-types' ? 'items' : itemType.type,
      id: item ? item.id : null,
      attributes: {},
      relationships: {},
      meta: {
        bucket: {
          data: {
            type: 'buckets',
            id: window.bucketId
          }
        },
        item_type: {
          data: {
            type: 'item-types',
            id: itemType.id
          }
        },
        status: $('#c10_form_' + itemType.id).find('#__status').val(),
        position: options.position || 0,
        parent_id: options.parentId || ''
      }
    };
    if (!item) {
      delete data.id;
    }
    // if (itemType.type !== 'item-types') {
    //   delete data.relationships;
    //   delete data.meta;
    // }
    // TODO: Remove require formview. Don't need it.
    formView.disableAllObjectInputs();
    for (var propKey in itemType.attributes.schema.properties) {
      var props = itemType.attributes.schema.properties[propKey];
      var inputEl = $('#c10_form_' + itemType.id).find('[name="' + propKey + '"]');
      if (inputEl && !inputEl.attr('disabled')) {
        value = inputEl.val();
        if (props.type !== 'relationship') {
          if (props.put_in_data) {
            data[propKey] = value;
          } else {
            data.attributes[propKey] = value;
          }
        } else if (props.type === 'relationship') {
          data.relationships[propKey] = value;
        }
      }
    }
    formView.enableAllObjectInputs();
    // data = this.setCorrectTypes(data, itemType);
    return data;
  },
  getCachedContext: function() {
    return localStorage.getItem(dataKey) ? JSON.parse(localStorage.getItem(dataKey)) : null;
  },
  clearContext: function() {
    localStorage.removeItem(dataKey);
  },
  setCorrectTypes: function(item, itemType) {
    // console.log('before:', item)
    function _setCorrectTypes(instance, schema) {
      var fixedItem = {};
      var properties = schema.properties;
      for(var key in instance) {
        key = key && ~key.indexOf('__') ?
              key.split('__')[1] :
              key;
        var value = instance[key];
        // Not in object
        if (properties && properties[key]) {
          if (properties[key].type === 'object') {
            var objectValue = value;
            if (typeof objectValue === 'string') {
              try {
                objectValue = JSON.parse(objectValue);
              }
              catch(e) {
                // array = [];
                objectValue = undefined;
              }
            }
            // if (objectValue) {
              // Fix validation for extension when you have time
              if (properties[key].extension) {
                fixedItem[key] = objectValue;
              } else {
                fixedItem[key] = _setCorrectTypes(objectValue, properties[key]);  
              }
            // }
          } else if (properties[key].type === 'array') {
            var fixedArr = [];
            // var arrayValue = value instanceof Array ? value : JSON.parse(value);
            // arrayValue = arrayValue ? arrayValue : [];
            var arrayValue = value;
            if (typeof arrayValue === 'string') {
              try {
                arrayValue = JSON.parse(arrayValue);
              }
              catch(e) {
                // arrayValue = [];
                arrayValue = undefined;
              }
            }
            if (arrayValue) {
              // Fix validation for extension when you have time
              if (properties[key].extension) {
                fixedArr = arrayValue;
              } else {
                for (var i = 0;i < arrayValue.length; i++) {
                  if (typeof arrayValue[i] === 'string') {
                    fixedArr.push(arrayValue[i]);
                  } else {
                    var obj = _setCorrectTypes(arrayValue[i], properties[key].items);
                    fixedArr.push(obj);
                  }
                }
              }
            }
            fixedItem[key] = fixedArr;
          } else {
            if ((properties[key].type === 'string' || properties[key].type === 'text') && typeof value === 'string' && value.length === 0 && properties[key].format) {
              fixedItem[key] = undefined;
            } else if (schema.required && ~schema.required.indexOf(key) && (properties[key].type === 'string' || properties[key].type === 'slug' || properties[key].type === 'text') && typeof value === 'string' && value.length === 0) {
              fixedItem[key] = undefined;
            } else if (properties[key].type === 'boolean') {
              fixedItem[key] = (value === 'true' || value === true) ? true : false;
            } else if (properties[key].type === 'integer') {
              fixedItem[key] = typeof value === 'string' && value.length > 0 && !~value.indexOf('.') && !isNaN(value) ?
                                     parseInt(value) :
                                     typeof value === 'string' && value.length === 0 ?
                                       undefined :
                                       value;
            } else if (properties[key].type === 'number') {
              fixedItem[key] = typeof value === 'string' && value.length > 0 && value.substring(value.length - 1) != '.' && !isNaN(value) ?
                                     parseFloat(value) :
                                     typeof value === 'string' && value.length === 0 ?
                                       undefined :
                                       value;
            } else if (properties[key].type === 'string' && properties[key].format === 'date-time') {
              // ToDo: Fix this later. Add option for choosing time zone.
              // fixedItem[key] = value ? new Date(value).toISOString() : value;
              var dateTime = new Date(value);
              fixedItem[key] = (value && dateTime.toString() !== 'Invalid Date') ? new Date((dateTime.getTime()) + (60.00 * 60 * 1000)).toISOString() : value;
              // fixedItem[key] = value ? value.replace(' ', 'T') + ':00.000Z' : value;
              // console.log('d', fixedItem[key]);
            } else {
              fixedItem[key] = value;
            }
          }
        }
      }
      return fixedItem;
    }
    item.attributes = _setCorrectTypes(item.attributes, itemType.attributes.schema);
    // console.log(item)
    return item;
  },
  getContext: function(cb, fetchAgain) {
    if (typeof window.accessToken === 'undefined' && typeof window.username === 'undefined') {
      cb({message: 'Must have an access token'});
      return;  
    }   
    if (fetchAgain) {
      this.clearContext();
    }
    var context = this.getCachedContext();
    if((!context || !context.items || !context.itemTypes || context.items.message)) {
      async.parallel({
        itemTypes: function(callback) {
          cmsClient.get('item-types')
            .then(function(data) {
              callback(null, data);
            })
            .catch(function(err) {
              try {
                callback(err);  
              } catch(e) {
                // Handle callback was already called error
                // console.log(e.message);
              }
            });
        },
        items: function(callback) {
          cmsClient.get('items')
            .then(function(data) {
              callback(null, data);
            })
            .catch(function(err) {
              try {
                callback(err);  
              } catch(e) {
                // Handle callback was already called error
                // console.log(e.message);
              }
            });
        },
        media: function(callback) {
          cmsClient.get('media')
            .then(function(data) {
              callback(null, data);
            })
            .catch(function(err) {
              try {
                callback(err);  
              } catch(e) {
                // Handle callback was already called error
                // console.log(e.message);
              }
            });
        },
        extensions: function(callback) {
          cmsClient.get('extensions')
            .then(function(data) {
              callback(null, data);
            })
            .catch(function(err) {
              try {
                callback(err);  
              } catch(e) {
                // Handle callback was already called error
                // console.log(e.message);
              }
            });
        }
      },
      function(err, results) {
        if (err) {
          cb(err);
        } else {
          if (results.items.message) {
            cb(results.items);
          } else {
            window.cmsContext = typeof window.cmsContext !== 'undefined' ?
                               window.cmsContext :
                               {};
            window.cmsContext.itemTypes = results.itemTypes;
            window.cmsContext.items = results.items;
            window.cmsContext.media = results.media;
            window.cmsContext.extensions = results.extensions;
            if (!window.cmsContext.items) {
              // console.log('what');
            }
            localStorage.setItem(dataKey, JSON.stringify(window.cmsContext));
            // console.log('fetched');
            cb(null, window.cmsContext);
          }
        }
      }.bind(this));
    } else {
        window.cmsContext = context;
        // console.log('not fetched');
        cb(null, window.cmsContext);  
      }
    }
};