var React = require('react');
var PropertyItem = require('./PropertyItem');
var SchemaField1 = require('./SchemaField1');
var utils = require('../../lib/utils.js');

var PropertyObject = React.createClass({displayName: "PropertyObject",
  propTypes: {
    name: React.PropTypes.string.isRequired,
    value: React.PropTypes.object.isRequired,
    config: React.PropTypes.object.isRequired
  },
  getInitialState: function() {
    return {
      items: this._getDefaults()
    };
  },
  _getDefaults: function() {
    var defaults;
    
    if (!this.props.value) {
      return false;
    }
    if(Object.keys(this.props.value).length) {
      defaults = this.props.value;
      
      // Fix for Klar
      // var properties = this._config().properties;
      // if(properties) {
      //   for (var key in properties) {
      //     var item = properties[key];
      //     if (typeof defaults[key] === 'undefined') {
      //       defaults[key] = item.default_value ?
      //                       item.default_value :
      //                         item.default ?
      //                           item.default : 
      //                           '';
      //       if(item.type === 'boolean') {
      //         if(defaults[key].length === 0) {
      //           defaults[key] = false;
      //         }
      //       }
      //     }
      //   };
      // }
    } else {
      var obj = {};
      var properties = this._config().properties;
      if(properties) {
        for (var key in properties) {
          var item = properties[key];
          obj[key] = item.default_value ?
                     item.default_value :
                       item.default ?
                         item.default : 
                         '';
          if(item.type === 'boolean') {
            if(obj[key].length === 0) {
              obj[key] = false;
            }
          }
        };
      }
      defaults = obj;
    }
    return defaults;
  },
  _config: function() {
    return this.props.config;
  },
  _onChange: function(prop) {
    var newItems = this.state.items;
    // Check slugify
    for(var key in this._config().properties) {
      var item = this._config().properties[key];
      // console.log('proasfd: ', prop.key);
      // console.log('item.field.slugify: ', prop.key);
      if(item.options && item.options.populate_from) {
        if(item.options.populate_from === prop.key) {
          var slugifiedProp = utils.slugify(newItems[prop.key]);
          //alert(newItems[key] + ' : ' + slugifiedProp);
          if(newItems[key].length === 0 || newItems[key] === slugifiedProp) {
            newItems[key] = utils.slugify(prop.value);
          }
          //document.getElementById('heading').style.display = 'none';
        }
      }
    }
    newItems[prop.key] = prop.value;
    this.setState({items: newItems});
    //alert(this.props.children);
    // PropertyItem['symbol'].setState({
    //   value: 'asdf'
    // });
    this._setInputData(this.state.items);
    //this.forceUpdate();
    //this.setProps({items: newItems});
    // React.render(
    //   <PropertyObject name={this.props.name} value={JSON.stringify(newItems)} config={this.props.config} />,
    //   this.props.obj
    // );
    if(this.props.onChange) {
      this.props.onChange(this.state.items, this.props.index);
    } else {
      if (this.props.onFormChange) {
        this.props.onFormChange(this.props.name, this.state.items);
      }
    }
  },
  _removeItem: function() {
    this.props.removeItem(this.props.index);
  },
  _onMouseEnter: function() {
    //this._enableAllInputs();
  },
  _onMouseLeave: function() {
    //this._disableAllInputs();
  },
  componentDidMount: function() {
    if(this.props.config.disabled !== true) {
      var self = this;
      this._setInputData();
      //alert(JSON.stringify(this.props));
      //this._disableAllInputs();
      //$('form').unbind('submit');
      // var submitButton = $('#form-cms');
      // submitButton.bind('click', function() {
      //   self._disableAllInputs();
      // });
      if ($('body.cms').length) {
        alert('File: PropertyObject.js');
        $('form').submit(function() {
          self._disableAllInputs();
        });
      }
    }
  },
  _setInputData: function() {
    if(!this.props.isArray) {
      if(document.getElementById('result-object')) {
        document.getElementById('result-object').innerHTML = JSON.stringify(this.state.items).replace(/{/g, '{<br/>&nbsp;&nbsp;').replace(/}/g, '<br/>}').replace(/":/g, '": ').replace(/,/g, ',<br/>&nbsp;&nbsp;');
      }
      document.getElementById(this.props.name).value = JSON.stringify(this.state.items);
    }
  },
  _disableAllInputs: function() {
    //var PropertyObjectItems = Array.prototype.slice.call(document.querySelectorAll('[data-key="' + this.props.name + '"] [class="form-control"], [data-key="' + this.props.name + '"] [class="form-control--unstyled"]'));
    var PropertyObjectItems = Array.prototype.slice.call(document.querySelectorAll('.js-property-object-items [class="form-control"], .js-property-object-items [class="form-control--unstyled"]'));
    for(var i in PropertyObjectItems) {
      var item = PropertyObjectItems[i];
      item.setAttribute('disabled', 'disabled');
    }
  },
  _enableAllInputs: function() {
    //var PropertyObjectItems = Array.prototype.slice.call(document.querySelectorAll('[data-key="' + this.props.name + '"] [class="form-control"], [data-key="' + this.props.name + '"] [class="form-control--unstyled"]'));
    var PropertyObjectItems = Array.prototype.slice.call(document.querySelectorAll('.js-property-object-items [class="form-control"], .js-property-object-items [class="form-control--unstyled"]'));
    for(var i in PropertyObjectItems) {
      var item = PropertyObjectItems[i];
      item.removeAttribute('disabled');
    }
  },
  _sort: function(e) {
    if(this.props.sort) {
      this.props.sort();
    }
    //var PropertyObjectItems = Array.prototype.slice.call(document.querySelectorAll('[class="form-control"]'));
    //for(var i in PropertyObjectItems) {
    //  var item = PropertyObjectItems[i];
    //  item.removeAttribute('disabled');
    //}
  },
  render: function() {
    var items = [];
    var name = this.props.name;
    var properties = this._config().properties;
    //console.log(this.state.items.heading + ' : ' + this.props.index);
    if(properties) {
      for (var key in properties) {
        var item = properties[key];
        var itemValue = this.state.items[key];
        // console.log(key, this.props.uiSchema)
        var uiSchema = this.props.uiSchema && this.props.uiSchema[key] ? this.props.uiSchema[key] : null;
        var required = this.props.config.required ?
                       (this.props.config.required.indexOf(key) !== -1) :
                       false;
        //itemValue = typeof itemValue === 'object' ? JSON.stringify(itemValue) : itemValue;
        var isNew = this.props.value ?
                    false :
                    true;
        if (item.type === 'array') {
          items.push(
            React.createElement(SchemaField1, {
              key: key, 
              name: this.props.name + '__' + key, 
              value: itemValue, 
              schema: item, 
              uiSchema: uiSchema, 
              isNew: isNew, 
              onFormChange: this.props.onFormChange, 
              initControls: true})
          );
        } else if (item.type === 'object') {
          items.push(
            React.createElement(SchemaField1, {
              key: key, 
              name: this.props.name + '__' + key, 
              value: itemValue, 
              schema: item, 
              uiSchema: uiSchema, 
              isNew: isNew, 
              onFormChange: this.props.onFormChange, 
              initControls: true})
          );
        } else {
          items.push(
            React.createElement(PropertyItem, {
              objectName: this.props.name, 
              key: key, 
              onChange: this._onChange, 
              onFormChange: this.props.onFormChange, 
              name: this.props.name + '__' + key, 
              value: itemValue, 
              config: item, 
              uiSchema: uiSchema, 
              required: required, 
              uniqueArrayKey: this.props.uniqueArrayKey, 
              isArray: this.props.isArray, 
              isNew: isNew})
          );
        }
      };
    }
    var propertyInput = !this.props.isArray ? React.createElement("input", {id: name, name: name, type: "hidden"}) : '';
    var heading = this.props.isArray ? 
    React.createElement("div", {className: "panel-heading"}, 
      React.createElement("div", {className: "tool-box tool-box--left pull-left"}, 
        React.createElement("div", {className: "item move-item", title: "Flytta"}, React.createElement("span", {className: "fa fa-th"}))
      ), 
      
        this._config().display_field && this.state.items && this.state.items[this._config().display_field] ?
          this.state.items[this._config().display_field] :
          this._config().title + ' ' + (this.props.index+1), 
      
      React.createElement("div", {className: "tool-box tool-box--right"}, 
        React.createElement("div", {className: "item toggle-item", title: "Öppna/stäng"}, React.createElement("span", {className: "fa fa-angle-down"})), 
        React.createElement("div", {className: "item remove-item", title: "Ta bort", onMouseUp: this._removeItem}, React.createElement("span", {className: "fa fa-minus"}))
      )
    ) : React.createElement("div", {className: "panel-heading"}, React.createElement("h3", {className: "panel-title"}, this._config().title));
    var result;
    if(this.props.config.disabled === true) {
      result = (React.createElement("div", null));
    } else {
      result = (
        React.createElement("div", {className: 'panel panel-default panel--box', onMouseEnter: this._onMouseEnter, onMouseLeave: this._onMouseLeave, onClick: this._sort}, 
        heading, 
        React.createElement("div", {className: "panel-body-wrapper"}, 
          React.createElement("div", {className: "panel-body js-property-object-items"}, 
            items, 
            propertyInput
          )
        )
      )
    );
    }
    return result;
  }
});

module.exports = PropertyObject;