var React = require('react');
var markdownEditor = require('../../lib/markdownEditor.js');
var aceTexteditor = require('../../lib/aceTexteditor.js');    
var controls = require('../../lib/controls.js');

var PropertyItem = require('../FormObject/PropertyItem');
var PropertyObject = require('../FormObject/PropertyObject');
var PropertyArray = require('../FormObject/PropertyArray');

var SchemaField = React.createClass({displayName: "SchemaField",
  propTypes: {
    itemType: React.PropTypes.object.isRequired,
    item: React.PropTypes.object,
    options: React.PropTypes.object,
    onFormChange: React.PropTypes.func.isRequired,
  },
  
  componentDidMount: function() {
    if ((this.props.options && this.props.options.focusInput !== false)) {
      if (document.querySelector('#c10_form_' + this.props.itemType.id + ' .form-control')) {
        // Fix for item list - setTimeout
        setTimeout(function(){document.querySelector('#c10_form_' + this.props.itemType.id + ' .form-control').select();}.bind(this), 0);
      }
    }
    markdownEditor.init();
    aceTexteditor.init();
    controls.init();
  },

  _getColFields: function(colName) {
    var fields = [];
    var formLayout = this.props.itemType.attributes.options ?
                     this.props.itemType.attributes.options.form.layout :
                     null;
    
    formLayout = formLayout ?
                 formLayout :
                 this.props.itemType.attributes.ui_schema && this.props.itemType.attributes.ui_schema['ui:layout'] ?
                   this.props.itemType.attributes.ui_schema['ui:layout'] :
                   null;
    
    var isNew = this.props.item ? false : true;
    for(var propKey in this.props.itemType.attributes.schema.properties) {
      var field;
      var prop = this.props.itemType.attributes.schema.properties[propKey];
      var value = this.props.item ?
                    prop.type === 'relationship' ?
                                  this.props.item.relationships ?
                                  this.props.item.relationships[propKey] :
                                  '' :
                  this.props.item.attributes[propKey] :
                  '';
      // var value = '';
      // if (this.props.item) {
      //   if (prop.type === 'relationship') {
      //     if (this.props.item.relationships) {
      //       value = this.props.item.relationships[propKey]
      //     }
      //   } else {
      //     value = this.props.item.attributes[propKey]
      //   }
      // }
      value = typeof value !== 'undefined' ?
              value :
              '';
      var uiSchema = this.props.itemType.attributes.ui_schema && this.props.itemType.attributes.ui_schema[propKey] ?
                     this.props.itemType.attributes.ui_schema[propKey] :
                     null;
      var required = this.props.itemType.attributes.schema.required ?
                     (this.props.itemType.attributes.schema.required.indexOf(propKey) !== -1) :
                     false;
      
      window.currentItemExtension.attributes[propKey] = {
        id: propKey,
        value: value,
        schema: prop
      };
      if (!formLayout) {
        field = (
          React.createElement(GetField, {key: propKey, type: prop.type, propKey: propKey, prop: prop, value: value, uiSchema: uiSchema, required: required, isNew: isNew, onFormChange: this.props.onFormChange})
        );
        fields.push(field);
      } else if (!colName && formLayout.sidebar && !~formLayout.sidebar.indexOf(propKey)) {
        field = (
          React.createElement(GetField, {key: propKey, type: prop.type, propKey: propKey, prop: prop, value: value, uiSchema: uiSchema, required: required, isNew: isNew, onFormChange: this.props.onFormChange})
        );
        fields.push(field);
      } else if (formLayout[colName] && ~formLayout[colName].indexOf(propKey)) {
        if (colName === 'sidebar') {
          field = (
            React.createElement(GetField, {key: propKey, type: prop.type, propKey: propKey, prop: prop, value: value, uiSchema: uiSchema, required: required, isNew: isNew, onFormChange: this.props.onFormChange})
          );
        }
        if (colName === 'wide') {
          field = ( 
            React.createElement("div", {key: propKey, className: "col-md-12"}, 
              React.createElement(GetField, {type: prop.type, propKey: propKey, prop: prop, value: value, uiSchema: uiSchema, required: required, isNew: isNew, onFormChange: this.props.onFormChange})
            )
          );
        }
        if (colName === 'in-halves') {
          field = ( 
            React.createElement("div", {key: propKey, className: "col-md-6"}, 
              React.createElement(GetField, {type: prop.type, propKey: propKey, prop: prop, value: value, uiSchema: uiSchema, required: required, isNew: isNew, onFormChange: this.props.onFormChange})
            )
          );
        }
        if (colName === 'in-thirds') {
          field = (
            React.createElement("div", {key: propKey, className: "col-md-4"}, 
              React.createElement(GetField, {type: prop.type, propKey: propKey, prop: prop, value: value, uiSchema: uiSchema, required: required, isNew: isNew, onFormChange: this.props.onFormChange})
            )
          );
        }
        if (colName === 'in-fourths') {
          field = (
            React.createElement("div", {key: propKey, className: "col-md-3"}, 
              React.createElement(GetField, {type: prop.type, propKey: propKey, prop: prop, value: value, uiSchema: uiSchema, required: required, isNew: isNew, onFormChange: this.props.onFormChange})
            )
          );
        }
        fields.push(field);
      }
    }
    return fields;
  },
  
  render: function() {
    var itemType = this.props.itemType;
    if (this.props.options && this.props.options.hideFields) {
      for (var propKey in itemType.attributes.schema.properties) {
        var prop = itemType.attributes.schema.properties[propKey];
        if (~this.props.options.hideFields.indexOf(propKey)) {
          prop.hide = true;
        }
      }
    }
    var grid = [];
    
    if
      (
        itemType.attributes.options && itemType.attributes.options.form && itemType.attributes.options.form.layout ||
        itemType.attributes.ui_schema && itemType.attributes.ui_schema['ui:layout']
      ) {
      var formLayout
      if (itemType.attributes.ui_schema && itemType.attributes.ui_schema['ui:layout']) {
        formLayout = itemType.attributes.ui_schema['ui:layout'];
      } else {
        formLayout = itemType.attributes.options.form.layout;
      }
      if (formLayout.sidebar) {
        grid = (
          React.createElement("div", {key: rowKey, id: "properties-row", className: "row"}, 
            React.createElement("div", {className: "col-md-8"}, this._getColFields()), 
            React.createElement("div", {className: "col-md-4"}, this._getColFields('sidebar'))
          )    
        );
      } else {
        for(var rowKey in formLayout) {
          var r = (
            React.createElement("div", {key: rowKey, className: "row"}, 
              this._getColFields(rowKey)
            )    
          );
          grid.push(r);
        }
      }
    } else {
      var r = (
        React.createElement("div", {key: "fields", id: "properties-row"}, 
          this._getColFields()
        )
      );
      grid.push(r);
    }
    
    return (
      React.createElement("div", null, 
        grid
      )
    );
  }
});

var GetField = function(props) {
  var type = props.type;
  var propKey = props.propKey;
  var prop = props.prop;
  var uiSchema = props.uiSchema;
  var required = props.required;
  var value = props.value;
  var isNew = props.isNew;

  var formField;
  if (type === 'array' && !(prop.uniqueItems && prop.items && prop.items.enum) && !prop.extension) {
    value = value || [];
    formField = React.createElement(PropertyArray, {name: propKey, value: value, config: prop, uiSchema: uiSchema, required: required, isNew: isNew, onFormChange: props.onFormChange});
  } else if (type === 'object' && !prop.extension) {
    value = value || {};
    formField = React.createElement(PropertyObject, {name: propKey, value: value, config: prop, uiSchema: uiSchema, required: required, isNew: isNew, onFormChange: props.onFormChange});
  } else {
    formField = React.createElement(PropertyItem, {name: propKey, value: value, config: prop, uiSchema: uiSchema, required: required, uniqueArrayKey: (new Date()).getTime(), isNew: isNew, onFormChange: props.onFormChange});
  }

  return (
    React.createElement("div", null, 
      formField
    )
  );
};

module.exports = SchemaField;

