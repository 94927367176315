var aceTexteditor = require('./aceTexteditor.js');
var markdownEditor = require('./markdownEditor.js');
var controls = require('./controls.js');
// var listView = require('./listView.js');

var formView = {
  init: function() {
    if($('#react-form').length) {
      if(~window.location.toString().indexOf('=new') || $('#react-form input[name="action"]').length === 0) {
        // Don't set focus if it is a new nav menu item
        if (!$('#nav-menu-item-display-name-input').length) {
          $('#react-form input.form-control')[0].focus();
        }
      } else {
        $('#react-form input.form-control')[0].select();  
      }
      function setActiveClass(item) {
        $('.nav-tabs--form a').parent().removeClass('active');
        item.parent().addClass('active');
      }
      function hideProperties() {
        $('.js-tab-content [data-key]').css('display', 'none');
        //$('.js-tab-content [data-key]').fadeOut();
      }
      $('.nav-tabs--form a').click(function() {
        var item = $(this);
        var properties = item.data('properties');
        if (properties) {
          var propertiesSplit = properties.toString().split(',');
          setActiveClass(item);
          hideProperties();
          for (var i = 0; i < propertiesSplit.length; i++) {
            var propContainer = $('.js-tab-content [data-key="' + propertiesSplit[i] + '"]');
            propContainer.css('display', 'block');
            //propContainer.fadeIn();
          }
        }
      });
      if($('.nav-tabs--form a').length) {
        $('.nav-tabs--form a').first().click();
      }
    }
    this.setSubmitEvents();
  },
  setSubmitEvents: function() {
    // Don't use ajax on pages just yet
    if (~location.toString().indexOf('nav-menu-item')) {
      return;
    }
    // Don't use ajax in dialog just yet
    // if (~location.toString().indexOf('is_link=true')) {
    //   return;
    // }
    // if (!~location.toString().indexOf('/items/')) {
    //   return;
    // }
    var self = this;
    if (~location.toString().indexOf('=list_form')) {
      if (typeof topperText !== 'undefined') {
        if ($('#topper').length) {
          $('#topper').text(topperText);
        } else {
          $('.topper h1').text(topperText);  
        }
      }
    }
    markdownEditor.init();
    aceTexteditor.init();
    // controls.init();
    var submitButton = $('#form-cms');
    submitButton.unbind();
    var win = window;
    submitButton.click(function() {
      // Run ajax mode if we are handling items otherwise just validate the form before sending it
      if (!~location.toString().indexOf('/items/') || ~location.toString().indexOf('is_link=true')) {
        $('.relationship-object').find('input').prop('disabled', true); 
        controls.validateForm();
        return;
      }
      self.disableAllObjectInputs();
      win.$('#item-saved-successfully').removeClass('show');
      win.$('#item-saved-successfully').removeClass('hide');
      controls.validateForm();
      if ($('#form-cms').attr('disabled')) return;
      var data = win.$('.update-panel').find('form').serialize();
      data = data + '&ajax=true';
      $.ajax({
        type: 'POST',
        url: win.$('.update-panel').find('form').attr('action'),
        data: data,
        cache: false,
        success: function(response) {
          win.$('.update-panel').find('.alert-danger').remove();
          win.$('.update-panel').find('.alert-success').remove();
          var str;
          var isError = false;
          if(response.status !== 200) {
            isError = true;
            str = '<div class="alert alert-danger alert-dismissable fade in" role="alert">';
            str += '<button class="close" type="button" data-dismiss="alert" aria-label="close">';
            str += '<span aria-label-hidden="true">×</span>';
            str += '</button>';
            str += '<h4 style="font-size: 20px;margin-bottom: 10px;">Felmeddelande!</h4>';
            if (response.error.validation) {
              for (var i=0; i < response.error.validation.validations.length; i++) {
                var validation = response.error.validation.validations[i];
                str += '<div style="margin-bottom: 5px;">';
                if (validation.property_title) {
                  str += '<strong>';
                  str += validation.property_title;
                  str += '</strong>';  
                }
                str += '<br />';
                str += validation.messages[0];
                str += '</div>';
              }
            }
            //str += $(response).find('.alert-danger').html();
            str += '</div>';
          } else {
            // str = '<div class="alert alert-success alert-dismissable fade in" role="alert">';
            // str += '<button class="close" type="button" data-dismiss="alert" aria-label="close">';  
            // str += '<span aria-label-hidden="true">×</span>';  
            // str += '</button>';
            // str += '<h4>Lyckad uppdatering!</h4>';  
            // str += 'Formuläret sparades utan problem';
            // // str += $(response).find('.alert-success').html();
            // str += '</div>';
            win.$('#item-saved-successfully').addClass('show');
            setTimeout(function(){win.$('#item-saved-successfully').addClass('hide');}, 1500);  
          }
          win.$('.update-panel').prepend(str);
          $('#pre-loader').css('visibility', 'hidden');
          submitButton.button('reset');
          if($(response).find('#validation-errors').length) {
            controls.validateForm();
            $('#pre-loader').css('visibility', 'hidden');
          }
          if (!response.created_id) {
            self.enableAllObjectInputs();
          }
          if (response.created_id) {
            // load edit item page 
            if (response.edit_url) {
              // alert(JSON.stringify(response));
              // Reload list instead
              // self._updatePage(response.edit_url, response);
              // alert(response.edit_url.substring(response.edit_url.indexOf('/edit/'), response.edit_url.length));
              localStorage.setItem(window.currentBucketId + '-' + window.currentItemType + '-item-url', response.edit_url.substring(response.edit_url.indexOf('/edit/'), response.edit_url.length));
              var listView = require('./listView.js');
              listView.reloadList();
              // location.reload();
            }
            // return;
          }
          parent.frames.postMessage({'action': 'reload_template'}, '*');
          if (~location.toString().indexOf('is_link=true')) {
            parent.frames.postMessage({'action': 'close_dialog_window'}, '*');
            // $('[data-dismiss="modal"]').click();
          }
          //alert(window.currentNavMenuItemId)
          //win.$('.update-panel').parent().next().find('.js-inline-dialog-cancel').click();
        }
      });
      return false;
    });
    $('.update-panel').find('input').each(function(index) {
      $(this).keydown(function() {
        if (event.keyCode == 13) {
          submitButton.click();
          return false;
        }
      });  
    });
  },
  disableAllObjectInputs: function() {
    //var PropertyObjectItems = Array.prototype.slice.call(document.querySelectorAll('[data-key="' + this.props.name + '"] [class="form-control"], [data-key="' + this.props.name + '"] [class="form-control--unstyled"]'));
    var PropertyObjectItems = Array.prototype.slice.call(document.querySelectorAll('.js-property-object-items [class="form-control"], .js-property-object-items [class="form-control--unstyled"]'));
    for(var i in PropertyObjectItems) {
      var item = PropertyObjectItems[i];
      item.setAttribute('disabled', 'disabled');
    }
    $('.relationship-object').find('input').prop('disabled', true);
  },
  enableAllObjectInputs: function() {
    //var PropertyObjectItems = Array.prototype.slice.call(document.querySelectorAll('[data-key="' + this.props.name + '"] [class="form-control"], [data-key="' + this.props.name + '"] [class="form-control--unstyled"]'));
    var PropertyObjectItems = Array.prototype.slice.call(document.querySelectorAll('.js-property-object-items [class="form-control"], .js-property-object-items [class="form-control--unstyled"]'));
    for(var i in PropertyObjectItems) {
      var item = PropertyObjectItems[i];
      item.removeAttribute('disabled');
    }
    $('.relationship-object').find('input').prop('disabled', false);
  }
}

module.exports = formView;