var React = require('react');
var utils = require('../../lib/utils.js');
var validate2 = require('../../lib/validate-2.js');

var FormActions = function(props) {
  var itemType = props.itemType;
  var item = props.item;
  var options = props.options;
  var endPointName = itemType.type === 'item-types' ? 'items' : itemType.type;
  var saveItem = function(e) {
    C10.edit.resetErrors();
    var isValid = C10.edit.validateForm('#c10_form_' + itemType.id);
    
    var data = utils.getRequestData(itemType, item, options);
    data = utils.setCorrectTypes(data, itemType);

    var validation = validate2.form(data, itemType);
    
    if (!validation.isValid) {
      C10.edit.renderErrors('.js-form-error', validation);
      C10.edit.renderErrors('.form-actions-divider', validation);
    }

    if (options && options.connectWith) {
      isValid = C10.edit.validateForm('#c10_form_' + options.connectWith);
    }
    if (!isValid) {
      console.log('Form validation failed!!!');
      e.preventDefault();
      return;
    }
    var onItemSaved = function(data, isNew) {
      utils.clearContext();
      if (options.showLoader !== false) {
        utils.hideLoader();
      }
      if (options && options.relationship) {
        utils.getContext(function(err, data1) {
          if (err) {
            console.log(err.message);
          } else {
            var itemId = options.relationship;
            var item = itemId ?
                       window.cmsContext.items.data.find(function(item) {return item.id === itemId;}) :
                       null;
            var doubleData = {};
            doubleData[data.data.meta.item_type.data.id] = data;
            doubleData[item.meta.item_type.data.id] = item;
            if (isNew) {
              if (options.onCreate) {
                options.onCreate(null, doubleData);
              }  
            } else {
              if (options.onUpdate) {
                options.onUpdate(null, doubleData);
              }
            }    
          }
        }.bind(this));     
      } else {
        if (isNew) {
          if (options.onCreate) {
            options.onCreate(null, data);
          }  
        } else {
          if (options.onUpdate) {
            options.onUpdate(null, data);
          }
        }
      }
      
      // if (options.titleSelector && data.data.attributes.title) {
      //   document.querySelector(options.titleSelector).innerHTML = data.data.attributes.title;
      // }
    }
    var onItemError = function(err, isNew) {
      // console.log('Error from FormActions.js: ', err); 
      // Show the errors
      C10.edit.renderErrors('#c10_form_' + itemType.id, err);
      // $('#c10_form_' + itemType.id + ' .form-group').each(function() {
      //   for(var i=0;i<err.errors.length;i++) {
      //     var error = err.errors[i];
      //     if (error.source && error.source.pointer) {
      //       var fieldName = error.source.pointer.substring(error.source.pointer.lastIndexOf('/')+1);
      //       fieldName = fieldName.replace(']', '------');
      //       fieldName = fieldName.replace('[', '----');
      //       fieldName = fieldName.replace('.', '__');
      //       if ($(this).find('[name="'+fieldName+'"]').length) {
      //         $(this).addClass('has-error');
      //         $(this).find('.js-error-message-text').text(error.detailed);
      //       }
      //     }
      //   }
      // });
      
      if (options.showLoader !== false) {
        utils.hideLoader();
      }
      if (isNew) {
        if (options.onCreate) {
          options.onCreate(err);
        }  
      } else {
        if (options.onUpdate) {
          options.onUpdate(err);
        }
      }
    }
    if (options.showLoader !== false) {
      // $('#c10_form_' + itemType.id).css('position', 'relative');
      utils.showLoader({text: 'Sparar...', elector: '#c10_form_' + itemType.id});
    }
    // data = utils.setCorrectTypes(data, itemType);
    if (options && (options.overrideSubmit || options.onSubmit)) {
      if (options && options.connectWith) {
        var doubleData = {};
        doubleData[itemType.id] = utils.getRequestData(itemType, item, options);
        doubleData[itemType.id] = utils.setCorrectTypes(doubleData[itemType.id], itemType);
        utils.getContext(function(err, data) {
          if (err) {
            console.log(err.message);
          } else {
            var itemType1 = window.cmsContext.itemTypes.data.find(function(itemType) {
              return itemType.id === options.connectWith;
            });
            doubleData[options.connectWith] = utils.getRequestData(itemType1, null, options);
            doubleData[options.connectWith] = utils.setCorrectTypes(doubleData[options.connectWith], itemType1);
            options.overrideSubmit(doubleData);
          }
        });
      } else {
        var done = function(err, data) {
          if (err) {
            onItemError(err);
          } else {
            onItemSaved(data);
          }
        }
        if (options.overrideSubmit) {
          options.overrideSubmit(data, done);
        } else if (options.onSubmit) {
          options.onSubmit(data, done);
        }
      }
      e.preventDefault();
      return false;
    }
    if (options.onBeforeSubmit) {
      options.onBeforeSubmit(null, data);
    }
    $('.js-my-custom-loader').css('display', 'block');
    if (item) {
      cmsClient.put(endPointName + '/' + item.id, {data: data})
        .then(function(data) {
          // console.log(data);
          onItemSaved(data);
        })
        .catch(function(err) {
          onItemError(err);
        });
    } else {
      cmsClient.post(endPointName, {data: data})
        .then(function(data) {
          onItemSaved(data, true);
        })
        .catch(function(err) {
          onItemError(err, true);
        });
    }
    e.preventDefault();
  };

  var deleteItem = function(e) {
    var btn = $(e.target);
    btn.button('loading');
    if (options && options.overrideDelete) {
      if (options && options.relationship) {
        utils.getContext(function(err, data1) {
          if (err) {
            console.log(err.message);
          } else {
            var itemId = options.relationship;
            var item1 = itemId ?
                       window.cmsContext.items.data.find(function(item) {return item.id === itemId;}) :
                       null;
            var doubleData = {};
            doubleData[item.meta.item_type.data.id] = item;
            doubleData[item1.meta.item_type.data.id] = item1;
            options.overrideDelete(doubleData);
          }
        }.bind(this));       
      } else {
        options.overrideDelete(item);
      }
      e.preventDefault();
      return false;
    }
    // utils.showLoader({text: 'Sparar...', selector: '#modal-example .modal-body'});
    cmsClient.delete(endPointName + '/' + item.id)
      .then(function(data) {
        utils.clearContext();
        // utils.hideLoader();
        btn.button('reset');
        $('#modal-example-'+item.id).modal('hide');
        // if (itemTypeId === 'nav-menu-item') {
        //   $('#modal-example').removeAttr('style');
        //   $('#modal-example').find('.modal-dialog').removeAttr('style');
        //   $('.modal-backdrop').removeAttr('style');
        // }
        if (options.onDelete) {
          options.onDelete(null, data);
        }
      })
      .catch(function(err) {
        // console.log(err);
        // utils.hideLoader();
        btn.button('reset');
        if (options.onDelete) {
          options.onDelete(err);
        }
      });
  };

  var deleteItemShowModal = function(e) {
    // Fix for modal in position: fixes context.
    $('body').append($('#modal-example-'+item.id));
    $('#modal-example-'+item.id).modal('show');
    // if (itemTypeId === 'nav-menu-item') {
    //   $('#modal-example').css('position', 'absolute');
    //   $('#modal-example').find('.modal-dialog').css('width', 'auto');
    //   $('#modal-example').find('.modal-dialog').css('margin', '10px');
    //   // $('.modal-backdrop').css('z-index', '999');
    //   $('.modal-backdrop').css('display', 'none');
    // }
  };

  return (
    React.createElement("div", {className: "clearfix form-actions-wrapper"}, 
      React.createElement("div", {className: "form-actions pull-right"}, 
        React.createElement("select", {id: "__status", name: "__status", className: "form-control", defaultValue: item ? (item.meta && item.meta.status) : 'published'}, 
          React.createElement("option", {value: "published"}, "Publicera"), 
          React.createElement("option", {value: "unpublished"}, "Avpublicera")
        ), 
        
          // <button class='btn btn-default' type='button'>Avbryt</button>
        
         item && React.createElement("button", {className: "btn btn-default", onClick: deleteItemShowModal, type: "button"}, "Ta bort"), 
        React.createElement("button", {className: "btn btn-primary", "data-loading-text": "Spara", onMouseDown: function(){window._c10CurrentActiveElement_do_not_set_this_for_now=document.activeElement}, onClick: saveItem, type: "submit"}, "Spara")
      ), 
      
        item && (
          React.createElement("div", {id: 'modal-example-' + item.id, className: "modal modal-default fade"}, 
            React.createElement("div", {className: "modal-dialog"}, 
              React.createElement("div", {className: "modal-content"}, 
                React.createElement("div", {className: "modal-header"}, 
                  React.createElement("h4", {className: "modal-title"}, "Ta bort ", itemType.attributes.labels ? itemType.attributes.labels.singular_name : itemType.attributes.title.toLowerCase())
                ), 
                React.createElement("div", {className: "modal-body"}, 
                  "Är du säker på att du vill ta bort ", 
                    itemType.attributes.labels && itemType.attributes.labels.definite_form_name ?
                      itemType.attributes.labels.definite_form_name :
                        itemType.attributes.labels ?
                        itemType.attributes.labels.singular_name :
                        itemType.attributes.title.toLowerCase(), 
                  "?"
                ), 
                React.createElement("div", {className: "modal-footer"}, 
                  React.createElement("button", {type: "button", className: "btn btn-default", "data-dismiss": "modal"}, "Avbryt"), 
                  React.createElement("button", {type: "button", className: "btn btn-primary", "data-loading-text": 'Tar bort ' + (itemType.attributes.labels && itemType.attributes.labels.definite_form_name ?
                      itemType.attributes.labels.definite_form_name :
                        itemType.attributes.labels ?
                        itemType.attributes.labels.singular_name :
                        itemType.attributes.title.toLowerCase()), onClick: deleteItem}, "Ok")
                )
              )
            )
          )
        )
      
    )
  );
};

FormActions.propTypes = {
  itemType: React.PropTypes.object.isRequired,
  item: React.PropTypes.object
};

module.exports = FormActions;