var React = require('react');
var ReactPropTypes = React.PropTypes;
// var bootstrapSwitch = require('bootstrap-switch');
var Switchery = require('switchery-npm');

var Switch = React.createClass({displayName: "Switch",
  componentDidMount: function () {
    this.props.hideLabel();
    // this.props.showField();
    var el = document.querySelector('#' + this.props.id + '-ui-widget--switch');
    var init = new Switchery(el, {
      size: 'small',
      // color: '#64bd63',
      // color: '#005da0'
      // className: 'switchery',
      // disabled : false,
      speed    : '0.4s'
    });
    // Remove transition on first render
    el.nextSibling.style.transition = 'none';
    el.addEventListener('change', function() {
      this.setValue(el.checked);
    }.bind(this));
    // $('#' + this.props.id + '-ui-widget--switch').bootstrapSwitch({
    //   size: 'mini',
    //   onColor: 'success',
    //   onInit: function (e, state) {

    //   },
    //   onSwitchChange: function (e, state) {
    //     this.setValue(state);
    //   }.bind(this)
    // });
  },
  setValue: function (value) {
    this.props.setValue(value);
  },
  // onClick: function (e) {
  //   var value = e.target.checked ?
  //               true :
  //               false;
  //   this.props.setValue(value);
  // },
  render: function() {
    return (
      React.createElement("div", {className: "clearfix"}, 
        React.createElement("label", {style: { margin: '0px 0 0 0'}}, 
           this.props.schema.title
        ), 
        React.createElement("div", {className: "pull-right"}, 
          React.createElement("input", {id: this.props.id + '-ui-widget--switch', type: "checkbox", defaultChecked:  this.props.value, value: this.props.value})
        )
      )
    );
  }
});

Switch.propTypes = {
  id: React.PropTypes.string.isRequired,
  name: React.PropTypes.string.isRequired,
  value: React.PropTypes.array,
  schema: React.PropTypes.object.isRequired,
  setValue: React.PropTypes.func.isRequired,
  setInvalid: React.PropTypes.func.isRequired,
  validate: React.PropTypes.func.isRequired,
  showField: React.PropTypes.func.isRequired,
  hideLabel: React.PropTypes.func.isRequired
};

module.exports = Switch;