var React = require('react');
var ReactPropTypes = React.PropTypes;
var request = require('superagent');

var AccessToken = React.createClass({displayName: "AccessToken",
  propTypes: {
    id: React.PropTypes.string.isRequired,
    name: React.PropTypes.string.isRequired,
    value: React.PropTypes.string,
    config: React.PropTypes.object.isRequired
  },
  componentDidMount: function() {
    var propField = document.getElementById(this.props.id);
    //document.getElementById(this.props.id).type = 'hidden';
  },
  _deleteAccessToken: function(event) {
    var item = event.target;
    var accessToken = $(item).parent().data('token-id').toString();
    var model = this._getModel().filter(function(at) {
      return at !== accessToken;
    });
    this._update(model);
  },
  _onChange: function() {
    var model = this._getModel();
    var endpoint = '/api/utils'
    var query = {
      action: 'generate_access_token'
    };
    request
      .get(endpoint)
      .query(query)
      .set('Accept', 'application/json')
      .end(function(err, res) {
        if(err)  {
          alert(err);
        } else {
          model[model.length] = res.body.data.access_token;
          this._update(model);
        }
      }.bind(this));
  },
  _update: function(model) {
    var propField = document.getElementById(this.props.id);
    propField.value = JSON.stringify(model);
    propField.click();
  },
  _getModel: function() {
    var propValue = this.props.value;
    var model = 
        propValue ?
        JSON.parse(propValue) :
        [];
    return model;
  },
  render: function() {
    var accessTokensView = [];
    var model = this._getModel();
    model.map(function(accessToken, index) {
      var uniqueKey = Math.random();
      accessTokensView.push(
        React.createElement("li", {key: uniqueKey, className: "list-group-item"}, 
          accessToken, 
          React.createElement("div", {className: "pull-right"}, 
            React.createElement("a", {title: "Ta bort", onClick: this._deleteAccessToken, "data-token-id": accessToken, style: {cursor: 'pointer', fontSize: '16px', isplay: 'block', argin: '-1px 0 0 5px', color: '#a94442'}}, 
              React.createElement("span", {className: "fa fa-remove"})
            ), 
            React.createElement("a", {title: "Ändra", style: {fontSize: '18px', display: 'none'}}, 
              React.createElement("span", {className: "fa fa-edit"})
            )
          )
        )
      );
    }.bind(this));
    return (
      React.createElement("div", {className: "access-token-view"}, 
        React.createElement("button", {className: "btn btn-success", type: "button", onClick: this._onChange, title: "Lägg till"}, "Generera ny access token"), 
        React.createElement("ul", {className: "list-group clearfix", style: {marginTop: '15px'}}, 
          accessTokensView
        )
      )
    );
  }
});
 
module.exports = AccessToken;

