var React = require('react');
var ReactPropTypes = React.PropTypes;
var PageItem = require('./PageItem');

var PageItems = function(props) {
  var pageItems = [];
  var navMenu = props.navMenu;
  var _getItemTypes = function(event) {
    return props.itemTypes;
  };
  _reloadItemsFromServer = function(event) {
    props.reloadPageItems(null, true);
  };
  var itemHasChildren = function(id) {
    var hasChildren = false;
    for (var i=0; i<navMenu.length;i++) {
      var item = navMenu[i];
      if (item.meta.parent_id === id) {
        hasChildren = true;
        break;
      }
    }
    return hasChildren;
  }
  if(navMenu) {
    var getMenu = function(items, parentId) {
      var firstChild = true;
      var pI = [];
      var prevElId = '';
      items.map(function(navMenuItem, index) {
        var uniqueKey = Math.random();
        var children;
        if (navMenuItem.meta.parent_id === parentId) {
          if (firstChild) {
            prevElId = '';
          } else {
            prevElId = navMenuItem.id || '';  
          }
          firstChild = false;
          if (itemHasChildren(navMenuItem.id)) {
            // console.log(navMenuItem.id)
            // console.log(getMenu(items, navMenuItem.id));
            // Array.apply(getMenu(items, navMenuItem.id), pI);
            children = (
              React.createElement("ul", null, 
                getMenu(items, navMenuItem.id)
              )
            );
            // pI.push(foo);
            // pI.push(getMenu(items, navMenuItem.id));
          } else {
            children = (
              React.createElement("ul", {style: {minHeight: '0px', ackground: '#222'}})
            );
          }
          pI.push(
            React.createElement(PageItem, {key: uniqueKey + navMenuItem.__meta.id, prevElId: prevElId, navMenuItem: navMenuItem, reloadPageItems: props.reloadPageItems, addPageItem: props.addPageItem, getItemTypes: _getItemTypes}, 
              children
            )

          );
        }

        // if (parentId !== '') {}
        // if (navMenuItem.meta.parent_id === parentId) {
        //   pI.push(
        //     <PageItem key={uniqueKey + navMenuItem.__meta.id} prevElId={prevElId} navMenuItem={navMenuItem} reloadPageItems={props.reloadPageItems} addPageItem={props.addPageItem} getItemTypes={_getItemTypes}/>
        //   );
        // } else if (navMenuItem.meta.parent_id !== parentId && navMenuItem.meta.parent_id !== '') {
        //   // pI.push(
        //   //   <PageItem key={uniqueKey + navMenuItem.__meta.id} prevElId={prevElId} navMenuItem={navMenuItem} reloadPageItems={props.reloadPageItems} addPageItem={props.addPageItem} getItemTypes={_getItemTypes}/>
        //   // );
        //   console.log(getMenu(items, navMenuItem.meta.parent_id))
        //   pI.concat(getMenu(items, navMenuItem.meta.parent_id));
        // }
        // if (navMenuItem.id && navMenuItem.meta.parent_id !== prevElId) {
        //   prevElId = '';
        // } else {
        //   prevElId = navMenuItem.id || '';  
        // }
        // firstChild = false;
      });
      return pI;
    }
    pageItems = getMenu(navMenu, '');
    // pageItems.map(function(nM, index) {
    //   console.log(nM.props.prevElId);
    // });
  }
  return (
    React.createElement("div", null, 
      React.createElement("ul", {className: "nav nav--menu-navigation"}, 
        pageItems
      ), 
      React.createElement("div", {id: "nav-items-reload-items-from-server", onClick: _reloadItemsFromServer, style: {display: 'none'}})
    )
  );
};

module.exports = PageItems;