var React = require('react');
var ReactPropTypes = React.PropTypes;

var File = React.createClass({displayName: "File",
  getInitialState: function() {
    return {
      value: this.props.value || ''
    };
  },
  componentDidMount: function() {
    // this.props.showField();
    // this.props.setValue(this.state.value);
  },
  setValue : function(val) {
    this.setState({
      value: val
    });
    this.props.setValue(val);
  },
  onChange: function(e) {
    this.setValue(e.target.value);
  },
  openDialog: function(e) {
    window.currentFileCallback = function (url) {
      this.setValue(url);
    }.bind(this);
    $('.js-iframe-media').attr('src', '/media');
    $('#modal-media').modal('show');
    $('#modal-media').on('hidden.bs.modal', function() {
      $('#modal-media').find('.modal-body .js-iframe-media').prop('src', 'about:blank');
      // $($('#clientframe').contents()[0].documentElement).find('[data-field-string-file]').popover('destroy');
    });
  },
  onBlur: function(e) {
    this.setValue(e.target.value);
    this.props.validate();
  },
  render: function() {
    return (
      React.createElement("div", null, 
        React.createElement("div", {className: "input-group"}, 
          React.createElement("input", {
            type: "text", 
            onChange: this.onChange, 
            onBlur: this.onBlur, 
            value: this.state.value, 
            className: "form-control"}), 
          React.createElement("span", {className: "input-group-btn"}, 
            React.createElement("button", {onClick: this.openDialog, className: "btn btn-default", type: "button"}, "Välj fil")
          )
        ), 
        React.createElement("a", {href: "javascript:void(0);", title: "Byt fil", onClick: this.openDialog, className: "thumbnail", style:  {padding: '2px', borderRadius: '0', margin: '2px 0 0 0', minHeight: '120px', textAlign: 'center'} }, 
          React.createElement("img", {src: this.state.value, style:  {maxWidth: '100%'} })
        )
      )
    );
  }
});

File.propTypes = {
  id: React.PropTypes.string.isRequired,
  name: React.PropTypes.string.isRequired,
  value: React.PropTypes.array,
  schema: React.PropTypes.object.isRequired,
  setValue: React.PropTypes.func.isRequired,
  setInvalid: React.PropTypes.func.isRequired,
  validate: React.PropTypes.func.isRequired,
  showField: React.PropTypes.func.isRequired
};

module.exports = File;