var request = require('superagent');
var Mprogress = require('./progress.js');
// var mprogress = new Mprogress();
var mprogressParent;
try {
  mprogressParent = parent.frames['app'] ?
    '.cms_main--app > div' :
    'body';
}
catch (err) {
  mprogressParent = 'body';
}
 
var mprogress = new Mprogress({parent: mprogressParent});

var reload_template_counts = 0;

window.extensionSendDialogData = function (iframeId, id, method, res, errors) {
  frames[iframeId].postMessage({
    id: id,
    method: method,
    data: {
      res: res,
      errors: errors
    }
  }, '*');
}

var extensionSendBucketData = function (iframeId, id, method, res, errors) {
  frames[iframeId].postMessage({
    id: id,
    method: method,
    data: {
      res: res,
      errors: errors
    }
  }, '*');
}

var postMessage = {
  init: function() {
    // window.cmsReceiveMessage = function(e) {
    //   // if (e.origin !== "http://s.codepen.io") {
    //   //   return;
    //   // }
    //   alert('Origin: ' + e.origin);
    //   alert('Data: ' + e.data);
    // }
    // window.addEventListener('message', window.cmsReceiveMessage);
    setPostMessageEventListener = function() {
      var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
      var eventer = window[eventMethod];
      var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';

      // Listen to message from child window
      eventer(messageEvent, function(e) {
        var data = e.data;
        // console.log('Origin: ' + e.origin);
        // console.log('Data: ' + e.data.action);
        // For template editing
        
        if (data.action === 'extension_update_height') {
          var ifr = document.getElementById(data.id);
          if (ifr) {
            ifr.style.height = data.height + 'px';
          }
        } else if (data.methodName === 'extension_init') {
          // Fix later
          // console.log('gaga')
          // console.log(window.name);
          // console.log(window);
          if (!data.iframeId) {
            console.log('No extension specified!');
            console.log(data);
            return;
          }
          var fieldInput = $('#' + data.params.field_id);
          if (fieldInput.length) {
            fieldInput.data('post-message', {
              id: data.id,
              method: data.methodName,
              initExtension: true,
              options: data.params.options
            });
            fieldInput.click();
          } else {
            // Try to init item type extension
            frames['extension_iframe_' + data.params.field_id].postMessage({
              init_extension: true,
              id: data.id,
              method: data.methodName,
              data: {
                item_type: window.currentItemType,
                item: {}
              }
            }, '*');
          }
        } else if (data.methodName === 'extension_field_set_value') {
          // console.log(data)
          var __setValue = function(val) {
            var message;
            val = val || undefined;
            if (data.params.type === 'array' && !(val instanceof Array)) {
              message = 'The value is not of type array';
            } else if (data.params.type === 'object' && typeof val !== 'object') {
              message = 'The value is not of type object';
            } else if (data.params.type === 'string' && (typeof val !== 'string' && typeof val !== 'undefined')) {
              message = 'The value is not of type string';
            }
            if (message) {
              console.log(message);
              return;
            }
            if (typeof val === 'object') {
              val = JSON.stringify(val);
            }
            var fieldInput = $('#' + data.params.field_id);
            fieldInput.val(val);
            fieldInput.data('post-message', {
              id: data.id,
              method: data.methodName
            });
            fieldInput.click();
          }
          __setValue(data.params.value);
          // var fieldInput = $('#' + data.params.field_id);
          // fieldInput.val(data.params.value);
          // fieldInput.data('post-message', {
          //   id: data.id,
          //   method: data.methodName
          // });
          // fieldInput.click();
          // var v = $('#' + data.params.field_id).val();
          // frames[data.iframeId].postMessage({
          //   id: data.id,
          //   result: 'Field was updated',
          //   value: v
          // }, '*');
        } else if (data.methodName === 'extension_item_attributes_set_value') {
          // console.log(data)
          // var __setValue = function(val) {
          //   var message;
          //   val = val || undefined;
          //   if (data.type === 'array' && !(val instanceof Array)) {
          //     message = 'The value is not of type array';
          //   } else if (data.type === 'object' && typeof val !== 'object') {
          //     message = 'The value is not of type object';
          //   }
          //   if (message) {
          //     alert(message);
          //     return;
          //   }
          //   if (typeof val === 'object') {
          //     val = JSON.stringify(val);
          //   }
          //   var fieldInput = $('.form-group > input[name="' + data.params.field_id + '"]');
          //   fieldInput.val(val);
          // }
          // __setValue(data.params.value);

          // var fieldInput = $('#' + data.params.field_id);
          var fieldInput = $('.form-group > input[name="' + data.params.field_id + '"]');
          fieldInput.val(data.params.value);
          // fieldInput.data('post-message', {
          //   id: data.id,
          //   method: data.methodName
          // });
          // fieldInput.click();
          // var v = $('#' + data.params.field_id).val();
          // frames[data.iframeId].postMessage({
          //   id: data.id,
          //   result: 'Field was updated',
          //   value: v
          // }, '*');
        } else if (data.methodName === 'extension_field_validate') {
          var fieldInput = $('#' + data.params.field_id);
          fieldInput.data('post-message', {
            id: data.id,
            method: data.methodName,
            validate: true
          });
          fieldInput.click();
        } else if (data.methodName === 'extension_field_set_invalid') {
          var fieldInput = $('#' + data.params.field_id);
          fieldInput.data('post-message', {
            id: data.id,
            method: data.methodName,
            setInvalid: true,
            value: data.params.value
          });
          fieldInput.click();
        } else if (data.methodName === 'extension_field_remove_value') {
          var fieldInput = $('#' + data.params.field_id);
          fieldInput.val(null);
          fieldInput.data('post-message', {
            id: data.id,
            method: data.methodName
          });
          fieldInput.click();
        } else if (data.methodName === 'extension_field_set_fullscreen') {
          if (data.params.bool !== false) {
            function run() {
              var form = $('.form--default').parent()
              var formActionsWrapper = $('.form-actions-wrapper');
              var ifr = $('#' + data.iframeId);
              if (ifr.length) {
                // form.css({
                //   'padding': 0
                // });
                ifr.css({
                  height: form.parent()[0].offsetHeight - 65 + 'px',
                  top: 0,
                  left: 0,
                  position: 'absolute',
                  borderBottom: 'solid 1px #dedede'
                });
                // ifr.style.height = data.height + 'px';
              }
              if (formActionsWrapper.length) {
                // form.css({
                //   'padding': 0
                // });
                formActionsWrapper.css({
                  bottom: '20px',
                  left: '-20px',
                  position: 'fixed',
                  width: '100%'
                });
                
                // ifr.style.height = data.height + 'px';
              }
            }
            run();
          } else {

          }
        } else if (data.methodName === 'extension_form_submit') {
          $('[type="submit"]')[0].click();
          // var fieldInput = $('#' + data.params.field_id);
          // fieldInput.data('post-message', {
          //   id: data.id,
          //   method: data.methodName
          // });
          // fieldInput.click();
          // frames[data.iframeId].postMessage({
          //   id: data.id,
          //   method: data.methodName,
          //   data: {
          //     message: 'Form was submitted'
          //   }
          // }, '*');
        } else if (data.methodName === 'extension_form_set_padding') {
          $('.form--default').parent().css('padding', data.params.height + 'px');
        } else if (data.methodName === 'extension_bucket_get') {
          twixly.api.get(data.params.endPoint, data.params.options)
            .then(function(res) {
              extensionSendBucketData(data.iframeId, data.id, data.methodName, res);
            })
            .catch(function(err) {
              extensionSendBucketData(data.iframeId, data.id, data.methodName, null, err);
            });       
        } else if (data.methodName === 'extension_bucket_post') {
          twixly.api.post(data.params.endPoint, data.params.data)
            .then(function(res) {
              extensionSendBucketData(data.iframeId, data.id, data.methodName, res);
            })
            .catch(function(err) {
              extensionSendBucketData(data.iframeId, data.id, data.methodName, null, err);
            });
        } else if (data.methodName === 'extension_bucket_put') {
          twixly.api.put(data.params.endPoint, data.params.data)
            .then(function(res) {
              extensionSendBucketData(data.iframeId, data.id, data.methodName, res);
            })
            .catch(function(err) {
              extensionSendBucketData(data.iframeId, data.id, data.methodName, null, err);
            });
        } else if (data.methodName === 'extension_bucket_delete') {
          twixly.api.delete(data.params.endPoint)
            .then(function(res) {
              extensionSendBucketData(data.iframeId, data.id, data.methodName, res);
            })
            .catch(function(err) {
              extensionSendBucketData(data.iframeId, data.id, data.methodName, null, err);
            });
        } else if (data.methodName === 'extension_dialogs_select_single_item') {
          var options = {
            add_button: true,
            input_id: data.iframeId + ',' + data.id + ',' + data.methodName,
            item_type_id: data.params.options.itemTypes[0],
            mode: 'undefined',
            multiple: false,
            title: 'Lägg till',
            type: 'items'
          }
          showLinkModal(options, false);
        } else if (data.methodName === 'extension_dialogs_select_multiple_items') {
          var options = {
            add_button: true,
            input_id: data.iframeId + ',' + data.id + ',' + data.methodName,
            item_type_id: data.params.options.itemTypes[0],
            mode: 'undefined',
            multiple: true,
            title: 'Lägg till',
            type: 'items'
          }
          showLinkModal(options, false);
        } else if (data.methodName === 'extension_dialogs_select_single_media') {
          var options = {
            add_button: true,
            input_id: data.iframeId + ',' + data.id + ',' + data.methodName,
            // item_type_id: data.params.options.itemTypes[0],
            mode: 'undefined',
            multiple: false,
            title: 'Lägg till',
            type: 'media'
          }
          showLinkModal(options, false);
        } else if (data.methodName === 'extension_dialogs_select_multiple_media') {
          var options = {
            add_button: true,
            input_id: data.iframeId + ',' + data.id + ',' + data.methodName,
            // item_type_id: data.params.options.itemTypes[0],
            mode: 'undefined',
            multiple: true,
            title: 'Lägg till',
            type: 'media'
          }
          showLinkModal(options, false);
        } else if (data.methodName === 'extension_dialogs_edit_item') {
          var options = {
            input_id: data.iframeId + ',' + data.id + ',' + data.methodName + ',' + data.params.itemId,
            item_type_id: '__twixly_dummy_delete_soon__',
            multiple: false,
            title: 'Ändra',
            item_id: data.params.itemId,
            type: 'items'
          }
          showLinkModal(options, false);
        } else if (data.methodName === 'extension_dialogs_edit_media_item') {
          var options = {
            input_id: data.iframeId + ',' + data.id + ',' + data.methodName + ',' + data.params.itemId,
            multiple: false,
            title: 'Ändra',
            item_id: data.params.itemId,
            type: 'items'
          }
          showLinkModal(options, false);
        } else if (data.methodName === 'extension_dialogs_select_send_close_dialog_response') {
          if (currentLinkObject && currentLinkObject.input_id) {
            if (~currentLinkObject.input_id.indexOf(',')) {
              var inputIdSplit = currentLinkObject.input_id.split(',');
              var iframeId = inputIdSplit[0];
              var id = inputIdSplit[1];
              var method = inputIdSplit[2];
              var res = null;
              // eval(currentLinkObject.iframe).extensionSendDialogData(iframeId, id, method, null);
              eval(currentLinkObject.iframe).postMessage({'method': 'extension_dialogs_select_send_dialog_data', params: {iframeId: iframeId, id: id, method: method, res: res}}, '*');
            }
          }
        } else if (data.methodName === 'extension_dialogs_select_send_dialog_data') {
          frames[data.params.iframeId].postMessage({
            id: data.params.id,
            method: data.params.method,
            data: {
              res: data.params.res,
              errors: data.params.errors
            }
          }, '*');
        } else if (data.action == 'set_template_current_url') {
          window.templateCurrentUrl = data.value;
          // localStorage.setItem('nav-menu-item-current-page-url', templateCurrentUrl);
          localStorage.setItem('ide-current-page-url', templateCurrentUrl);
          // console.log('Page URL was saved to local storage!');
          // console.log(templateCurrentUrl);
        } else if (data.action == 'reload_template') {
          if (frames && frames.document && frames.document.getElementById('page')) {
            parent.frames['page'].postMessage({'action': 'show_loader', 'text': 'Laddar sidan...'}, '*');
            // if (frames && frames['page-toolbar'].document) {
            //   frames['page-toolbar'].document.getElementById('page-toolbar-loader').style.visibility = 'visible';
            //   $('#page').load(function() {
            //     frames['page-toolbar'].document.getElementById('page-toolbar-loader').style.visibility = 'hidden';
            //   });
            // }
            var currentUrl;
            if (typeof templateCurrentUrl !== 'undefined') {
              currentUrl = templateCurrentUrl;
            } else {
              currentUrl = frames.document.getElementById('page').src;
            }
            if (!~currentUrl.indexOf('env=dev')) {
              if (~currentUrl.indexOf('?')) {
                currentUrl = currentUrl + '&env=dev&cache_key=' + Date.now();
              } else {
                currentUrl = currentUrl + '?env=dev&cache_key=' + Date.now();  
              }
            }
            request
              .get(currentUrl)
              .end(function(err, res) {
                if (res && res.status === 500) {
                  console.log('Hämta sidan igen efter några millisekunder');
                  if (reload_template_counts > 10) {
                    reload_template_counts = 0;
                    frames.document.getElementById('page').src = frames.document.getElementById('page').src.replace('/blogg', '');
                  } else {
                    reload_template_counts++;
                    setTimeout(function() { window.postMessage({'action': 'reload_template'}, '*'); }, reload_template_counts * 10);
                  }
                } else if (err) {
                  alert('Page error, whaaaat!');
                  console.log('The page seems broken');
                } else {
                  // console.log('Success: Page was reloaded!');
                  reload_template_counts = 0;
                  frames.document.getElementById('page').src = currentUrl;
                  // frames.document.getElementById('page').style.opacity = '1';
                }
              });
          }
        } else if (data.action == 'load_template_url') {
          if (frames && frames.document && frames.document.getElementById('page')) {
            var uri = frames.document.getElementById('page').src.split('/')[0] + '//' + frames.document.getElementById('page').src.split('/')[2];
            var currentUrl = uri + data.url;
            if (~currentUrl.indexOf('?')) {
              currentUrl = currentUrl + '&env=dev&cache_key=' + Date.now();
            } else {
              currentUrl = currentUrl + '?env=dev&cache_key=' + Date.now();  
            }
            request
              .get(currentUrl)
              .end(function(err, res) {
                if (res && res.status === 500) {
                  
                } else if (err) {
                  console.log('The page seems broken');
                } else {
                  // console.log('Success: Page was loaded!');
                  frames.document.getElementById('page').src = currentUrl;
                }
              });
          }
        } else if (data.action == 'set_local_storage') {
          localStorage.setItem(data.key, data.value);
        } else if (data.action == 'current_link_object') {
          e.source.postMessage({'action': 'current_link_object_response', 'value': window.top.window.currentLinkObject}, '*');
        } else if (data.action == 'set_current_link_object') {
          currentLinkObject = data.value;
        } else if (data.action === 'clear_current_link_object') {
          currentLinkObject = undefined;
        } else if (data.action == 'current_link_object_iframe_input_value') {
          e.source.postMessage({'action': 'current_link_object_iframe_input_value_response', 'value': $('#' + data.input_id).val()}, '*');
        } else if(data.action == 'current_link_object_1') {
          e.source.postMessage({'action': 'current_link_object_1_response', 'value': window.top.window.currentLinkObject_1}, '*');
        } else if(data.action == 'set_current_link_object_1') {
          currentLinkObject_1 = data.value;
        } else if (data.action === 'clear_current_link_object_1') {
          currentLinkObject_1 = undefined;
        } else if (data.action == 'current_link_object_1_iframe_input_value') {
          e.source.postMessage({'action': 'current_link_object_1_iframe_input_value_response', 'value': $('#' + data.input_id).val()}, '*');
        } else if(data.action === 'set_input_value') {
          $('#' + data.input_id).val(data.value);
          $('#' + data.input_id).click();
        } else if(data.action === 'update_link_view') {
          var updateLinkViewButton = $('#' + data.input_id + '-update-link-view');
          updateLinkViewButton.click();
        } else if(data.action === 'close_dialog_window') {
          $('[data-dismiss="modal"]').click();
        } else if(data.action === 'set_dialog_iframe_to_about_blank') {
          $("#modal-iframe")[0].src = 'about:blank';
        } else if(data.action === 'show_loader') {
          $('#pre-loader').removeClass().addClass('loader')
          if (data.modifier) {
            $('#pre-loader').addClass(data.modifier);  
          }
          $('#pre-loader .content').text(data.preLoaderText);
          $('#pre-loader').css('visibility', 'visible');
        } else if(data.action === 'window_context') {
          e.source.postMessage({'action': 'window_context_response', 'value': window.context}, '*');
        } else if (data.action == 'reload') {
          location.reload();
        } else if(data.action === 'show_dialog') {
          var modal = $('#systemModal').removeData('bs.modal');
          modal.find('.loader').css('visibility', 'visible')
          .addClass('loader--light-inverted');
          modal.removeClass('fade');
          modal.find('.modal-title').text(data.title);
          modal.find('.modal-body #modal-iframe').prop('src', data.source);
          modal.find('.modal-body #modal-iframe').css('min-height', data.modalIframeHeight);
          // console.log(data.source)
          if (~data.source.indexOf('action=edit')) {
            modal.find('.js-dialog-save').removeClass('hidden').addClass('visible');
          } else {
            modal.find('.js-dialog-save').removeClass('visible').addClass('hidden');
          }
          modal.modal('show');
          modal.find('.modal-body #modal-iframe').load(function () {
            modal.find('.loader').css('visibility', 'hidden');
          });
          modal.off('hidden.bs.modal');
          modal.on('hidden.bs.modal', function() {
            if (data.modalOnHidden === 'object') {
              if(window.windowCurrentLinkObject && window.windowCurrentLinkObject.inlineSub) {
              // if(window.top.window.currentLinkObject && window.top.window.currentLinkObject.inlineSub) {
                window.top.window.currentLinkObject = {
                  input_id: window.top.window.currentLinkObject.input_id_parent,
                  type: window.top.window.currentLinkObject.type_parent,
                  item_type: window.top.window.currentLinkObject.item_type_parent,
                  multiple: window.top.window.currentLinkObject.multiple_parent,
                  iframe: window.top.window.currentLinkObject.iframe_parent,
                  input_id_parent: window.ject.input_id,
                  type_parent: window.ject.type,
                  item_type_parent: window.ject.item_type,
                  multiple_parent: window.ject.multiple,
                  iframe_parent: window.ject.iframe,
                  // Don't need them your inline : )
                  // modalIframe: window.top,
                  // modalIframeId: window.top.$('#modal-iframe')[0],
                  loaderFrame: window.top.parent.frames['page'],
                  inline: true,
                  inlineSub: true
                };
                // console.log('input_id: ' + window.top.window.currentLinkObject.input_id);
                // console.log('type: ' + window.top.window.currentLinkObject.type);
                // console.log('item_type: ' + window.top.window.currentLinkObject.item_type);
                // console.log('multiple: ' + window.top.window.currentLinkObject.multiple);
                //console.log('iframe: ' + window.top.window.currentLinkObject.iframe.name);
                //console.log('loaderFrame: ' + window.top.window.currentLinkObject.loaderFrame.name);
              } else {
                // window.top.window.currentLinkObject = null;
                // console.log('clear_0')
                // if (typeof currentLinkObject !== 'undefined') {
                //   currentLinkObject.iframe = eval(currentLinkObject.iframe);
                //   currentLinkObject.iframe.postMessage({'action': 'update_link_view', 'input_id': currentLinkObject.input_id}, '*');
                // }
                // if (typeof currentLinkObject_1 !== 'undefined') {
                //   currentLinkObject_1.iframe = eval(currentLinkObject_1.iframe);
                //   currentLinkObject_1.iframe.postMessage({'action': 'update_link_view', 'input_id': currentLinkObject_1.input_id}, '*'); 
                // }
                window.top.window.postMessage({'methodName': 'extension_dialogs_select_send_close_dialog_response'}, '*');
                window.top.window.postMessage({'action': 'clear_current_link_object'}, '*');
                window.top.window.postMessage({'action': 'clear_current_link_object_1'}, '*');
              }
            } else {
              // window.top.window.currentLinkObject_1 = null;
              // console.log('clear_1')
              window.top.window.postMessage({'action': 'clear_current_link_object_1'}, '*');
            }
            modal.find('.modal-body #modal-iframe').prop('src', 'about:blank');
          });
        } else if(data.action === 'save_dialog') {
          $('#form-cms').click();
          // $('form').submit();
          // $('#pre-loader .content').text(preLoaderText);
          // $('#pre-loader').css('visibility', 'visible');  
        } else if(data.action === 'get_access') {
          if(parent.frames['app']) {
            var appStart = function(data) {
              parent.frames['app'].postMessage(data, '*');
            }
            var genericError = function(err) {
              parent.frames['app'].postMessage(err.message, '*');
            }
            var cms;
            var clientConfig;
            if (typeof window.envLocal !== 'undefined') {
              cms = require('../../../../sdk/index.js');
              clientConfig = {
                host: 'localhost',
                port: '8080', 
                secure: false,
                clientId: window.clientId,
                clientSecret: window.clientSecret,
                code: window.code
              }  
            } else {
              cms = require('twixly');
              clientConfig = {
                clientId: window.clientId,
                clientSecret: window.clientSecret,
                code: window.code
              }
            }
            var client = new cms.Client(clientConfig);
            client.init()
              .then(appStart)
              .catch(genericError);
          }
        } else if(data.action === 'show_app_loader') {
          // Check to see your'e not in a frameset
          if (document.getElementsByTagName('div').length) {
          // if(parent.frames['app']) {
            mprogress.start();
            mprogress.set(0.4);
            // document.querySelector('.js-loader').style.visibility='visible';
            // document.querySelector('#app').style.visibility='hidden';
          // }
          }
        } else if(data.action === 'hide_app_loader') {
          // Check to see your'e not in a frameset
          if (document.getElementsByTagName('div').length) {
          // if(parent.frames['app']) {
            mprogress.end();
            if (document.querySelector('.js-loader')) {
              document.querySelector('.js-loader').style.visibility='hidden';
            }
            if (document.querySelector('#app')) {
              document.querySelector('#app').style.visibility='visible';  
            }
          // }
          }
        } else if(data.action === 'logout') {
          location.href = '/logout';
        } 
      }, false);
    };

    setPostMessageEventListener();
  }
}

module.exports = postMessage;