var React = require('react');
var ReactPropTypes = React.PropTypes;
var request = require('superagent');
var async = require('async');
var dialog = require('../../lib/dialog.js');
//var cx = require('react/lib/cx');
var validate = require('../../lib/validate.js');
var validate2 = require('../../lib/validate-2.js');
var utils = require('../../lib/utils.js');
// Controls
var AccessToken = require('./Controls/AccessToken');
var Filepicker = require('./Controls/Filepicker');
var Slug = require('./Controls/Slug');
var Relationship = require('./Controls/Relationship');
var Pages = require('./Controls/Pages/Pages');
var Custom = require('./Controls/Custom');

// Widgets
var WidgetExample = require('./Widgets/WidgetExample');
var Checkboxes = require('./Widgets/Checkboxes');
var Color = require('./Widgets/Color');
var File = require('./Widgets/File');
var Icon = require('./Widgets/Icon');
var SvgIcon = require('./Widgets/SvgIcon');
var Switch = require('./Widgets/Switch');

var uiWidgets = {
  checkboxes: Checkboxes,
  color: Color,
  file: File,
  icon: Icon,
  svg_icon: SvgIcon,
  switch: Switch
};

var PropertyItem = React.createClass({displayName: "PropertyItem",
  propTypes: {
    value: React.PropTypes.oneOfType([
      React.PropTypes.string,
      React.PropTypes.number,
      React.PropTypes.bool,
      React.PropTypes.object,
      React.PropTypes.array
    ]).isRequired
  },
  getInitialState: function() {
    this.fieldName = this._fieldName(this.props.name);
    return {
      value: this._getDefaultValue(),
      isValid: true
    };
  },
  _getDefaultValue: function() {
    if (typeof this.props.isNew !== 'boolean') {
      this.props.isNew = this.props.value ?
                         false :
                         true;
    }
    if (this.props.required) {
      this.props.config.required = true;
    }
    var defaultValue;
    defaultValue = this.props.value || this.props.value == 0 ? this.props.value : this.props.config.default_value ?
                                                         this.props.config.default_value :
                                                           this.props.config.default ?
                                                             this.props.config.default : 
                                                             '';
    defaultValue = typeof defaultValue === 'object' ? JSON.stringify(defaultValue) : defaultValue;
    defaultValue = this.props.config.type === 'boolean' ? (defaultValue === 'true' || defaultValue === true) : defaultValue;
    defaultValue = this.props.config.type === 'integer' && typeof defaultValue !== 'undefined' && defaultValue.length > 0 && !~defaultValue.indexOf('.') && !isNaN(defaultValue) ? parseInt(defaultValue) : defaultValue;
    defaultValue = this.props.config.type === 'number' && typeof defaultValue !== 'undefined' && defaultValue.length > 0  && defaultValue.substring(defaultValue.length-1) != '.' && !isNaN(defaultValue) ? parseFloat(defaultValue) : defaultValue;
    return defaultValue;
  },
  _showForm: function (e) {
    var ifr = $(e.target);
    // if (ifr[0].offsetHeight === 0) {
    //   ifr.prev().removeClass('extension-field--hide');
    //   console.log('Extension ' + this.props.name + ' is not responding. Iframe is : ' + ifr[0].offsetHeight + '!');
    // }
    $('.form--default').removeAttr('style');
  },
  _setExtensionDataOnInit: function() {
    if (this.props.config.extension) {
      // var arrayKeySuffix = this.props.uniqueArrayKey ?
      //                      '-' + this.props.uniqueArrayKey :
      //                      '';
      // var fieldId = this.props.name + arrayKeySuffix;
      var fieldId = this.props.name;
      var inputField = $('#' + fieldId);
      var postMessageInfo = inputField.data('post-message');
      if (postMessageInfo.options && postMessageInfo.options.height) {
        var ifr = document.getElementById('extension_iframe_' + this.props.name);
        if (ifr) {
          ifr.style.height = postMessageInfo.options.height + 'px';
        }
      }
      if (postMessageInfo.options && postMessageInfo.options.enableDefault) {
        if (~postMessageInfo.options.enableDefault.indexOf('*')) {
          inputField.prev().css('display', 'block');
          inputField.next().next().css('display', 'block');
          inputField.next().next().next().css('display', 'block');
          // inputField.prev().remove();
          // inputField.next().next().remove();
          // inputField.next().next().remove();
        } else {
          if (~postMessageInfo.options.enableDefault.indexOf('label')) {
            inputField.prev().css('display', 'block');
            // inputField.prev().remove();
          }
          if (~postMessageInfo.options.enableDefault.indexOf('description')) {
            inputField.next().next().next().css('display', 'block');
            // inputField.next().next().next().remove();
          }
          if (~postMessageInfo.options.enableDefault.indexOf('errors')) {
            inputField.next().next().css('display', 'block');
            // inputField.next().next().remove();
          }
        }
      }
      var ui_schema = this._getUiSchema();
      if (document.activeElement && document.activeElement.name === this.props.name) {
        ui_schema['ui:autofocus'] = true;
      }
      var validation = this._validate(inputField, null, null, true);
      frames['extension_iframe_' + this.props.name].postMessage({
        init_extension: true,
        id: postMessageInfo.id,
        method: postMessageInfo.method,
        data: {
          value: this._getCorrectValue(this.state.value),
          name: this.props.name,
          id: this.props.name,
          type: this.props.config.type,
          schema: this.props.config,
          ui_schema: ui_schema,
          isValid: this.state.isValid,
          errors: validation.errors,
          item_type: window.currentItemType,
          item: window.currentItemExtension
        }
      }, '*');
    }
  },
  _setExtensionData: function(data) {
    if (this.props.config.extension) {
      // var arrayKeySuffix = this.props.uniqueArrayKey ?
      //                      '-' + this.props.uniqueArrayKey :
      //                      '';
      // var inputField = $('#' + this.props.name + arrayKeySuffix);
      var inputField = $('#' + this.props.name);
      var postMessageInfo = inputField.data('post-message');
      data = {
        data: data
      }
      if (postMessageInfo) {
        data.id = postMessageInfo.id;
        data.method = postMessageInfo.method;
        if (postMessageInfo.validate) {
          
        }
      }
      frames['extension_iframe_' + this.props.name].postMessage(data, '*');
      inputField.data('post-message', '');
    }
  },
  _setExtensionDataExternalField: function(fieldName, data) {
    data = {
      data: data
    }
    data.method = 'external_field';
    frames['extension_iframe_' + fieldName].postMessage(data, '*');
  },
  _getUiSchema: function () {
    return this.props.uiSchema || {};
  },
  _onChange: function(event) {
    var val = event.target.value;
    val = this.props.config.type === 'boolean' && !this._getUiSchema()['ui:widget'] ? event.target.checked : this._getCorrectValue(val);
    val = this.props.config.type === 'integer' && val.length > 0 && !~val.indexOf('.') && !isNaN(val) ? parseInt(val) : val;
    val = this.props.config.type === 'number' && val.length > 0  && val.substring(val.length-1) != '.' && !isNaN(val) ? parseFloat(val) : val;
    // Make as a native widget in the future
    if (this.props.config.type === 'array' && this.props.config.items && val.length > 0  && val.substring(val.length-1) != '.') {
      if (!(this._getUiSchema()['ui:widget'] === 'checkboxes' || this.props.config.extension)) {
        var options = event.target.options;
        val = [];
        for (var i = 0, l = options.length; i < l; i++) {
          if (options[i].selected) {
            val.push(options[i].value);
          }
        }
      }
    }
    var self = this;
    if(this.props.onChange) {
      var prop = {};
      prop.key = this.fieldName;
      prop.value = val;
      this.props.onChange(prop);
    } else {
      if (this.props.isNew) {
        var key = $(event.target).attr('name');
        var slugifiers = $('[data-slugify]');
        slugifiers.each(function(index) {
          var slugifierName = $(this).data('slugify');
          var input = $(this).next();
          if(slugifierName === key) {
            var stateValue = self.state.value ? self.state.value : ''; 
            var slugifiedProp = utils.slugify(stateValue, input.data('separator'));
            if (!input.prop('readonly')) {
              if(input.val().length === 0 || input.val() === slugifiedProp) {
                input.prop('value', utils.slugify(val, input.data('separator')));
                input.click();
                if(input.attr('name') === '__handle') {
                  input.next().click();
                }
              }
            }
          }
        });
      }
      if(this.props.config.type === 'boolean' && !this._getUiSchema()['ui:widget']) {
        if(val) {
          event.target.value = 'true';
        } else {
         event.target.value = 'false';
        }
      }
      if(this.props.config.type === 'integer') {
        $('#range-'+this.props.name).text(event.target.value);
      }
    }
    var validation;
    if (this.props.config.type !== 'custom') {
      if (this.props.config.type === 'slug') {
        validation = this._validate(event, null, null, false);
      // } else if (this.props.config.extension && document.activeElement.name !== 'extension_iframe_' + this.props.name) {
      } else if (this.props.config.extension) {
        // console.log(document.activeElement);
        // console.log(this.props.name);
        var inputField = $(event.target);
        var postMessageInfo = inputField.data('post-message');
        if (postMessageInfo && postMessageInfo.validate) {
          validation = this._validate(event, null, null, false);
        } else {
          validation = this._validate(event, null, null, true);
        }
        if (postMessageInfo && postMessageInfo.setInvalid) {
          inputField.data('post-message', '');
          return;
        }
        if (postMessageInfo && postMessageInfo.initExtension) {
          this._setExtensionDataOnInit();
          inputField.data('post-message', '');
          return;
        }
      } else {
        validation = this._validate(event, null, null, true);
      }
    }
    if(!this.props.onChange) {
      this.setState({
        value: val,
        isValid: validation.isValid
      });
      if (this.props.onFormChange) {
        this.props.onFormChange(this.props.name, val, validation.isValid);
      }
    }
    this._setExtensionData({
      value: this._getCorrectValue(val),
      isValid: validation.isValid,
      errors: validation.errors
    });
    // Send request to extensions except this field if this is an extension field. Refactor this function to use for all fields in the future.
    if (window.currentItemType) {
      for (var propKey in window.currentItemType.attributes.schema.properties) {
        var prop = window.currentItemType.attributes.schema.properties[propKey];
        if (!(this.props.config.extension && propKey === this.props.name)) {
          if (prop.extension) {
            // console.log({propKey: propKey, current: this.props.name});
            this._setExtensionDataExternalField(propKey, {
              id: this.props.name,
              value: this._getCorrectValue(val),
              isValid: validation.isValid,
              errors: validation.errors
            });
          }
        }
      }
    }
  },
  componentDidMount: function() {
    // console.log('Getting context!!!');
    // get data for relationship type : )
    var date = Date.now();
    if(this.props.config.type === 'relationship') {
      
      // Test single threading
      // for (var i=0;i<3;i++) {
      //   $.get('http://localhost:8080/v1/buckets/575f3ac21efbec670b979ac3/items?access_token=575f3ac61efbec670b979ac6', function(data) {
      //     var ms = Date.now() - date;
      //     console.log(ms);
      //   })
      // }
      // cmsClient.get('items')
      //   .then(function(data) {
      //     var ms = Date.now() - date;
      //     console.log('ms 1:', ms);
      //   })
      //   .catch(function(err) {
      //     console.log('2');
      //   });
      // cmsClient.get('items')
      //   .then(function(data) {
      //     var ms = Date.now() - date;
      //     console.log('ms 2:', ms);
      //   })
      //   .catch(function(err) {
      //     console.log('2');
      //   });
      utils.getContext(function(err, data) {
        if (err) {
          console.log('error: ', err.message);
        } else {
          if (this.isMounted()) {
            this.setState({
              value: this.state.value,
              isValid: this.state.isValid,
              items: window.cmsContext.items,
              itemTypes: window.cmsContext.itemTypes,
              media: window.cmsContext.media
            });
          }
        }
      }.bind(this), true);
    }
  },
  componentDidUpdate: function() {
    if(this.props.config.type === 'relationship') {
      //console.log('did update')
      dialog.init();
    }
    //if(this.props.name === '__handle') {
    //  this._validate($('#' + this.props.name), null, true);
    //}
  },
  _updateRelationshipView: function(event) {
    // alert('updating')
    utils.getContext(function(err, data) {
      if (err) {
        console.log('error: ', err.message);
      } else {
        this.setState({
          value: this.state.value,
          isValid: this.state.isValid,
          items: window.cmsContext.items,
          media: window.cmsContext.media,
          itemTypes: window.cmsContext.item_types
        });
      }
    }.bind(this), true);
  },
  _validate: function(event, reactId, isTrusted, doNotRenderErrorGUI) {
    if (window._c10CurrentActiveElement) {
      window._c10CurrentActiveElement = null;
      return true;
    }
    var $field;
    var arrayKey = this.props.uniqueArrayKey ? this.props.uniqueArrayKey : '';
    var uniqueId = arrayKey ? this.props.name + '-' + arrayKey : this.props.name;
    if(typeof event === 'undefined' || this.props.config.type === 'custom') { 
      $field = $('#' + uniqueId);
    } else if(event.target) {
      $field = $(event.target);
    } else {
      $field = event;
    }
    var $fieldContainer = $field.parent();
    $fieldContainer = $fieldContainer.attr('class').indexOf('form-group') === -1 ? $fieldContainer.parent() : $fieldContainer;
    $fieldContainer = $fieldContainer.attr('class').indexOf('form-group') === -1 ? $fieldContainer.parent() : $fieldContainer;
    // If this is a custom field. Then add validation if found.
    if($field.data('pattern')) {
      this.props.config.pattern = $field.data('pattern');
      this.props.config.message = $field.data('message');
    }
    // const validation = validate.control(this.props.name, $field.val(), this.props.config);
    const validation = this._validateField($field.val());
    // console.log('OLDVALIDATION: ', validation);
    // console.log('VALIDATION: ', validation);
    if (this.props.config.type !== 'custom') {
      if(doNotRenderErrorGUI) {
        if(validation.isValid) {
          $fieldContainer.removeClass('has-error');
          $('button[type="submit"]').prop('disabled', false);
        }
      } else {
        $fieldContainer.removeClass('has-error');
        if(!validation.isValid) {
          $fieldContainer.find('.js-error-message-text').text(validation.errors[0].detailed);
          $fieldContainer.addClass('has-error');
          $('button[type="submit"]').prop('disabled', true);
        } else {
          $('button[type="submit"]').prop('disabled', false);
        }
      }
      if (this.props.config.extension) {
        var postMessageInfo = $field.data('post-message');
        if (postMessageInfo && postMessageInfo.setInvalid) {
          if (postMessageInfo.value === true) {
            $fieldContainer.find('.js-error-message-text').text(validation.errors[0].detailed);
            $fieldContainer.addClass('has-error');
          } else {
            $fieldContainer.removeClass('has-error');
          }
        }
      }
    }
    if (this.props.config.type === 'custom') {
      // console.log(event)
      // this._onChange(event);
      // $fieldContainer.removeClass('has-error');
      $fieldContainer.removeClass('js-has-error');
      if(!validation.isValid) {
        // $fieldContainer.find('.js-error-message-text').text(validation.message);
        // $fieldContainer.addClass('has-error');
        $fieldContainer.addClass('js-has-error');
        $('button[type="submit"]').prop('disabled', true);
      } else {
        $('button[type="submit"]').prop('disabled', false);
      }
      if (this.textInput && this.textInput.onFormSubmit) {
        this.textInput.onFormSubmit(validation.isValid);
      }
    }
    // console.log($field.val());
    return validation;
  },
  _validateField: function(value) {
    // if (this.props.config.type === 'boolean') {
    //   value = value === 'on' ? true : false;
    // } else if (this.props.config.type === 'integer') {
    //   value = typeof value !== 'undefined' && value.length > 0 && !isNaN(value) ?
    //           parseInt(value) :
    //           value;
    // } else if (this.props.config.type === 'number') {
    //   value = typeof value !== 'undefined' && value.length > 0 && value.substring(value.length - 1) != '.' && !isNaN(value) ?
    //           parseFloat(value) :
    //           value;
    // }
    var itemType = {
      attributes: {
        schema: {
          required: this.props.config.required ? [this.fieldName] : undefined,
          properties: {}
        }
      }
    };
    itemType.attributes.schema.properties[this.fieldName] = this.props.config;
    var item = {
      attributes: {}
    };
    item.attributes[this.fieldName] = value;
    // console.log('asdfasdfasdf 1', item)
    var item = utils.setCorrectTypes(item, itemType);
    // console.log('asdfasdfasdf 2', item)
    // console.log('asdf', this.props.value)
    // var instance = {};
    // instance[this.props.name] = value;
    // var schema = {
    //   required: this.props.config.required ? [this.props.name] : undefined,
    //   properties: {}
    // }
    // schema.properties[this.props.name] = this.props.config
    // console.log('ITEM: ', item);
    // console.log('ITEM TYPE: ', itemType);
    var validation = validate2.form(item, itemType);
    validation.isValid = true;
    // validation.isValid = validation.errors.length ? false : true;
    // console.log('SCHEMA: ', schema);
    return validation;
  },
  _validateCustomField: function() {
    var $field;
    var arrayKey = this.props.uniqueArrayKey ? this.props.uniqueArrayKey : '';
    var uniqueId = arrayKey ? this.props.name + '-' + arrayKey : this.props.name;
    $field = $('#' + uniqueId);
    var $fieldContainer = $field.parent();
    $fieldContainer = $fieldContainer.attr('class').indexOf('form-group') === -1 ? $fieldContainer.parent() : $fieldContainer;
    $fieldContainer = $fieldContainer.attr('class').indexOf('form-group') === -1 ? $fieldContainer.parent() : $fieldContainer;
    // If this is a custom field. Then add validation if found.
    if($field.data('pattern')) {
      this.props.config.pattern = $field.data('pattern');
      this.props.config.message = $field.data('message');
    }
    value = $field.val();
    var validation = validate.control(this.props.name, value, this.props.config);
    // if(hide) {
    if(validation.isValid) {
      $fieldContainer.removeClass('has-error');
    //     $('button[type="submit"]').prop('disabled', false);
    }
    // console.log('asdf: ', validation.isValid)
    // } else {
    //   $fieldContainer.removeClass('has-error');
       if(!validation.isValid) {
    //     $fieldContainer.find('.js-error-message-text').text(validation.message);
    //     $fieldContainer.addClass('has-error');
    //     $('button[type="submit"]').prop('disabled', true);
       } else {
        $('button[type="submit"]').prop('disabled', false);
       }
    // }
    // console.log($field.val());
    return validation.isValid;
  },
  _validateCustomFieldWithValue: function(value, validation) {
    if(validation) {
      this.props.config.pattern = validation.pattern;
      this.props.config.message = validation.message;
    }
    if (typeof value === 'undefined') {
      value = '';
    }
    var validation = validate.control(this.props.name, value, this.props.config);
    return validation.isValid;
  },
  _fieldName: function() {
    var fieldName = this.props.name && ~this.props.name.indexOf('__') ?
                    this.props.name.split('__').length > 2 ?
                      this.props.name.split('__')[2] :
                      this.props.name.split('__')[1] :
                    this.props.name;
    return fieldName;
  },
  _uniqueId: function() {
    var arrayKeySuffix = this.props.uniqueArrayKey ?
                         '-' + this.props.uniqueArrayKey :
                         '';
    return this.props.name + arrayKeySuffix;
  },
  _showErrors: function() {
    var $field;
    var arrayKey = this.props.uniqueArrayKey ? this.props.uniqueArrayKey : '';
    var uniqueId = arrayKey ? this.props.name + '-' + arrayKey : this.props.name;
    $field = $('#' + uniqueId);
    var $fieldContainer = $field.parent();
    $fieldContainer = $fieldContainer.attr('class').indexOf('form-group') === -1 ? $fieldContainer.parent() : $fieldContainer;
    $fieldContainer = $fieldContainer.attr('class').indexOf('form-group') === -1 ? $fieldContainer.parent() : $fieldContainer;
    if($field.data('pattern')) {
      this.props.config.pattern = $field.data('pattern');
      this.props.config.message = $field.data('message');
    }
    var validation = validate.control(this.props.name, $field.val(), this.props.config);
    $fieldContainer.removeClass('has-error');
    // if(!validation.isValid) {
      $fieldContainer.find('.js-error-message-text').text(validation.message);
      $fieldContainer.addClass('has-error');
      $('button[type="submit"]').prop('disabled', true);
    // } else {
    //   $('button[type="submit"]').prop('disabled', false);
    // }
  },
  _hideErrors: function() {
    var $field;
    var arrayKey = this.props.uniqueArrayKey ? this.props.uniqueArrayKey : '';
    var uniqueId = arrayKey ? this.props.name + '-' + arrayKey : this.props.name;
    $field = $('#' + uniqueId);
    if ($field.length) {
      var $fieldContainer = $field.parent();
      $fieldContainer = $fieldContainer.attr('class').indexOf('form-group') === -1 ? $fieldContainer.parent() : $fieldContainer;
      $fieldContainer = $fieldContainer.attr('class').indexOf('form-group') === -1 ? $fieldContainer.parent() : $fieldContainer;
      if($field.data('pattern')) {
        this.props.config.pattern = $field.data('pattern');
        this.props.config.message = $field.data('message');
      }
      var validation = validate.control(this.props.name, $field.val(), this.props.config);
      // if(validation.isValid) {
        $fieldContainer.removeClass('has-error');
        $('button[type="submit"]').prop('disabled', false);
      // }
    }
  },
  _setInvalid: function(value) {
    value === true ?
    this._showErrors() :
    this._hideErrors;
  },
  _getCorrectValue: function(val) {
    var correctValue = typeof val !== 'undefined' ?
                       val :
                       this.state.value;
    if (this.props.config.type === 'object' || this.props.config.type === 'array') {
      if (typeof correctValue === 'string') {
        try {
          // console.log('METHOD GET_CORRECT_VALUE - Parsed json string : ', correctValue);
          correctValue = JSON.parse(correctValue);
        }
        catch(e) {
          // console.log('METHOD GET_CORRECT_VALUE - Parsed json string : ', correctValue);
          correctValue = undefined;
        }
      }
    } else if (this.props.config.type === 'boolean') {
      correctValue = typeof correctValue !== 'undefined' && (correctValue === true || correctValue === 'true') ?
                     true :
                     false;
    }
    return correctValue;
  },
  _setValue: function(val) {
    var message;
    val = typeof val !== 'undefined' ?
          val :
          undefined;
    if (this.props.config.type === 'array' && !(val instanceof Array)) {
      message = 'The value is not of type array';
    } else if (this.props.config.type === 'object' && typeof val !== 'object') {
      message = 'The value is not of type object';
    } else if (this.props.config.type === 'boolean' && typeof val !== 'boolean') {
      message = 'The value is not of type boolean';
    }
    if (message) {
      alert(message);
      return;
    }
    if (typeof val === 'object') {
      val = JSON.stringify(val);
    } else if (typeof val === 'boolean') {
      val = val.toString();
    }
    document.getElementById(this._uniqueId()).value = val;
    // this._onChange(val);
    document.getElementById(this._uniqueId()).click();
  },
  render: function() {
    var field;
    var self = this;
    var config = this.props.config;
    var uiSchema = this.props.uiSchema ? this.props.uiSchema : {};
    var arrayKey = this.props.uniqueArrayKey ? this.props.uniqueArrayKey : '';
    var objectName = this.props.objectName ? this.props.objectName : '';
    var d = new Date();
    var n = d.getTime();
    var objectNamePrefix = objectName.length > 0 ? objectName.toLowerCase() + '-' + Math.random() + '-' : '';
    var id = arrayKey ? this.props.name + '-' + arrayKey : this.props.name;
    var name = this.props.name;
    var value;
    var requiredBlock = config.required ? React.createElement("span", {className: "form-control-required"}, " *") : '';
    //var requiredBlock = config.required ? <span className="fa fa-star" style={{fontSize:'7px',verticalAlign:'top',margin:'3px 0 0 4px'}}></span> : '';
    var placeholder = config.field && config.field.placeholder ? config.field.placeholder : '';
    var descriptionBlock = config.description ? React.createElement("p", {className: "help-block help-block--description"}, React.createElement("i", {className: "fa fa-info-circle"}), React.createElement("span", null, " "), config.description) : '';
    var readOnly = false;
    var disabled = false;
    if(config.field && config.field.read_only === true) {
      readOnly = true;
    } else if (config.field && config.field.read_only_on_update && !this.props.isNew) {
      readOnly = true;
    }
    if(config.field && config.field.disabled === true) {
      disabled = true;
    }
    // UI Add-ons
    placeholder = uiSchema['ui:placeholder'] ? uiSchema['ui:placeholder'] : placeholder;
    readOnly = uiSchema['ui:readonly'] === true ? uiSchema['ui:readonly'] : readOnly;
    disabled = uiSchema['ui:disabled'] === true ? uiSchema['ui:disabled'] : disabled;
    var errorBlock = React.createElement("div", {className: "help-block help-block--message js-error-message"}, React.createElement("i", {className: "fa fa-warning"}), React.createElement("span", null, " "), React.createElement("span", {className: "js-error-message-text"}));
    var label = config.title ? React.createElement("label", {className: "control-label", htmlFor: name}, config.title, requiredBlock) : '';
    if(this.props.onChange) {
      //value = this.props.value ? this.props.value : this.props.config.default_value;
      //value = this.props.value;
      value = typeof this.props.value === 'object' ? JSON.stringify(this.props.value) : this.props.value;
    } else {
      value = this.state.value;
    }
    if(config.disabled) {
      field = (
        React.createElement("div", null)
      );
    } else if (config.field && config.field.type === 'hidden' || uiSchema['ui:widget'] === 'hidden') {
      field = (
        React.createElement("div", {className: "form-group hidden"}, 
          React.createElement("input", {
            id: name, 
            name: name, 
            value: value, 
            className: "form-control", 
            type: "hidden"}), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.extension && window.extensions && window.extensions[config.extension]) {
      field = (
        React.createElement("div", {className: "form-group"}, 
          React.createElement("span", {style: {display: 'none'}}, 
            label
          ), 
          React.createElement("textarea", {
            id: name, 
            name: name, 
            value: value, 
            onChange: this._onChange, 
            onClick: this._onChange, 
            onBlur: this._validate, 
            // placeholder={placeholder}
            // readOnly={readOnly}
            // type="text"
            className: "form-control js-extension-field extension-field extension-field--hide"}), 
          React.createElement("iframe", {onLoad: this._showForm, src: window.extensions[config.extension].uri + '?iframe_id=extension_iframe_' + name + '&field_id=' + name, className: "iframe-extension", id: 'extension_iframe_' + name, name: 'extension_iframe_' + name, height: "0"}), 
          React.createElement("span", {style: {display: 'none'}}, 
            errorBlock
          ), 
          React.createElement("span", {style: {display: 'none'}}, 
            descriptionBlock
          )
        )
      );
    } else if ((config.format === 'file') || (config.format === 'color') || (config.format === 'rgba') || (this.props.uiSchema && this.props.uiSchema['ui:widget'] && uiWidgets[this.props.uiSchema['ui:widget']])) {
      var widgetName = config.format === 'color' ||
                       config.format === 'file' ?
                       config.format :
                       config.format === 'rgba' ?
                         'color' : 
                         this.props.uiSchema['ui:widget'];
      var control = uiWidgets[widgetName];
      var correctValue = this._getCorrectValue();
      var validate = function(val){document.getElementById(id).click();};
      var showField = function(){document.getElementById(id).className = 'form-control js-widget-field';};
      var hideLabel = function(){document.getElementById(id).previousSibling.style.display = 'none';};
      var control = React.createElement(
        control,
        {
          id: id,
          name: name,
          value: correctValue,
          schema: config,
          setValue: (function (value) {this._setValue(value);}.bind(this)),
          setInvalid: (function (value) {this._setInvalid(value);}.bind(this)),
          validate: this._validate,
          showField: showField,
          hideLabel: hideLabel
        }
      );
      field = (
        React.createElement("div", {className: "form-group"}, 
          React.createElement("label", {className: "control-label", style: {display: 'block'}, htmlFor: name}, config.title, requiredBlock), 
          React.createElement("input", {
            id: id, 
            name: name, 
            value: value, 
            onChange: this._onChange, 
            onClick: this._onChange, 
            onBlur: this._validate, 
            className: "form-control js-widget-field hide", 
            type: "text", 
            placeholder: placeholder, 
            readOnly: readOnly}), 
          control, 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.field && config.field.type === 'password') {
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("input", {
            id: name, 
            name: name, 
            value: value, 
            onChange: this._onChange, 
            onBlur: this._validate, 
            className: "form-control", 
            type: "password"}), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'string' && config.format === 'date') {
      var style = {idth: '100px'}
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("div", {className: "input-group"}, 
            React.createElement("input", {
              "data-type": "date", 
              // data-pattern="/^(19|20)\d\d([- /.])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/g"
              // data-message="Värdet är inte ett korrekt datum."
              id: name, 
              name: name, 
              value: value, 
              className: "form-control", 
              style: style, 
              type: "text", 
              onChange: this._onChange, 
              onClick: this._onChange, 
              onBlur: this._validate, 
              placeholder: placeholder}), 
            React.createElement("span", {className: "input-group-addon", title: "Välj datum"}, React.createElement("span", {className: "fa fa-calendar"}))
          ), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'string' && config.format === 'date-time') {
      var style = {idth: '160px'}
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("div", {className: "input-group"}, 
            React.createElement("input", {
              "data-type": "date-time", 
              // data-pattern="/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/g"
              // data-message="Värdet är inte ett korrekt datum med tid."
              id: name, 
              name: name, 
              // value={value ? (new Date((new Date(value).getTime()) + (60.00 * 60 * 1000)).toISOString().slice(0,10)) + ' ' + (new Date((new Date(value).getTime()) + (60.00 * 60 * 1000)).toISOString().slice(11,16)) : value}
              // value={value ? (new Date(value).toISOString().slice(0,10)) + ' ' + (new Date(value).toISOString().slice(11,16)) : value}
              value: value ? value.slice(0, 10) + ' ' + value.slice(11, 16) : value, 
              className: "form-control", 
              style: style, 
              type: "text", 
              onChange: this._onChange, 
              onClick: this._onChange, 
              onBlur: this._validate, 
              placeholder: placeholder}), 
            React.createElement("span", {className: "input-group-addon", title: "Välj datum och tid"}, React.createElement("span", {className: "fa fa-calendar"}))
          ), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'string' && config.format === 'uri') {
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("input", {
            "data-type": "uri", 
            // data-pattern="/(http|ftp|https)://([\w+?\.\w+])+([a-zA-Z0-9\~\!\@\#\$\%\^\&\*\(\)_\-\=\+\\\/\?\.\:\;\'\,]*)?/g"
            // data-message="Värdet är inte ett korrekt datum med tid."
            id: name, 
            name: name, 
            value: value, 
            className: "form-control", 
            type: "text", 
            onChange: this._onChange, 
            onClick: this._onChange, 
            onBlur: this._validate, 
            placeholder: placeholder}), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'string' && config.format === 'email') {
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("input", {
            id: name, 
            name: name, 
            value: value, 
            className: "form-control", 
            type: "text", 
            onChange: this._onChange, 
            onBlur: this._validate, 
            placeholder: placeholder, 
            readOnly: readOnly}), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'string' && config.format === 'symbol') {
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("input", {
            "data-type": config.format, 
            id: name, 
            name: name, 
            value: value, 
            className: "form-control", 
            type: "text", 
            onChange: this._onChange, 
            onClick: this._onChange, 
            onBlur: this._validate, 
            placeholder: placeholder, 
            readOnly: readOnly}), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'text' && config.format === 'json') {
      var style = {height: '100px'};
      if(config.field && config.field.height) {
        style = {height: config.field.height};
      }
      if(uiSchema['ui:options'] && uiSchema['ui:options'].height) {
        style = {height: uiSchema['ui:options'].height + 'px'};
      }
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("div", {className: "ace-editor-container", style: style, "data-format": "json"}, 
            React.createElement("textarea", {
              id: name, 
              name: name, 
              className: "form-control", 
              defaultValue: value, 
              onClick: this._onChange, 
              onBlur: this._validate}), 
            React.createElement("div", {className: "ace-editor-element"})
          ), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'text' && config.format === 'html') {
      var style = {height: '100px'};
      if(config.field && config.field.height) {
        style = {height: config.field.height};
      }
      if(uiSchema['ui:options'] && uiSchema['ui:options'].height) {
        style = {height: uiSchema['ui:options'].height + 'px'};
      }
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("div", {className: "ace-editor-container", style: style, "data-format": "html"}, 
            React.createElement("textarea", {
              id: name, 
              name: name, 
              className: "form-control", 
              defaultValue: value, 
              onClick: this._onChange, 
              onBlur: this._validate}), 
            React.createElement("div", {className: "ace-editor-element"})
          ), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'text' && config.format === 'javascript') {
      var style = {height: '100px'};
      if(config.field && config.field.height) {
        style = {height: config.field.height};
      }
      if(uiSchema['ui:options'] && uiSchema['ui:options'].height) {
        style = {height: uiSchema['ui:options'].height + 'px'};
      }
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("div", {className: "ace-editor-container", "data-format": "javascript"}, 
            React.createElement("textarea", {
              id: name, 
              name: name, 
              className: "form-control", 
              defaultValue: value, 
              style: style, 
              onBlur: this._validate}), 
            React.createElement("div", {className: "ace-editor-element"})
          ), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'text' && config.format === 'markdown') {
      var style = {};
      if(config.field && config.field.height) {
        style = {height: config.field.height};
      }
      if(uiSchema['ui:options'] && uiSchema['ui:options'].height) {
        style = {height: uiSchema['ui:options'].height + 'px'};
      }
      field = (
        React.createElement("div", {className: "form-group clearfix"}, 
          label, 
          React.createElement("div", {className: "ace-editor-container-markdown", style: style}, 
            React.createElement("textarea", {
              id: name, 
              name: name, 
              className: "form-control", 
              defaultValue: value, 
              placeholder: placeholder, 
              onChange: this._onChange, 
              onClick: this._onChange, 
              onBlur: this._validate}), 
            React.createElement("div", {className: "ace-editor-element-markdown"})
          ), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'integer' && (config.minimum || config.minimum === 0) && config.maximum || config.maximum === 0) {
      var style = {paddingLeft: 0, paddingRight: 0, border: 'none'}
      field = (
        React.createElement("div", {className: "form-group", style: { marginBottom: '5px'}}, 
          React.createElement("label", {className: "control-label", htmlFor: name, style: { marginBottom: 0}}, config.title, requiredBlock), 
          React.createElement("span", {id: 'range-' + name, style: { float: 'right', fontWeight: 'bold'}}, value || value === 0 ? value : config.minimum), 
          React.createElement("input", {
            "data-type": "range", 
            // data-pattern={config.pattern}
            // data-message="Värdet är inte ett nummer."
            min: config.minimum, 
            max: config.maximum, 
            step: config.multipleOf ? config.multipleOf : 1, 
            id: name, 
            name: name, 
            value: value || value === 0 ? value : config.minimum, 
            className: "form-control", 
            style: style, 
            type: "range", 
            onClick: this._onChange, 
            onChange: this._onChange, 
            onBlur: this._validate, 
            placeholder: placeholder}), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'integer') {
      var style = {width: '100px'}
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("input", {
            "data-type": "integer", 
            // data-pattern={config.pattern}
            // data-message="Värdet är inte ett nummer."
            id: name, 
            name: name, 
            value: value, 
            className: "form-control", 
            style: style, 
            type: "text", 
            onChange: this._onChange, 
            onBlur: this._validate, 
            placeholder: placeholder}), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'number') {
      var style = {width: '100px'}
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("input", {
            "data-type": "number", 
            id: name, 
            name: name, 
            value: value, 
            className: "form-control", 
            style: style, 
            type: "text", 
            onChange: this._onChange, 
            onBlur: this._validate, 
            placeholder: placeholder}), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'boolean') {
      field = (
        React.createElement("div", {className: "form-group"}, 
          React.createElement("div", {className: "checkbox"}, 
            React.createElement("label", {className: "forshow"}, 
              React.createElement("input", {
                checked: value === true ? true : false, 
                type: "checkbox", 
                className: "form-control--unstyled", 
                onChange: this._onChange, 
                onBlur: this._validate}), 
              React.createElement("input", {
                id: objectNamePrefix + name, 
                name: objectNamePrefix + name, 
                className: "form-control--unstyled", 
                value: value === true ? "true" : "false", 
                type: "hidden"}), 
              config.title
            )
          ), 
          errorBlock, 
          descriptionBlock
        )
      );
      //value = (value === 'true');
      //console.log(value);
      // field = 
      //   <div className="form-group">
      //     <div>
      //       <label className="control-label">{config.title}</label>
      //     </div>
      //     <label className="control-label" className="radio-inline">
      //       <input
      //         checked={value === true ? true : false}
      //         id={objectNamePrefix + name + '-0'}
      //         name={objectNamePrefix + name}
      //         value="true"
      //         type="radio"
      //         onChange={this._onChange}
      //         onBlur={this._validate}
      //       /> Ja
      //     </label>
      //     <label className="control-label" className="radio-inline">
      //       <input
      //         checked={value !== true ? true : false}
      //         id={objectNamePrefix + name + '-1'}
      //         name={objectNamePrefix + name}
      //         value="false"
      //         type="radio"
      //         onChange={this._onChange}
      //         onBlur={this._validate}
      //       /> Nej
      //     </label>
      //     {errorBlock}
      //     {descriptionBlock}
      //   </div>
    } else if (config.type === 'string' && config.enum) {
      var optionItems = [];
      if(config.default_selected_value && config.default_selected_text) {
        optionItems.push(
          React.createElement("option", {key: config.default_selected_value, value: config.default_selected_value}, 
            config.default_selected_text
          )
        );
      }
      // config.enum.map(function(item, index) {
      //   var value = item;
      //   var text = item;
      //   if(item.value) {
      //     value = item.value;
      //     text = item.text;
      //   }
      //   optionItems.push(
      //     <option key={index} value={value}>
      //       {text}
      //     </option>
      //   );
      // });
      if(config.field && config.field.option_labels) {
        config.enum.map(function(item, index) {
          optionItems.push(
            React.createElement("option", {key: index, value: item}, 
              config.field.option_labels[index]
            )
          );
        });
      } else if(config.enumNames) {
        config.enum.map(function(item, index) {
          optionItems.push(
            React.createElement("option", {key: index, value: item}, 
              config.enumNames[index]
            )
          );
        });
      } else {
        config.enum.map(function(item, index) {
          optionItems.push(
            React.createElement("option", {key: index, value: item}, 
              item
            )
          );
        });
      }
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("select", {
            id: name, 
            name: name, 
            value: value, 
            className: "form-control", 
            style: style, 
            onClick: this._onChange, 
            onChange: this._onChange, 
            placeholder: placeholder, 
            onBlur: this._validate
          }, 
          optionItems
          ), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'array' && config.uniqueItems && config.items && config.items.enum) {
      value = value ?
              value :
              [];
      value = typeof value === 'string' ? JSON.parse(value) : value;
      var optionItems = [];
      config.items.enum.map(function(item, index) {
        optionItems.push(
          React.createElement("option", {key: index, value: item}, 
            item
          )
        );
      });
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("select", {
            value: value, 
            multiple: true, 
            id: name, 
            name: name, 
            value: value, 
            className: "form-control", 
            style: style, 
            onChange: this._onChange, 
            placeholder: placeholder, 
            onBlur: this._validate
          }, 
          optionItems
          ), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'include') {
      field = (
        React.createElement("div", {id: name, className: "js-include-item-type", "data-target": "sub-page-test"}, "hehe")
      );
    } else if (config.type === 'slug' || config.type === 'access-token' || config.type === 'relationship' || config.type === 'filepicker' || config.type === 'pages' || config.type === 'custom') {
      var control;
      var controlType = 'text';
      var dataPattern;
      var dataMessage;
      if (config.type === 'slug') {  
        if(!config.editable) { 
          //readOnly = true;
        }
        control = (React.createElement(Slug, {id: id, name: name, value: value, config: config, validateCallback: this._validate, isNew: this.props.isNew}));
      } else if (config.type === 'access-token') {
        control = (React.createElement(AccessToken, {id: id, name: name, value: value, config: config}));
      } else if (config.type === 'filepicker') {
        control = (React.createElement(Filepicker, {id: id, name: name, value: value, config: config, isNew: this.props.isNew}));
      } else if (config.type === 'relationship') {
        control = (React.createElement(Relationship, {id: id, name: name, value: value, config: config, parentState: this.state, updateRelationshipViewCallback: this._updateRelationshipView, isArray: this.props.isArray, isNew: this.props.isNew}));
      } else if (config.type === 'pages') {
        control = (React.createElement(Pages, {id: id, name: name, value: value, config: config, parent: this})); 
      } else if (config.type === 'custom') { 
        if (customFields[config.data.id]) {
          var customComponent;
          try {
            var getLabelElement = function(){return document.getElementById(id).previousSibling.previousSibling;};
            var getInputElement = function(){return document.getElementById(id);};
            var hideTitle = function(){document.getElementById(id).previousSibling.previousSibling.style.display = 'none';};
            // var hideField = function(){document.getElementById(id).type = 'hidden';};
            var showField = function(){document.getElementById(id).type = 'text';};
            // var setData = function(val){document.getElementById(id).value=val;document.getElementById(id).click();};
            var setState = function(val){document.getElementById(id).click();};
            var setData = function(val){document.getElementById(id).value=val;};
            customComponent = eval(customFields[config.data.id]);
            var customErrorMessge;
            if (customComponent.validation && customComponent.validation.pattern) {
              customErrorMessge = customComponent.validation.message;
            }
            var customErrorBlock = React.createElement("div", {className: "help-block"}, React.createElement("i", {className: "fa fa-warning"}), React.createElement("span", null, " "), React.createElement("span", null, customErrorMessge));
            if (typeof customComponent === 'function') {
              var infoMessages = {
                error: customErrorBlock,
                description: descriptionBlock
              };
              errorBlock = '';
              descriptionBlock = '';
              controlType = 'hidden';
              control = React.createElement(
                customComponent,
                {
                  id: id,
                  // ref: (input) => this.textInput = input,
                  ref: (function (input) {return this.textInput = input;}.bind(this)),
                  name: name,
                  property: config,
                  value: value,
                  validate: this._validateCustomField,
                  showErrors: this._showErrors,
                  hideErrors: this._hideErrors,
                  setData: setData,
                  // setState: setState,
                  hideTitle: hideTitle,
                  showField: showField,
                  // getLabelElement: getLabelElement,
                  // getInputElement: getInputElement,
                  // showError: this.state.showError,
                  infoMessages: infoMessages,
                  isValid: this._validateCustomFieldWithValue(this.state.value, customComponent.validation)
                }
              );
              // console.log(customComponent.validation);
              if (customComponent.validation && customComponent.validation.pattern) {
                dataPattern = customComponent.validation.pattern;
                dataMessage = customComponent.validation.message;
              } else {
                // console.log('Validation syntax is incorrect. Make sure to end the object with a semicolon.');
              }
              // Not a good way to get the validation but I didn't find any other way
              // var instanceToGetTheValidation = new control.type({});
              // if (instanceToGetTheValidation.validation && instanceToGetTheValidation.validation.pattern) {
              //   dataPattern = instanceToGetTheValidation.validation.pattern;
              //   dataMessage = instanceToGetTheValidation.validation.message;
              // } else {
              //   console.log('Validation syntax is incorrect. Make sure to end the object with a semicolon.');
              // }
              // console.log(control);
              // console.log(control.type)
              // var myRe = /this.validation[^;]*;/g;
              // var myArray = myRe.exec(control.type.toString());
              // if (myArray && myArray.length) {
              //   var validation = eval(myArray[0].replace('this.', ''));
              //   if (validation && validation.pattern) {
              //     dataPattern = validation.pattern;
              //     dataMessage = validation.message;
              //   } else {
              //     console.log('Validation syntax is incorrect. Make sure to end the object with a semicolon.');
              //   }
              // }
              // myRe = /this.dataType[^;]*;/g;
              // myArray = myRe.exec(control.type.toString());
              // if(myArray.length) {
              //   var dataType = eval(myArray[0].replace('this.', ''));
              // }
            }
          } catch(e) {
            // control = (<p className="help-block help-block--message">There is no custom field named: {config.custom}. <br /> Error: {e.message}</p>);
            control = (React.createElement("p", {className: "help-block help-block--message"}, "Error: ", e.message));
          }
        }
      }
      var separator = config.options && config.options.separator ?
                      config.options.separator :
                      config.separator;
      var populateFrom = config.options && config.options.populate_from ?
                         config.options.populate_from :
                         config.populate_from;
      var slugify = populateFrom ? React.createElement("span", {"data-slugify": populateFrom}) : '';
      field = (
        React.createElement("div", {className: "form-group learfix"}, 
          label, 
          slugify, 
          React.createElement("input", {
            id: id, 
            name: name, 
            "data-pattern": dataPattern, 
            "data-message": dataMessage, 
            "data-separator": separator, 
            value: value, 
            className: "form-control", 
            type: controlType, 
            onChange: this._onChange, 
            onClick: this._onChange, 
            onBlur: this._validate, 
            placeholder: placeholder, 
            readOnly: readOnly}), 
          control, 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'text' || (config.type === 'string' && config.field && (config.field.type === 'text' || config.field.type === 'textarea'))) {
      var style = {height: '100px'};
      if(config.field && config.field.height) {
        style = {height: config.field.height};
      }
      if(uiSchema['ui:options'] && uiSchema['ui:options'].height) {
        style = {height: uiSchema['ui:options'].height + 'px'};
      }
      // if (Object.keys(style).length === 0) {
      //   if(uiSchema['ui:options'] && uiSchema['ui:options'].height) {
      //     style = {height: uiSchema['ui:options'].height + 'px'};
      //   }
      // }
      // var patt = "<\/?\w+((\s+\w+(\s*=\s*(?:\".*?\"|'.*?'|[\^'\">\s]+))?)+\s*|\s*)\/?>";
      // patt = "/^\d+(\.\d{1,4})?$/g";
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("textarea", {
            id: name, 
            name: name, 
            // data-pattern={patt}
            // data-message="GAGAGAGA Värdet är inte ett korrekt datum."
            className: "form-control", 
            defaultValue: value, 
            style: style, 
            placeholder: placeholder, 
            onChange: this._onChange, 
            onBlur: this._validate}), 
          errorBlock, 
          descriptionBlock
        )
      );
    } else if (config.type === 'string' && !config.format && !config.enum) {
      field = (
        React.createElement("div", {className: "form-group"}, 
          label, 
          React.createElement("input", {
            id: id, 
            name: name, 
            value: value, 
            onClick: this._onChange, 
            onChange: this._onChange, 
            onBlur: this._validate, 
            className: "form-control", 
            type: "text", 
            placeholder: placeholder, 
            readOnly: readOnly, 
            disabled: disabled}), 
          errorBlock, 
          descriptionBlock
        )
      );
    }
    if (!field) {
      field = (React.createElement("div", {className: "alert alert-danger", role: "alert"}, "Unsupported field schema ", JSON.stringify(config), "."));
    } else if (config.hide) {
      field = (
        React.createElement("div", {className: "hide"}, 
          field
        )
      );
    }
    return (field);
  }
});

module.exports = PropertyItem;

