var sidebar = {
  init: function() {
    $('.nav-sidebar--sidebar').mouseup(function() {
      $(this).find('.tooltip').remove();
    });
    // $('.nav-sidebar--sidebar .sub-menu').click(function() {
    //   return false;
    // });
    var index = false;
    var inBreadcrumb = $('.nav-sidebar--sidebar > .in-breadcrumb');
    if(inBreadcrumb.length) {
      index = inBreadcrumb.index()-1;
      //$('.nav-sidebar--sidebar > .in-breadcrumb > a').addClass('active');
      //alert(index);
    }
    var index1 = false;
    var inBreadcrumb1 = $('.nav-sidebar--sidebar > .in-breadcrumb .in-breadcrumb');
    if(inBreadcrumb1.length) { 
      //alert(inBreadcrumb1.length)
      $('.nav-sidebar--sidebar').first().find(' > .in-breadcrumb.sub-menu ul > .sub-menu').each(function(indexi) {
        if (~$(this).prop('class').indexOf('in-breadcrumb')) {
          index1 = indexi;
          return false;
        }
      });
      //$('.nav-sidebar--sidebar > .in-breadcrumb > a').addClass('active');
      //alert(index);
    }
    if($('.nav-sidebar--sidebar li.sub-menu ul li').length) {
      $('.nav-sidebar--sidebar .sub-menu > a').attr('href', 'javascript:void(0);');
      $('.nav-sidebar--sidebar').accordion({
        header: '> li.sub-menu > a',
        collapsible: true, 
        active: index,
        heightStyle: 'content',
        create: function(event, ui) {
          var header = ui.header;
          if(header.length) {
            header.addClass('active');
            header.parent().removeClass('opened');
          }
        },
        activate: function(event, ui) {
          var newHeader = ui.newHeader;
          var newPanel = ui.newPanel;
          var oldPanel = ui.oldPanel;
          $('.accordion .panel-heading').removeClass('active');
          newHeader.addClass('active');
          newPanel.addClass('active');
          newHeader.next().css('height', 'auto');
          // alert(oldPanel.parent().attr('class'));
          oldPanel.parent().addClass('collapsed');
        },
        beforeActivate: function(event, ui) {
          var newHeader = ui.newHeader;
          var oldHeader = ui.oldHeader;
          var newPanel = ui.newPanel;
          var oldPanel = ui.oldPanel;
          newHeader.addClass('active');
          oldHeader.removeClass('active');
          newHeader.parent().removeClass('collapsed');
          // oldHeader.parent().addClass('collapsed');
          // alert(newHeader.parent().attr('class'));
        },
      });
      $('.nav-sidebar--sidebar > li.sub-menu').accordion({
        header: 'li.sub-menu > a',
        collapsible: true,
        active: index1,
        heightStyle: 'content',
        create: function(event, ui) {
          var header = ui.header;
          if(header.length) {
            header.addClass('active');
            header.parent().removeClass('opened');
          }
        },
        activate: function(event, ui) {
          var newHeader = ui.newHeader;
          var newPanel = ui.newPanel;
          var oldPanel = ui.oldPanel;
          $('.accordion .panel-heading').removeClass('active');
          newHeader.addClass('active');
          newPanel.addClass('active');
        },
        beforeActivate: function(event, ui) {
          var newHeader = ui.newHeader;
          var oldHeader = ui.oldHeader;
          var newPanel = ui.newPanel;
          var oldPanel = ui.oldPanel;
          newHeader.addClass('active');
          oldHeader.removeClass('active');
          //alert(newHeader.next().css('height', 'auto'));
          //newHeader.next().css('height', newHeader.next()[0].offsetHeight+'px');
          newHeader.next().css('height', 'auto');
        },
      });
    }
    $('.nav-sidebar--sidebar .fa-plus').click(function() {
      return false;
    });
    $('.nav-sidebar--sidebar .collapsed > ul > li > a').click(function() {
      return;
      var firstLink = $(this).parent().find('ul li a').first();
      // alert($('.nav-sidebar--sidebar .ui-accordion-header-active').length);
      if (firstLink.length && $('.nav-sidebar--sidebar .ui-accordion-header-active').length === 1) {
        $(this).find('.fa-angle-right').css('transform', 'none');
        location.href = firstLink.attr('href');
        return false;
      }
    });
    // $('.nav-sidebar--sidebar a:not(.ui-accordion-header):not(.toggle-menu-size), .js-loader-links a').click(function() {
    $('.nav-sidebar--sidebar a:not(.toggle-menu-size), .js-loader-links a').click(function() {
      if ($(this).attr('onclick') && document.getElementById('app')) {
        if(document.getElementById('app')) {
          // document.getElementById('app').style.border = 'solid 1px red';
          // return false;
          // console.log($(this).data('href'));
          var url = $(this).data('href');
          history.replaceState(null, 'page 3', url);
          // $('.ui-accordion-content').css('visibility', 'hidden');
          // $('.ui-accordion-content').css('visibility', 'visible');
          if (~$(this).parent().prop('class').indexOf('level-0')) {
            // $('.nav-sidebar--sidebar li a.active').click();
            // $('.nav-sidebar--sidebar li a.active').removeClass('active');
            // $('.nav-sidebar--sidebar li ul.ui-accordion-content-active').css('display', 'none');
            // $('.nav-sidebar--sidebar li ul.ui-accordion-content-active').removeClass('ui-accordion-content-active');
            $('.nav-sidebar--sidebar li').removeClass('active');
            // $('.nav-sidebar--sidebar li a.active').parent().removeClass('in-breadcrumb');
            // setTimeout(function(){$('.nav-sidebar--sidebar li a.active').click();}, 750);
            $(this).parent().addClass('active');
          } else {
            // console.log($('.sidebar-nav-item-dashboard + > ul').css('border', 'solid 4px green'));
            // console.log($('.sidebar-nav-item-dashboard + > ul')[0].style.display);
            // console.log($('.sidebar-nav-item-dashboard +').last().prop('class').indexOf('collapsed'));
            if (~$('.sidebar-nav-item-dashboard +').last().attr('class').indexOf('collapsed')) {
              // $('.sidebar-nav-item-dashboard +').css('border', 'solid 1px red');
              // $('.ui-accordion-content').css('visibility', 'hidden');
              // $('.sidebar-nav-item-dashboard +').find('a').first().click();
              // $('.nav-sidebar--sidebar li.level-0').removeClass('active');
              $('.nav-sidebar--sidebar li').removeClass('active');
              $('.sidebar-nav-item-dashboard +').addClass('in-breadcrumb');
              setTimeout(function(){$('.in-breadcrumb > a').click();}, 750);
              $(this).parent().addClass('active');  
              if (!~$(this).parent().parent().parent().parent().prop('class').indexOf('nav-sidebar--sidebar')) {
                setTimeout(function(){$(this).parent().parent().prev().click();}.bind(this), 1000);
              }
              // console.log('gaga');  
            } else {
              // $('.sidebar-nav-item-dashboard +').css('border', 'solid 1px blue');
              // Is first level
              if (~$(this).parent().parent().parent().parent().prop('class').indexOf('nav-sidebar--sidebar')) {
                // Collapse sub menu accordion
                // $('.nav-sidebar--sidebar li li a.active').click();
                // $('.nav-sidebar--sidebar li li a.active').removeClass('active');
                // $('.nav-sidebar--sidebar li li ul.ui-accordion-content-active').css('display', 'none');
                // $('.nav-sidebar--sidebar li li ul.ui-accordion-content-active').removeClass('ui-accordion-content-active');
                setTimeout(function(){$('.nav-sidebar--sidebar li li a.active').click();}, 500);
              }
              $('.nav-sidebar--sidebar li').removeClass('active');
              // $('.sidebar-nav-item-dashboard +').addClass('in-breadcrumb');
              $(this).parent().addClass('active');  
            }
          }
        }
      } else if ($(this).attr('target') !== 'blank_code') {
        // C10.native.loading();
        var showLoader = function() {
          $('.loader')
            .first()
            .removeClass('loader--inline')
            .removeClass('loader--inline-item-types')
            .removeClass('loader--light-inverted-bg')
            .removeClass('loader--absolute')
            .addClass('loader--sidebar')
            .css('visibility', 'visible')
            .find('.content').text('Laddar...');
          if (location.pathname === '/') {
            $('.loader').removeClass('loader--sidebar');
            $('.loader').css('background-color', '#f1f1f1');
            $('.loader').css('bottom', 0);
            $('.loader').css('color', '#555');
            $('.loader').css('height', 'auto');
            $('.loader .content').css('background-color', 'transparent');
          }
          $('body')
            .css('overflow', 'hidden')
          $('.cms_main--app > div').css('position', 'static');
          // Ugly fix to vertically align loader
          $('.cms_main-inner').css('height', '0');
          $('.cms_main-inner').css('overflow', 'hidden');
        }
        setTimeout(function(){showLoader()}, 100);
        location.href = $(this).attr('href');
        return false;
      }
    });
  }
}

module.exports = sidebar;